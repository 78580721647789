import * as React from 'react';
import { API_TYPES, apiSections } from 'dmpconnectjsapp-base/constants';
import { isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  formatCreateEsTLSiConnector,
  formatCreateTLSiConnector,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  getApiType,
  getConfigurationValue,
  getCpxCode, getEsUser,
  getPracticeLocationSettingFromState,
  getPracticeSetting, getUserConfiguration,
} from 'dmpconnectjsapp-base/helpers/accessors';

import { authenticationTypes } from 'dmpconnectjsapp-base/rules/accessRights';
import { getAction } from '../../dmpconnect/actions';
import Loading from '../Common/Loading/Loading';
import CheckAccessRights from '../AccessRights/CheckAccessRights';

class TLSiConnector extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      tlsiConnector,
      tlsiServerName,
      cpxCode,
      practiceSetting,
      practiceLocationSetting,
      cpxLoginType,
      apiType,
      accessRights,
      practiceLocationName,
      activitySector,
      hpAuthenticationMode,
      esUser,
      customPracticeLocationStructureName,
      customPracticeLocationStructureId,
      customPracticeLocationStructureIdType,
      customPracticeLocationActivitySector,
    } = this.props;
    if (!isReady(tlsiConnector) && !isLoading(tlsiConnector) && apiType !== API_TYPES.REST) {
      if (accessRights.authenticationType === authenticationTypes.DIRECT) {
        dispatch(getAction(
          commands.createTLSiConnector,
          apiSections.TLSI_CONNECTOR_SECTION,
          formatCreateTLSiConnector(
            cpxCode,
            tlsiServerName,
            practiceSetting,
            practiceLocationSetting,
            cpxLoginType,
            customPracticeLocationStructureName,
            customPracticeLocationStructureIdType,
            customPracticeLocationStructureId,
            customPracticeLocationActivitySector,
          ),
        ));
      } else if (accessRights.authenticationType === authenticationTypes.INDIRECT) {
        dispatch(getAction(
          commands.createEsTLsiConnector,
          apiSections.TLSI_CONNECTOR_SECTION,
          formatCreateEsTLSiConnector({
            serverName: tlsiServerName,
            practiceLocationName,
            activitySector,
            practiceSetting,
            hpName: esUser.hpName,
            hpGiven: esUser.hpGiven,
            hpProfession: esUser.hpProfession,
            hpProfessionOid: esUser.hpProfessionOid,
            hpSpeciality: esUser.hpSpeciality,
            hpInternalId: esUser.hpInternalId,
            hpAuthenticationMode,
          }),
        ));
      }
    }
  }

  render() {
    const {
      tlsiConnector, children, showLoading, apiType,
    } = this.props;
    if (isReady(tlsiConnector) || apiType === API_TYPES.REST) {
      return children;
    }
    if (showLoading && isLoading(tlsiConnector)) {
      return (
        <Loading centered />
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.TLSI_CONNECTOR_SECTION]: tlsiConnector,
      [apiSections.CPX_CARD_SECTION]: cpxCard,
    },
    dmpconnectConnectorConfig: {
      tlsiServerName,
    },
    dmpConnectPersistedAppConfiguration,
    dmpconnectESConfiguration,
    dmpconnectCPxConfiguration,
  } = state;

  return {
    apiType: getApiType(state),
    tlsiConnector,
    tlsiServerName,
    cpxCode: getCpxCode(state),
    practiceSetting: getPracticeSetting(state),
    practiceLocationSetting: getPracticeLocationSettingFromState(state),
    customPracticeLocationStructureName: getUserConfiguration(cpxCard, 'customPracticeLocationStructureName', dmpconnectCPxConfiguration, ''),
    customPracticeLocationStructureId: getUserConfiguration(cpxCard, 'customPracticeLocationStructureId', dmpconnectCPxConfiguration, ''),
    customPracticeLocationStructureIdType: getUserConfiguration(cpxCard, 'customPracticeLocationStructureIdType', dmpconnectCPxConfiguration, ''),
    customPracticeLocationActivitySector: getUserConfiguration(cpxCard, 'customPracticeLocationActivitySector', dmpconnectCPxConfiguration, ''),
    cpxLoginType: getConfigurationValue('cpxLoginType', dmpConnectPersistedAppConfiguration),
    practiceLocationName: getConfigurationValue('practiceLocationName', dmpconnectESConfiguration),
    activitySector: getConfigurationValue('activitySector', dmpconnectESConfiguration),
    hpAuthenticationMode: getConfigurationValue('hpAuthenticationMode', dmpconnectESConfiguration),
    esUser: getEsUser(state),
  };
}

TLSiConnector.propTypes = {
  apiType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  tlsiConnector: PropTypes.object,
  tlsiServerName: PropTypes.string,
  cpxCode: PropTypes.string,
  practiceSetting: PropTypes.string,
  practiceLocationSetting: PropTypes.number,
  practiceLocationName: PropTypes.string,
  activitySector: PropTypes.string,
  hpAuthenticationMode: PropTypes.number,
  esUser: PropTypes.object,
  showLoading: PropTypes.bool,
  cpxLoginType: PropTypes.string,
  accessRights: PropTypes.object.isRequired,
  customPracticeLocationStructureName: PropTypes.string,
  customPracticeLocationStructureId: PropTypes.string,
  customPracticeLocationStructureIdType: PropTypes.string,
  customPracticeLocationActivitySector: PropTypes.string,
};

TLSiConnector.defaultProps = {
  tlsiConnector: {},
  tlsiServerName: '',
  cpxCode: '',
  practiceSetting: '',
  practiceLocationSetting: undefined,
  practiceLocationName: '',
  activitySector: '',
  hpAuthenticationMode: 8,
  esUser: {},
  showLoading: false,
  cpxLoginType: undefined,
  customPracticeLocationStructureName: '',
  customPracticeLocationStructureId: '',
  customPracticeLocationStructureIdType: '',
  customPracticeLocationActivitySector: '',
};


const ConnectedTLSiConnector = connect(mapStateToProps)(TLSiConnector);

export default CheckAccessRights(ConnectedTLSiConnector);
