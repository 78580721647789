import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const MobileHrEntry = ({
  title,
  subTitle,
  date,
  dateString,
  performer,
}) => {
  const {
    person: {
      name = '', given = '',
    } = {},
    professionCode,
    // professionLabel,
    // specialty,
    // rpps,
    formation,
    es: { name: esName } = {},
  } = performer;

  return (
    <div className="mobile-card">
      <div className="mobile-card-body">
        <div className="hr-section-infos">
          <div className="title">{title}</div>
          <div>{subTitle}</div>
          <div className="extra-infos">
            <div className="person">
              <div className="d-flex align-items-center justify-content-start">
                {!!professionCode && (
                <span className={`mr-1 badge-profession badge-profession-${professionCode}-${formation ? '2' : '1'}`} />
                )}
                {!!(given || name) && <span>{`${given} ${name}`}</span>}
                {!!esName && <span>{esName}</span>}
              </div>
            </div>
            <div className="date">{dateString || moment(date, 'YYYYMMDD').format('DD/MM/YYYY')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
MobileHrEntry.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  date: PropTypes.string,
  dateString: PropTypes.string,
  performer: PropTypes.object,
};
MobileHrEntry.defaultProps = {
  title: '',
  subTitle: '',
  dateString: undefined,
  date: '',
  performer: {},
};

export default MobileHrEntry;
