import * as React from 'react';
import PropTypes from 'prop-types';
import { generateId } from 'dmpconnectjsapp-base/utils/dataUtils';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';

function PopoverStickOnHover({
  delay, onMouseEnter, children, component, placement, id, className,
}) {
  const [showPopover, setShowPopover] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: true, // true by default
          altAxis: true, // true by default
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'left-start'],
        },
      },
    ],
  });
  let setTimeoutConst = null;

  React.useEffect(() => () => {
    if (setTimeoutConst) {
      clearTimeout(setTimeoutConst);
    }
  });

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
      onMouseEnter();
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  // const displayChild = React.Children.map(children, child => React.cloneElement(child, {
  //   // onMouseEnter: handleMouseEnter,
  //   // onMouseLeave: handleMouseLeave,
  //   ref: (node) => {
  //     referenceElement.current = node;
  //     const { ref } = child;
  //     if (typeof ref === 'function') {
  //       ref(node);
  //     }
  //   },
  // }))[0];

  return (
    <div
      style={{ position: 'relative' }}
      ref={setReferenceElement}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id={`${id}-${generateId(10)}`}
      className={className}
    >
      {children}
      {showPopover && (
        <>
          {ReactDOM.createPortal(
            <div
              data-id={id}
              className="popover"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {component}
            </div>,
            document.querySelector('body'),
          )}
        </>
      )}
    </div>
  );
}

PopoverStickOnHover.propTypes = {
  children: PropTypes.element.isRequired,
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  component: PropTypes.node.isRequired,
  placement: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PopoverStickOnHover.defaultProps = {
  delay: 0,
  onMouseEnter: () => {},
  className: '',
};

export default PopoverStickOnHover;
