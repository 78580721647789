import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { groupBy, groupByFunc } from '../../dmpconnect/helpers/jsUtils';
import MobileMedsTimeline from './MobileMedsTimeline';
import { hrSections } from '../../dmpconnect/constants/hrConstants';
import MobilePSPerformer from '../PS/MobilePS';
import { sortDesc } from '../../dmpconnect/utils/dataUtils';

const MobileMeds = ({
  entries, cipCode, atcCode, medsUrl,
}) => {
  const [currentYearIndex, setCurrentYearIndex] = React.useState(0);

  const atcLabels = React.useMemo(() => entries.reduce((labels, entry) => ({ ...labels, [entry.atcCode]: entry.atcLabel }), {}), [entries]);
  const medLabels = React.useMemo(() => entries.reduce((labels, entry) => ({ ...labels, [entry.cipCode]: entry.cipLabel }), {}), [entries]);

  const years = React.useMemo(() => {
    const yearsArray = entries.reduce((array, entry) => {
      if (entry.date) {
        array.push(moment(entry.date, 'YYYYMMDD').format('YYYY'));
      }
      return array;
    }, []);
    const set = new Set(yearsArray);
    return [...set.values()].sort(sortDesc);
  }, [entries]);

  // group by year, then atcLabel then medicament
  const meds = React.useMemo(() => {
    const groupedByYear = groupByFunc(
      entries,
      entry => (entry.date ? moment(entry.date, 'YYYYMMDD').format('YYYY') : undefined),
    );
    return Object.entries(groupedByYear).reduce(
      (result, [year, yearMeds]) => {
        const yearGroupedByAtc = groupBy(yearMeds, 'atcCode');
        return {
          ...result,
          [year]: Object.entries(yearGroupedByAtc).reduce(
            (medsResult, [atc, groupMeds]) => ({ ...medsResult, [atc]: groupBy(groupMeds, 'cipCode') }),
            {},
          ),
        };
      },
      {},
    );
  }, [entries]);

  return (
    <>
      {!cipCode && (
      <div className="timeline-year-select">
        <Button
          variant="link"
          className="btn-icon-only"
          disabled={currentYearIndex <= 0}
          onClick={() => setCurrentYearIndex(currentYearIndex - 1)}
        >
          <i className="ic-chevron-left" />
        </Button>
        <ul>
          {years.map((year, index) => (
            <li key={`year-list-${year}`} className={index === currentYearIndex ? 'active' : ''}>
              <Button
                variant="link"
                onClick={() => setCurrentYearIndex(index)}
              >
                {year}
              </Button>
            </li>
          ))}
        </ul>
        <Button
          variant="link"
          className="btn-icon-only"
          disabled={currentYearIndex >= years.length - 1}
          onClick={() => setCurrentYearIndex(currentYearIndex + 1)}
        >
          <i className="ic-chevron-right" />
        </Button>
      </div>
      )}
      {Object.entries(meds[years[currentYearIndex]])
        .filter(([currAtcCode]) => !atcCode || String(currAtcCode) === String(atcCode))
        .map(([currAtcCode, medicaments]) => (
          <React.Fragment key={currAtcCode}>
            {!cipCode ? (
              <div className="mobile-card">
                <div className="mobile-card-body">
                  <div className="hr-section-infos">
                    {!cipCode && <div className="title">{atcLabels[currAtcCode]}</div>}
                    {Object.entries(medicaments)
                      .filter(([currCipCode]) => !cipCode || String(currCipCode) === String(cipCode))
                      .map(([currCipCode, occurrences]) => (
                        <Link
                          key={`${currAtcCode}/${currCipCode}`}
                          to={`${medsUrl}/${hrSections.PRESCRIPTIONS}/${currAtcCode}/${currCipCode}`}
                        >
                          <div className="sub-title mt-3 d-flex align-items-center justify-content-between">
                            {medLabels[currCipCode]}
                            <i className="ic-chevron-right" style={{ fontSize: '0.625rem' }} />
                          </div>
                          <MobileMedsTimeline occurrences={occurrences} cipCode={currCipCode} />
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {Object.entries(medicaments)
                  .filter(([currCipCode]) => String(currCipCode) === String(cipCode))
                  .map(([currCipCode, occurrences]) => (
                    <React.Fragment key={currCipCode}>
                      <div className="mobile-card">
                        <div className="mobile-card-body">
                          <div className="hr-section-infos">
                            <div className="title text-primary">{medLabels[currCipCode]}</div>
                            {occurrences[0] && (
                            <>
                              <div className="sub-title mt-2">Composants actifs</div>
                              <ul>
                                {occurrences[0].ingredients && occurrences[0].ingredients.map(((ingredient, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                  <li key={`${ingredient.cnamCode}_${index}`}>{ingredient.cnamLabel}</li>
                                )))}
                              </ul>
                            </>
                            )}
                            <MobileMedsTimeline occurrences={occurrences} cipCode={currCipCode} color="blue" />
                          </div>
                        </div>
                      </div>

                      {occurrences.map(occurrence => (
                        <div className="mobile-card" key={occurrence.date}>
                          <div className="mobile-card-body">
                            <div className="hr-section-infos">
                              <div className="title text-primary">
                                Prescrit le
                                {' '}
                                {moment(occurrence.date, 'YYYYMMDD').format('DD/MM/YYYY')}
                              </div>
                              <div className="sub-title mt-2 mb-1">
                                Prescrit par
                              </div>
                              <MobilePSPerformer performer={occurrence.author} />
                              <div className="sub-title mt-2 mb-1">
                                Délivré par
                              </div>
                              <MobilePSPerformer performer={occurrence.performer} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
};

MobileMeds.propTypes = {
  entries: PropTypes.array,
  atcCode: PropTypes.string,
  cipCode: PropTypes.string,
  medsUrl: PropTypes.string.isRequired,
};
MobileMeds.defaultProps = {
  entries: [],
  atcCode: undefined,
  cipCode: undefined,
};

export default MobileMeds;
