import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import Collapse from 'react-bootstrap/Collapse';
import { b64DecodeUnicode } from '../../../dmpconnect/utils/dataUtils';
import { getErrorDescription } from '../../../dmpconnect/errors';

export default function ErrorDetails({ details = [], error }) {
  const errorDescription = React.useMemo(() => (error ? getErrorDescription(error) : undefined), [error]);
  const [show, setShow] = React.useState(!(
    errorDescription
      && errorDescription.detailsFields
      && errorDescription.detailsFields.length > 0
  ));

  const errorDetails = details.reduce((result, detail) => {
    const { content } = detail;

    let tmpResult = { ...result, [detail.name]: content };

    if (content.detailsType === 'error') {
      Object.entries(content).forEach(([key, value]) => {
        if(key === 'AnswerFrames' || key === 'RequestFrames') {
          tmpResult = {
            ...tmpResult,
            decoded: {
              ...tmpResult.decoded,
              [`${key}_decoded`]: value.map(s => b64DecodeUnicode(s)),
            },
          };
        }
        if (key.indexOf('Base64') > -1) {
          if (Array.isArray(value)) {
            tmpResult = {
              ...tmpResult,
              decoded: {
                ...tmpResult.decoded,
                [`${key}_decoded`]: value.map(s => b64DecodeUnicode(s)),
              },
            };
          } else if (typeof value === 'string' || value instanceof String) {
            tmpResult = {
              ...tmpResult,
              decoded: {
                ...tmpResult.decoded,
                [`${key}_decoded`]: b64DecodeUnicode(value),
              },
            };
          }
        }
      });
    }

    return { ...tmpResult, [detail.name]: content };
  }, {});

  return (
    <>
      {errorDescription && errorDescription.detailsFields && errorDescription.detailsFields.length > 0 && (
      <div className="error-details">
        {errorDescription.detailsFields.map((detail, index) => (
          <>
            {error && !!error[detail.field] && (
              <div key={detail.label}>
                <strong>{detail.label}</strong>
                <br />
                {error[detail.field]}
              </div>
            )}
          </>
        ))}
      </div>
      )}

      <button
        type="button"
        className={`btn btn-text font-weight-normal has-arrow before ${show ? 'active' : ''}`}
        onClick={() => setShow(!show)}
      >
        Informations techniques
      </button>
      <Collapse in={show}>
        <div>
          <div className="mb-2">Merci de transmettre au service technique les informations ci-dessous.</div>
          <ReactJson
            src={errorDetails}
            name={null}
            collapsed={false}
            collapseStringsAfterLength={48}
            displayObjectSize={false}
            displayDataTypes={false}
          />
        </div>
      </Collapse>
    </>
  );
}

ErrorDetails.propTypes = {
  details: PropTypes.array.isRequired,
  error: PropTypes.object.isRequired,
};
