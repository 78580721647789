import { extractDate } from 'dmpconnectjsapp-base/helpers/accessors';
import { hrSections } from '../constants/hrConstants';

export const displayHrSections = [
  hrSections.PRESCRIPTIONS,
  hrSections.VACCINATIONS,
  hrSections.MEDICALDEVICES,
  hrSections.BIO,
  hrSections.HOSPITALSTAYS,
  hrSections.RADIO,
  hrSections.MEDICALCARES,
];


export const defaultHRDocFilters = {
  from: '',
  to: '',
  dateRangeCode: 'default',
  author: '',
  eventTypes: displayHrSections,
};

export const hrSectionToCardInfos = (type, section) => {
  switch (type) {
    case hrSections.BIO:
    case hrSections.RADIO:
    case hrSections.MEDICALCARES:
    case hrSections.MEDICALDEVICES:
      return {
        date: section.date,
        title: section.label,
        subTitle: section.code,
        performer: section.performer,
      };
    case hrSections.HOSPITALSTAYS:
      return {
        dateString: `du ${extractDate(section.from, 'YYYYMMDD').format('DD/MM/YYYY')}`
            + ` au ${extractDate(section.to, 'YYYYMMDD').format('DD/MM/YYYY')}`,
        title: section.label,
        performer: section.performer,
      };
    case hrSections.VACCINATIONS:
      return {
        date: section.date,
        title: section.cipLabel,
        subTitle: section.atcLabel,
        performer: section.performer,
      };
    default: return {};
  }
};
