import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Pagination as BootstrapPagination, Row,
} from 'react-bootstrap';

function Pagination({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
  enablePageSizeChange,
}) {
  return (
    <Row className="pager">
      <Col sm={4}>&nbsp;</Col>
      <Col sm={4} className="d-flex flex-column align-items-center mb-2">
        <BootstrapPagination>
          <BootstrapPagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
          <BootstrapPagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
          <BootstrapPagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
          <BootstrapPagination.Last
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </BootstrapPagination>
        <div className="">
          <span>
            Page
            {' '}
            <strong>
              {pageIndex + 1}
              {' / '}
              {pageOptions.length}
            </strong>
          </span>
        </div>
      </Col>

      <Col sm={4} className="d-flex align-items-baseline justify-content-end">
        {enablePageSizeChange && (
          <>
            <Form.Control
              style={{ width: '100px' }}
              as="select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[20, 50, 100, 200, 500].map(pageSizeItem => (
                <option key={pageSizeItem} value={pageSizeItem}>
                  {pageSizeItem}
                </option>
              ))}
            </Form.Control>
            <Form.Label className="ml-1">Elements par page</Form.Label>
          </>
        )}
      </Col>
    </Row>
  );
}

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageOptions: PropTypes.array.isRequired,
  enablePageSizeChange: PropTypes.bool,
};
Pagination.defaultProps = {
  enablePageSizeChange: true,
};

export default Pagination;
