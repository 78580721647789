import {
  call, put, race, take, takeEvery,
} from 'redux-saga/effects';
import { websocketChannel } from 'dmpconnectjsapp-base/sagas/connectorSagas';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
} from 'dmpconnectjsapp-base/actions';
import { dmpconnectVirtualPrinterActionConstants } from '../constants';
import { manageDmpconnectErrors } from './connectorSagas';
import {
  clearVirtualPrinterSection,
  dmpVirtualPrinterCommandFailure,
  dmpVirtualPrinterCommandFailureContextualized,
  dmpVirtualPrinterCommandSuccess,
  dmpVirtualPrinterCommandSuccessContextualized,
  getVirtualPrinterFiles,
} from '../actions/dmpconnectVirtualPrinterActions';
import { sendDocument } from '../actions';
import { formatUploadDocumentParams } from '../helpers/sendDocument';

const sendVirtualPrinterCommand = function* (action) {
  const { command, context, silentError } = action;
  const channel = yield call(websocketChannel, command);
  const { message } = yield take(channel);

  if (message.s_status !== 'OK') {
    yield put(dmpVirtualPrinterCommandFailure(message, context, command, silentError));
    yield put(dmpVirtualPrinterCommandFailureContextualized(message,
      context,
      command,
      silentError));
  } else {
    yield put(dmpVirtualPrinterCommandSuccess(message, context, command));
    yield put(dmpVirtualPrinterCommandSuccessContextualized(message, context, command));
  }
};

export const handleVirtualPrinterCommand = function* () {
  yield takeEvery(dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SEND_COMMAND,
    sendVirtualPrinterCommand);
};

export const refreshVirtualPrintedFiles = function* () {
  yield put(clearVirtualPrinterSection(apiSections.VIRTUAL_PRINTER_FILES_SECTION));
  yield put(getVirtualPrinterFiles());
};

function* receiveVirtualPrinterFile({ data: { message } }) {
  const { i_count } = message;

  if (i_count === 0 || i_count === undefined) return false;

  yield call(refreshVirtualPrintedFiles);

  return true;
}

export const handleVirtualPrinterFile = function* () {
  yield takeEvery(dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_MONITORING_RECEIVED_FILE,
    receiveVirtualPrinterFile);
};

function* receivingVirtualPrinterFile(socketChannel, updateType) {
  while (true) {
    const payload = yield take(socketChannel);
    yield put({
      type: updateType,
      data: payload,
    });
  }
}

export const handleVirtualPrinterMonitoring = function* () {
  const monitoringAction = {
    startAction: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_MONITORING_START,
    stopAction: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_MONITORING_STOP,
    updateAction: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_MONITORING_RECEIVED_FILE,
  };

  while (true) {
    const { command } = yield take(monitoringAction.startAction);
    const socketChannel = yield call(websocketChannel, command, null, true);

    const { cancel } = yield race({
      task: call(
        receivingVirtualPrinterFile,
        socketChannel,
        monitoringAction.updateAction,
      ),
      cancel: take(monitoringAction.stopAction),
    });
    if (cancel) {
      socketChannel.close();
    }
  }
};

export const handleVirtualPrinterSendDocumentProcess = function* () {
  while (true) {
    const {
      sendDocumentParams,
    } = yield take(dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SEND_DOCUMENT);

    const {
      typeCode,
      data,
      title,
      description,
      format,
      documentVisibility,
      healthcareSetting,
      creationDate,
      replaceDocumentId,
    } = formatUploadDocumentParams(sendDocumentParams);

    yield put(sendDocument(
      sendDocumentParams.ins,
      typeCode,
      data,
      title,
      description,
      format,
      documentVisibility,
      healthcareSetting,
      creationDate,
      replaceDocumentId,
    ));

    const sendResult = yield take([
      dmpCommandSuccessContextualizedType(apiSections.SEND_DOCUMENT_SECTION),
      dmpCommandFailureContextualizedType(apiSections.SEND_DOCUMENT_SECTION),
    ]);

    if (sendResult.type === dmpCommandFailureContextualizedType(apiSections.SEND_DOCUMENT_SECTION)) {
      yield call(manageDmpconnectErrors, sendResult);
    }
  }
};
