import rename from 'deep-rename-keys';
import {
  remoteDmpStatuses,
  remoteUserAuthorizationStatuses,
} from '../../constants/remoteConstants';
import { objectWithoutTheseKeys } from '../../utils/dataUtils';

export const generateTestDmpExistenceResponse = (requestId, data) => {
  const {
    ExistingTestAnswer: {
      i_dmpStatus: dmpStatus,
      i_userAuthorization: authorization,
    } = {},
  } = data;

  return {
    '@RelatesTo': requestId,
    '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
    dmp: {
      '@status': remoteDmpStatuses[dmpStatus],
      '@authorization': remoteUserAuthorizationStatuses[authorization],
      ...objectWithoutTheseKeys(rename(data, key => key.replace(/^s_|^i_/, '')), ['status']),
    },
  };
};
