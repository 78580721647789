import React from 'react';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import { getInsiConfig } from 'dmpconnectjsapp-base/helpers/accessors';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  formatGetCertifiedIdentityParams,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { authenticationTypes } from 'dmpconnectjsapp-base/rules/accessRights';
import CheckAccessRights from '../AccessRights/CheckAccessRights';
import { getAction } from '../../dmpconnect/actions';
import {
  getCertifiedTD0IdentitySubSectionFromIns, getQualifiedTD0Ins,
} from '../../dmpconnect/helpers/certifiedIdentity';
import { getQualifiedIns as getQualifiedInsFromVitaleCard } from '../../dmpconnect/helpers/vitaleCard';

const TD0IdentityProvider = ({
  dispatch,
  children,
  certifiedIns,
  identity,
  vitaleIndex,
  nir,
  nirIndividu,
  birthDay,
  birthRank,
  insiConfig,
  accessRights,
  runOnMount,
}) => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [ins, setIns] = React.useState(certifiedIns);

  React.useEffect(() => {
    // we didn't receive a certified ins, go get one
    if (
      !isRunning
      && runOnMount
      && !certifiedIns
      && vitaleIndex >= 0
      && !isReady(identity)
      && !isLoading(identity)
      && !hasError(identity)
      && nir && birthDay && birthRank
      && (
        insiConfig.active !== true
        || accessRights.authenticationType !== authenticationTypes.DIRECT
      )
    ) {
      setIsRunning(true);
      dispatch(getAction(
        commands.getCertifiedIdentity,
        apiSections.CERTIFIFIED_IDENTITY_SECTION,
        formatGetCertifiedIdentityParams(nir, nirIndividu, birthDay, birthRank),
        {
          subSection: `${nir}_${vitaleIndex}`,
          contextParams: { vitaleIndex },
        },
      ));
    }
  }, [isRunning, runOnMount, certifiedIns, vitaleIndex, identity, nir, birthDay, birthRank]);

  React.useEffect(() => {
    if (isReady(identity)) {
      setIsRunning(false);
      setIns(getQualifiedTD0Ins(identity));
    } else {
      setIns(null);
    }
  }, [isReady(identity)]);

  React.useEffect(() => {
    if (hasError(identity)) {
      setIsRunning(false);
    }
  }, [hasError(identity)]);

  if (
    insiConfig.active === true
    && accessRights.authenticationType === authenticationTypes.DIRECT
  ) {
    return children;
  }

  return React.cloneElement(children, {
    certifiedIns: ins,
    certifiedInsError: hasError(identity),
    certifiedInsLoading: isLoading(identity),
    certifiedInsReady: isReady(identity),
    vitaleIndex: identity && identity.params ? identity.params.vitaleIndex : vitaleIndex,
  });
};

function mapStateToProps(state, props) {
  const {
    vitaleIndex, accessRights, certifiedIns, nir,
  } = props;
  const {
    dmpconnect: {
      [apiSections.CERTIFIFIED_IDENTITY_SECTION]: identitiesSection = {},
      [apiSections.VITALE_DATA_SECTION]: vitaleCardSection,
    },
  } = state;
  const insiConfig = getInsiConfig(state);

  let identity;
  let birthDay;
  let birthRank;
  let nirIndividu;
  if (
    insiConfig.active !== true
    || accessRights.authenticationType !== authenticationTypes.DIRECT
  ) {
    if (certifiedIns) {
      identity = getCertifiedTD0IdentitySubSectionFromIns(identitiesSection, certifiedIns);
    } else if (nir && Number(vitaleIndex) >= 0) {
      identity = identitiesSection[`${nir}_${vitaleIndex}`] || {};
    }

    if (isReady(vitaleCardSection) && Number(vitaleIndex) >= 0) {
      const { Patients: patients, i_cardStatus } = vitaleCardSection;
      const selectedPatient = patients[vitaleIndex];
      if (selectedPatient) {
        birthDay = selectedPatient.s_rawBirthdayYYYYMMDD;
        birthRank = selectedPatient.i_birthRank;
        identity = identitiesSection[`${nir}_${vitaleIndex}`];
        nirIndividu = getQualifiedInsFromVitaleCard(selectedPatient.s_certifiedNir, i_cardStatus);
      }
    }
  }

  return {
    identity,
    birthDay,
    birthRank,
    insiConfig,
    nirIndividu,
  };
}

TD0IdentityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  certifiedIns: PropTypes.string,
  identity: PropTypes.object,
  vitaleIndex: PropTypes.number,
  nir: PropTypes.string,
  nirIndividu: PropTypes.string,
  birthDay: PropTypes.string,
  birthRank: PropTypes.number,
  insiConfig: PropTypes.object.isRequired,
  accessRights: PropTypes.object.isRequired,
  runOnMount: PropTypes.bool,
};

TD0IdentityProvider.defaultProps = {
  vitaleIndex: -1,
  nir: undefined,
  nirIndividu: undefined,
  birthDay: undefined,
  birthRank: undefined,
  certifiedIns: undefined,
  identity: {},
  runOnMount: true,
};


const ConnectedTD0IdentityProvider = CheckAccessRights(connect(mapStateToProps)(TD0IdentityProvider));

export default ConnectedTD0IdentityProvider;
