import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

export default function Loading({
  message, children, size, centered, backdrop,
}) {
  return (
    <>
      {backdrop === true ? (
        <Modal
          backdrop="classic"
          centered
          dialogClassName="modal-auto"
          keyboard={false}
          show
        >
          <Modal.Body className="d-flex flex-column align-items-center">
            <Spinner animation="border" size={size} />
            {message !== null && <span className="mt-2">{message}</span>}
          </Modal.Body>
        </Modal>
      ) : (
        <div className={centered ? 'centered-container' : 'd-inline-block mr-2'}>
          <Spinner animation="border" size={size} />
          {message !== null && <span className={centered ? 'mt-2' : 'ml-2'}>{message}</span>}
          {children}
        </div>
      )}
    </>
  );
}

Loading.defaultProps = {
  centered: true,
  message: null,
  children: null,
  size: null,
  backdrop: false,
};

Loading.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node,
  message: PropTypes.string,
  size: PropTypes.string,
  backdrop: PropTypes.bool,
};
