import React from 'react';
import PropTypes from 'prop-types';

class ImageDocument extends React.PureComponent {
  render() {
    const { src, alt } = this.props;
    return (
      <div className="centered h-centered">
        <img src={src} alt={alt} className="documentPage" />
      </div>
    );
  }
}

ImageDocument.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImageDocument.defaultProps = {
  alt: 'Image viewer',
};


export default ImageDocument;
