import { apiSections, dmpconnectActionConstants } from 'dmpconnectjsapp-base/constants';
import { dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';

const initialState = { persistantData64: undefined };

export function esRestPersistantData(state = initialState, action) {
  switch (action.type) {
    case dmpCommandSuccessContextualizedType(apiSections.CLEAR_PERSISTANT_DATA): {
      return initialState;
    }
    case dmpconnectActionConstants.DMPC_SET_PERSISTANT_DATA_TO_LOCALSTORAGE: {
      const { command: { data: { s_persistantData64 } } } = action;
      return { persistantData64: s_persistantData64 };
    }
    default:
      return state;
  }
}
