import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, ButtonGroup, Col, Form, InputGroup,
} from 'react-bootstrap';
import moment from 'moment';
import { clearSection } from 'dmpconnectjsapp-base/actions';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  authenticationTypes,
  canUpload,
  isTransactionAllowed,
  transactions,
} from 'dmpconnectjsapp-base/rules/accessRights';
import { getConfigurationValue, isAirActive, extractDate } from 'dmpconnectjsapp-base/helpers/accessors';
import { getError, isLoading } from 'dmpconnectjsapp-base/helpers/common';
import Link from 'react-router-dom/Link';
import checkAccessRights from '../AccessRights/CheckAccessRights';

import { isMssActive } from '../../dmpconnect/helpers';

import { dmpconnectAPIConstants, searchPanels } from '../../dmpconnect/constants';

import DMPDate from '../Common/Date/DMPDate';
import { isDateValid } from '../../dmpconnect/utils/dateUtils';
import InputDate from '../Common/Form/Input/InputDate';
import {
  callGetAccessibleDmpList,
  getAccessibleDMPListCache,
  getFullName,
} from '../../dmpconnect/helpers/accessibleDMPList';
import DroppableNameCell from './DroppableNameCell';
import TitleTooltip from '../Common/TitleTooltip';
import ErrorMessage from '../Common/Message/ErrorMessage';
import {
  selectCertifiedIdentity, setBackPage, setCurrentFilter, setUserFilter,
} from '../../dmpconnect/actions';
import DMPStatusProvider from '../DMPStatus/DMPStatusProvider';
import DMPStatusActions from '../DMPStatus/DMPStatusActions';
import DMPStatusExtraActions from '../DMPStatus/DMPStatusExtraActions';
import ButtonWithLoader from '../Common/Form/ButtonWithLoader';
import PaginatedTable from '../Common/Table/PaginatedTable';
import { backPagesTypes } from '../../dmpconnect/helpers/nav';
import { getCurrentFilter, getUserFilter } from '../../dmpconnect/helpers/searchUtils';
import getIsMobileFromState from '../../dmpconnect/helpers/isMobile';
import MobilePatientList from './MobilePatientList';

const inputDateFormat = 'DD/MM/YYYY';

const filter = (data = [], search) => data
  .filter(
    (d) => {
      const nameLower = getFullName(d.s_patientName, d.s_patientGivenName || d.s_patientFirstBirthGiven, d.s_patientBirthName).toLowerCase();
      const searchLower = search.toLowerCase();
      return nameLower.indexOf(searchLower) !== -1;
    },
  );

const AccessByAvailableDMP = ({
  dispatch,
  accessibleDMPList,
  air,
  accessRights,
  selectIns,
  showUploadForm,
  debugUloadCPE,
  error,
  loading,
  selector,
  aldActive,
  sortBy,
  mssActive,
  availableDmpDate,
  availableDmpType,
  isMobile,
}) => {
  const [defaultData, setDefaultData] = React.useState(accessibleDMPList.AccessibleDmps);
  const [dmps, setDmps] = React.useState(defaultData);
  const [filterDate, setFilterDate] = React.useState(availableDmpDate || moment().subtract(4, 'years').format('DD/MM/YYYY'));
  const [filterMode, setFilterMode] = React.useState(availableDmpType || dmpconnectAPIConstants.DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_AUTHORIZATION);
  const [dateIsValid, setDateIsValid] = React.useState(true);
  const [patient, setPatient] = React.useState('');
  const [patientTimeout, setPatientTimeout] = React.useState(0);
  const [waitingResult, setWaitingResult] = React.useState(false);

  // when we receive a result
  React.useEffect(() => {
    if (waitingResult === true) {
      dispatch(clearSection(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION));
      setWaitingResult(false);
    }
  }, [accessibleDMPList.timestamp]);

  React.useEffect(() => {
    setDefaultData(accessibleDMPList.AccessibleDmps);
  }, [accessibleDMPList.AccessibleDmps]);

  React.useEffect(() => {
    if (defaultData) {
      setDmps(filter(defaultData, patient));
    }
  }, [defaultData]);


  React.useEffect(() => {
    if (!selector) {
      dispatch(setBackPage(backPagesTypes.DASHBOARD, `/home/${searchPanels.ACCESS_BY_AVAILABLE_DMP_PANEL}`));
    }
    if (isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS)) {
      dispatch(clearSection(apiSections.ACCESSIBLE_DMP_LIST_SECTION));
      if (!accessibleDMPList.timestamp) {
        setWaitingResult(true);
        callGetAccessibleDmpList(
          air,
          accessRights.psId,
          accessRights.esId,
          dispatch,
          filterMode,
          extractDate(filterDate, inputDateFormat).format('YYYYMMDD'),
        );
      }
    }
  }, []);

  const handleDateChange = (event) => {
    const { target: { value } } = event;
    setFilterDate(value);
    setDateIsValid(isDateValid(value, [inputDateFormat]));
  };

  const search = (e, newFilterMode) => {
    if (e) {
      e.preventDefault();
    }
    if (dateIsValid) {
      dispatch(setUserFilter(accessRights.psId, 'availableDmpDate', filterDate));
      dispatch(setCurrentFilter('availableDmpType', filterMode));
      dispatch(clearSection(apiSections.ACCESSIBLE_DMP_LIST_SECTION));
      setWaitingResult(true);
      callGetAccessibleDmpList(
        air,
        accessRights.psId,
        accessRights.esId,
        dispatch,
        newFilterMode || filterMode,
        extractDate(filterDate, inputDateFormat).format('YYYYMMDD'),
        true,
      );
    }
  };

  const changeFilterMode = (newFilterMode) => {
    setFilterMode(newFilterMode);
    search(undefined, newFilterMode);
  };

  const selectDMP = (ins) => {
    selectIns(ins);
    if (!selector) dispatch(selectCertifiedIdentity(null));
  };

  const handlePatientChange = (event) => {
    const { target: { value } } = event;
    setPatient(value);
    clearTimeout(patientTimeout);
    setPatientTimeout(setTimeout(() => {
      setDmps(filter(defaultData, value));
    }, 200));
  };

  const handleSortBy = (sort) => {
    if (sort !== sortBy) {
      dispatch(setUserFilter(accessRights.psId, 'availableDmpSortBy', sort));
    }
  };

  const displayUpload = canUpload(accessRights) || debugUloadCPE;

  const columns = React.useMemo(() => {
    let tmpColumns = [
      {
        Header: 'Patient',
        id: 'patientName',
        accessor: d => getFullName(d.s_patientName, d.s_patientGivenName || d.s_patientFirstBirthGiven, d.s_patientBirthName),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row, value }) => (
          <>
            {!selector ? (
              <>
                {displayUpload ? (
                  <DroppableNameCell
                    // eslint-disable-next-line react/prop-types
                    onDrop={acceptedFiles => showUploadForm(acceptedFiles, row.original.s_ins)}
                    // eslint-disable-next-line react/prop-types
                    ins={row.original.s_ins}
                    text={value}
                  />
                ) : (
                  <span className="typography-big-text-title">{value}</span>
                )}
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-link btn-sm typography-big-text-title"
                  // eslint-disable-next-line react/prop-types
                  onClick={() => selectDMP(row.original.s_ins)}
                >
                  {value}
                </button>
              </>
            )}
          </>
        ),
      },
      {
        Header: 'Né(e) le',
        id: 's_birthday',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<DMPDate dmpDate={value} className="typography-default-text-title" />),
        accessor: 's_birthday',
      },
      {
        id: 'dmp-access',
        Header: 'Accès au dossier patient',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          // eslint-disable-next-line react/prop-types
          <DMPStatusProvider getOnMount certifiedIns={row.original.s_ins} key={row.original.s_ins}>
            <DMPStatusActions accessDMP={ins => selectDMP(ins)} aldActive={false} />
          </DMPStatusProvider>
        ),
      },
      {
        Header: 'Dernier accès le',
        id: 's_lastAccessDate',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<DMPDate inputFormat="YYYYMMDDHHmmss" dmpDate={value} className="typography-default-text" />),
        accessor: 's_lastAccessDate',
      },
      {
        id: 'actions',
        Header: 'Actions',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          // eslint-disable-next-line react/prop-types
          <DMPStatusProvider certifiedIns={row.original.s_ins} showError={false}>
            <DMPStatusExtraActions showUploadForm={ins => showUploadForm([], ins)} mssActive={mssActive} />
          </DMPStatusProvider>
        ),
      },
    ];
    
    if (selector) {
      tmpColumns = tmpColumns.filter(c => !['actions', 'dmp-access'].includes(c.id));
    }
    return tmpColumns;
  }, []);

  return (
    <div id="accessByAvailableDmpPanel">
      {error && (
        <ErrorMessage error={error} showDetails />
      )}
      {!isMobile && (
        <Form>
          {isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS) && (
          <Form.Row className="mb-2 align-items-center">
            {[authenticationTypes.AIR, authenticationTypes.DIRECT].includes(accessRights.authenticationType) && (
            <Col sm="auto">
              <ButtonGroup aria-label="Mode de filtrage">
                <Button
                  size="sm"
                  variant="sand"
                  onClick={() => changeFilterMode(dmpconnectAPIConstants.DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_DOC)}
                  active={filterMode === dmpconnectAPIConstants.DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_DOC}
                >
                  Alimentés
                </Button>
                <Button
                  size="sm"
                  variant="sand"
                  onClick={() => changeFilterMode(dmpconnectAPIConstants.DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_AUTHORIZATION)}
                  active={filterMode === dmpconnectAPIConstants.DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_AUTHORIZATION}
                >
                  Accessibles
                </Button>
              </ButtonGroup>
            </Col>
            )}
            <Col sm="auto">
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>Depuis le&nbsp;</InputGroup.Text>
                </InputGroup.Prepend>
                <InputDate
                  size="sm"
                  value={filterDate}
                  name="filterDate"
                  style={{ width: '125px' }}
                  onChange={event => handleDateChange(event)}
                />
                {!dateIsValid && (
                <small className="form-text text-danger">
                  Date incorrecte
                </small>
                )}
              </InputGroup>
            </Col>
            <Col sm="auto">
              <ButtonWithLoader
                type="submit"
                onClick={event => search(event)}
                disabled={!dateIsValid || loading}
                loading={loading}
                label="Recharger"
                loadingLabel="Recharger"
                size="sm"
              />
            </Col>
            {aldActive && (
            <Col className="text-right">
              <TitleTooltip
                id="patients_ald"
                text="Veuillez lire la Carte Vitale du patient pour obtenir la liste des ALD"
              >
                <Button
                  as={Link}
                  size="sm"
                  variant="outline-primary"
                  to="/home/vitale/ald"
                >
                  ALD
                </Button>
              </TitleTooltip>
            </Col>
            )}
          </Form.Row>
          )}
          <Form.Row>
            <Col sm="auto">
              <InputGroup>
                <InputGroup.Prepend className="no-background">
                  <InputGroup.Text>
                    <Form.Label htmlFor="patients-search">
                      <i className="ic-input-search" />
                    </Form.Label>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id="patients-search"
                  onChange={event => handlePatientChange(event)}
                  value={patient}
                />
              </InputGroup>
            </Col>
          </Form.Row>
        </Form>
      )}

      {dmps && (
      <>
        {isMobile ? (
          <MobilePatientList
            patients={dmps.map(dmp => ({
              name: dmp.s_patientName,
              given: dmp.s_patientGivenName,
              firstBirthGiven: dmp.s_patientFirstBirthGiven,
              birthName: dmp.s_patientBirthName,
              birthday: dmp.s_birthday,
              sex: dmp.i_sex,
              ins: dmp.s_ins,
            }))}
            accessDMP={ins => selectDMP(ins)}
          />
        ) : (
          <PaginatedTable
            columns={columns}
            data={dmps}
            striped
            defaultPageSize={10}
            enablePageSizeChange={false}
            initialSortBy={sortBy || [{ id: 'patientName', desc: false }]}
            onSort={handleSortBy}
          />
        )}
      </>
      )}


    </div>
  );
};

AccessByAvailableDMP.propTypes = {
  selectIns: PropTypes.func.isRequired,
  accessibleDMPList: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  showUploadForm: PropTypes.func,
  accessRights: PropTypes.object.isRequired,
  debugUloadCPE: PropTypes.bool,
  error: PropTypes.object,
  loading: PropTypes.bool,
  air: PropTypes.bool,
  selector: PropTypes.bool,
  aldActive: PropTypes.bool,
  sortBy: PropTypes.array,
  availableDmpDate: PropTypes.string,
  availableDmpType: PropTypes.string,
  mssActive: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

AccessByAvailableDMP.defaultProps = {
  accessibleDMPList: {},
  showUploadForm: null,
  debugUloadCPE: false,
  error: {},
  loading: false,
  air: false,
  selector: false,
  aldActive: false,
  sortBy: undefined,
  availableDmpDate: undefined,
  availableDmpType: undefined,
  mssActive: false,
};

function mapStateToProps(state, props) {
  const { accessRights } = props;
  const {
    dmpconnect: {
      [apiSections.ACCESSIBLE_DMP_LIST_SECTION]: {
        [accessRights.psId]: psAccessibleDMPListSection,
        [accessRights.esId]: esAccessibleDMPListSection,
      },
    },
    dmpconnectAccessibleDmpList: accessibleDMPListCache,
    dmpconnectConnectorConfig,
  } = state;
  const air = isAirActive(state);
  const airOnly = getConfigurationValue('airOnly', dmpconnectConnectorConfig);
  const accessibleDMPList = getAccessibleDMPListCache(
    accessibleDMPListCache,
    accessRights.psId,
    air && !airOnly ? accessRights.esId : null,
    isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS),
  );

  return {
    accessibleDMPList,
    loading: isLoading(psAccessibleDMPListSection) || isLoading(esAccessibleDMPListSection),
    error: getError(psAccessibleDMPListSection) || getError(esAccessibleDMPListSection),
    debugUloadCPE: getConfigurationValue('debugUploadCPE', dmpconnectConnectorConfig),
    air: air && !airOnly,
    sortBy: getUserFilter(state, accessRights.psId, 'availableDmpSortBy'),
    availableDmpDate: getUserFilter(state, accessRights.psId, 'availableDmpDate'),
    availableDmpType: getCurrentFilter(state, 'availableDmpType'),
    mssActive: isMssActive(state),
    isMobile: getIsMobileFromState(state),
  };
}

const connectedAccessByAvailableDMP = connect(mapStateToProps)(AccessByAvailableDMP);

export default checkAccessRights(connectedAccessByAvailableDMP);
