import * as React from 'react';
import PropTypes from 'prop-types';
import MobilePatient from './MobilePatient';
import DMPStatusProvider from '../DMPStatus/DMPStatusProvider';

const MobilePatientList = ({ patients, accessDMP }) => (
  <>
    {patients.map(patient => (
      <DMPStatusProvider getOnMount certifiedIns={patient.ins} showError={false} showLoading={false}>
        <MobilePatient patient={patient} accessDMP={accessDMP} />
      </DMPStatusProvider>
    ))}
  </>
);

MobilePatientList.propTypes = {
  patients: PropTypes.array,
  accessDMP: PropTypes.func.isRequired,
};
MobilePatientList.defaultProps = {
  patients: [],
};

export default MobilePatientList;
