
const getIsMobileFromState = (state) => {
  const {
    dmpConnectPersistedAppConfiguration: {
      isMobileLayout = false,
    },
  } = state;

  return isMobileLayout;
};

export default getIsMobileFromState;
