import { isLoading }                        from 'dmpconnectjsapp-base/helpers/common';
import PropTypes                            from 'prop-types';
import React                                from 'react';
import { connect }                          from 'react-redux';
import { getDirectAuthenticationDMPStatus } from '../../dmpconnect/actions';
import { setVirtualPrinterFileIns }         from '../../dmpconnect/actions/dmpconnectVirtualPrinterActions';
import { isInsValid }                       from '../../dmpconnect/helpers';
import DMPSelector                          from '../DMPSearch/DMPSelector';

const VirtualPrinterDMPSelector = ({ dispatch, directAuthenticationDMPStatus }) => {
  const selectINS = (ins) => {
    if (ins && isInsValid(ins)) {
      dispatch(getDirectAuthenticationDMPStatus(ins));
    }
    dispatch(setVirtualPrinterFileIns(ins));
  };
  
  return (
    <DMPSelector
      loading={isLoading(directAuthenticationDMPStatus)}
      onSelect={selectedIns => selectINS(selectedIns)}
    />
  );
};

VirtualPrinterDMPSelector.propTypes      = {
  dispatch                     : PropTypes.func.isRequired,
  directAuthenticationDMPStatus: PropTypes.object.isRequired,
};
const ConnectedVirtualPrinterDMPSelector = connect()(VirtualPrinterDMPSelector);

export default ConnectedVirtualPrinterDMPSelector;
