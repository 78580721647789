import * as React from 'react';
import { connect } from 'react-redux';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { getApiType } from 'dmpconnectjsapp-base/helpers/accessors';
import { areLogsDetailed } from '../../../dmpconnect/helpers/logs';
import { showLogsPopup } from '../../../dmpconnect/actions';
import { API_TYPES } from '../../../dmpconnect/constants';

const GetLogsButton = ({
  onOpen, onClose, error, dispatch, apiType,
}) => {
  if (apiType === API_TYPES.REST) {
    return <></>;
  }

  return (
    <Button
      variant="link"
      size="xs"
      onClick={() => {
        dispatch(showLogsPopup(true, error, onClose));
        if (onOpen) {
          onOpen();
        }
      }}
    >
      Télécharger les journaux
    </Button>
  );
};

GetLogsButton.propTypes = {
  apiType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  error: PropTypes.array,
};
GetLogsButton.defaultProps = {
  onOpen: undefined,
  onClose: undefined,
  error: undefined,
};

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.GET_LOG_LEVELS]: logLevelsSection,
    },
  } = state;

  return {
    apiType: getApiType(state),
    detailedLogs: areLogsDetailed(logLevelsSection),
  };
}

export default connect(mapStateToProps)(GetLogsButton);
