import { dmpconnectMonitoringActionConstants } from '../constants';
import { getSessionId } from '../helpers';


export const startVitaleMonitoring = () => (dispatch, getState) => (
  dispatch({
    type: dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_START,
    command: {
      s_commandName: 'hl_startVitaleCardMonitoring',
      i_checkingInterval: 5,
      s_sessionId: getSessionId(getState()),
    },
  })
);

export const startCpxMonitoring = () => (dispatch, getState) => (
  dispatch({
    type: dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_START,
    command: {
      s_commandName: 'hl_startCpxCardMonitoring',
      i_checkingInterval: 5,
      s_sessionId: getSessionId(getState()),
    },
  })
);

export const stopCpxMonitoring = () => ({
  type: dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_STOP,
});

export const updateVitaleMonitoring = data => ({
  type: dmpconnectMonitoringActionConstants.DMPC_VITALE_MONITORING_UPDATE,
  data,
});

export const updateCpxMonitoring = data => ({
  type: dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_UPDATE,
  data,
});
