import { apiToRemoteErrors, dmpToRemoteErrors, remoteErrors } from '../../errors/errorConstants';
import { errorTypes } from '../../errors/errorConfiguration';

export const remoteSources = {
  GET: 'GET',
  IFRAME: 'messageEvent',
  CONNECTOR: 'connector',
};
export const remoteFormats = {
  XML: 'XML',
  JSON: 'JSON',
};
export const remoteResponseChannels = {
  NONE: 'none',
  IFRAME: 'messageEvent',
  URL: 'post',
  CONNECTOR: 'connector',
};
export const remoteActions = {
  getEfficienceVersion: 'getEfficienceVersion',
  getStatus: 'getStatus',

  sendCpxPinCode: 'sendCpxPinCode',

  setPscAccessToken: 'setPscAccessToken',

  getCertifiedIdentity: 'getCertifiedIdentity',
  getCurrentDmp: 'getCurrentDmp',
  findDmp: 'findDmp',
  testDmpExistence: 'testDmpExistence',
  openDmp: 'openDmp',
  openOrCreateDmp: 'openOrCreateDmp',
  closeDmpSession: 'closeDmpSession',
  submitDocument: 'submitDocument',
  deleteDocument: 'deleteDocument',

  sendMssMessage: 'sendMssMessage',
  getMssNbUnreadMessages: 'getMssUnreadMessagesCount',
  getMssHpInfos: 'getMssHpInfos',
  logout: 'logout',
};

const parseAttributes = attributes => Object.entries(attributes).reduce((parsed, [key, value]) => ({
  ...parsed,
  [`@${key}`]: value,
}), {});

export const parseXmlToJsResult = (xml) => {
  const nodes = Object.entries(xml);
  return nodes.reduce((parsedXml, [nodeName, nodeValue]) => {
    const {
      _attributes: attributes, _text: text, _cdata: cdata, ...children
    } = nodeValue;

    if (Array.isArray(nodeValue)) {
      return {
        ...parsedXml,
        [nodeName]: Object.values(parseXmlToJsResult(children)),
      };
    }

    const parsedAttributes = attributes ? parseAttributes(attributes) : undefined;
    const value = text || cdata;
    let parsedChildren;
    if (Object.keys(children).length > 0) {
      parsedChildren = parseXmlToJsResult(children);
    }

    return {
      ...parsedXml,
      [nodeName]: {
        ...parsedAttributes,
        value,
        ...parsedChildren,
      },
    };
  }, {});
};

export const convertJsResultToXmlReadyObject = (jsResult) => {
  if (jsResult === null || jsResult === undefined) return undefined;
  return Object.entries(jsResult).reduce((xml, [key, value]) => {
    if (Array.isArray(value)) {
      let pluralKey = key;
      let singularKey = key;
      if (key.endsWith('s')) {
        singularKey = key.slice(0, -1);
      } else {
        pluralKey = `${key}s`;
      }
      const converted = value.map(object => (typeof object === 'object' ? convertJsResultToXmlReadyObject(object) : object));
      if (converted) {
        return {
          ...xml,
          [`${pluralKey}`]: {
            [singularKey]: converted,
          },
        };
      }
    }

    if (typeof value === 'object') {
      const converted = convertJsResultToXmlReadyObject(value);
      if (converted) {
        return { ...xml, [key]: converted };
      }
    }

    if (key.startsWith('@')) {
      return { ...xml, _attributes: { ...xml._attributes, [key.substring(1)]: value } };
    }

    return { ...xml, [key]: value };
  }, {});
};

const getRemoteError = (apiErrorType, apiErrorCode) => {
  if (apiErrorType === errorTypes.ApiErrors) {
    return apiToRemoteErrors[apiErrorCode] || remoteErrors.DMP_ERROR;
  }
  if (apiErrorType === errorTypes.MssErrors) {
    return remoteErrors.MSS_ERROR;
  }
  if (apiErrorType === errorTypes.RemoteErrors) {
    return remoteErrors[apiErrorCode];
  }
  if (apiErrorType === errorTypes.DMPErrors) {
    return dmpToRemoteErrors[apiErrorCode] || remoteErrors.DMP_ERROR;
  }
  if (apiErrorType === errorTypes.JWTErrors) {
    return dmpToRemoteErrors[apiErrorCode] || remoteErrors.DMP_ERROR;
  }

  return null;
};

export const generateRemoteFailureResponse = (requestId, error, details) => {
  const { s_apiErrorExtendedInformations, i_apiErrorCode, i_apiErrorType } = error;
  const remoteError = getRemoteError(i_apiErrorType, i_apiErrorCode);

  const resultError = {
    '@code': remoteError,
    '@displayCode': s_apiErrorExtendedInformations,
  };
  if (details) {
    resultError.details = details;
  }
  return {
    '@RelatesTo': requestId,
    '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Failure',
    errors: [resultError],
  };
};
