import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { getConfigurationValue, getMssEmail } from 'dmpconnectjsapp-base/helpers/accessors';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  formatFinalizeMssOtpAuthentication,
  formatInitiateMssOtpAuthentication,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import {
  getError, hasError, isLoading, isReady,
} from 'dmpconnectjsapp-base/helpers/common';
import { setUserConfiguration } from 'dmpconnectjsapp-base/actions';
import ButtonWithLoader from '../Common/Form/ButtonWithLoader';
import { getAction } from '../../dmpconnect/actions';
import checkAccessRights from '../AccessRights/CheckAccessRights';
import { isMssActive } from '../../dmpconnect/helpers';
import CustomCheckbox from '../Common/Form/Input/CustomCheckbox';
import { otpChannelTypes } from '../../dmpconnect/constants/dmpConstants';
import ErrorMessage from '../Common/Message/ErrorMessage';
import env from '../../envVariables';

function MssOTPLogin({
  dispatch, otpChannelType,
  initAuthSection,
  finalizeAuthSection,
  mssEmail,
  esUser,
  accessRights,
}) {
  const [channelType, setChannelType] = React.useState(otpChannelType || otpChannelTypes.MOBILE);
  const [password, setPassword] = React.useState('');
  const [rpps, setRpps] = React.useState(esUser ? esUser.hpInternalId : accessRights.psId);
  const [otp, setOtp] = React.useState('');
  const [email, setEmail] = React.useState(mssEmail);
  const [authenticationId, setAuthenticationId] = React.useState(initAuthSection.s_authenticationId || '');

  React.useEffect(() => {
    if (isReady(initAuthSection)) {
      const { s_authenticationId } = initAuthSection;
      setAuthenticationId(s_authenticationId);
    }
  }, [initAuthSection, dispatch]);

  const initAuth = (event) => {
    event.preventDefault();

    dispatch(setUserConfiguration(accessRights.psId, { mssEmail: email }));
    dispatch(getAction(
      commands.initiateMssOtpAuthentication,
      apiSections.MSS_INIT_OTP_AUTH,
      formatInitiateMssOtpAuthentication(rpps, channelType, password),
      { silentError: true },
    ));
  };

  const finalizeAuth = (event) => {
    if (event) {
      event.preventDefault();
    }
    dispatch(getAction(
      commands.finalizeMssOtpAuthentication,
      apiSections.MSS_FINALIZE_OTP_AUTH,
      formatFinalizeMssOtpAuthentication(authenticationId, otp),
      { silentError: true },
    ));
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h5 className="mb-4">Veuillez vous connecter à votre compte Mailiz.</h5>

          {hasError(initAuthSection) && (
          <ErrorMessage
            error={getError(initAuthSection)}
            message={(
              <>
                L&apos;utilisation de la messagerie MSS nécessite un compte Mailiz.
                <br />
                Si vous n&apos;en disposez pas, vous pouvez en créer un depuis cette adresse :
                {' '}
                <a
                  href={Number(env.REACT_APP_PRODUCTON_MODE) === 1 ? 'https://mailiz.mssante.fr/' : 'https://mailiz.formation.mssante.fr/'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mailiz
                </a>
              </>
          )}
            showDetails
          />
          )}
          {hasError(finalizeAuthSection) && (
          <ErrorMessage error={getError(finalizeAuthSection)} showDetails />
          )}
          {!isReady(initAuthSection) ? (
            <Form className="flex-grow-1 mr-5" onSubmit={event => initAuth(event)}>
              <Form.Group>
                <Form.Label>
                  Canal sur lequel recevoir le code de vérification
                </Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <div className="toggle-checkbox-container mr-2">
                    <div className={`toggle-checkbox border ${channelType === otpChannelTypes.MOBILE ? 'active' : ''}`}>
                      <CustomCheckbox
                        type="radio"
                        id="mss-otp-mobile"
                        checked={channelType === otpChannelTypes.MOBILE}
                        label="SMS"
                        value={otpChannelTypes.MOBILE}
                        name="otpChannelType"
                        onChange={() => setChannelType(otpChannelTypes.MOBILE)}
                      />
                    </div>
                  </div>

                  <div className="toggle-checkbox-container">
                    <div className={`toggle-checkbox border ${channelType === otpChannelTypes.MAIL ? 'active' : ''}`}>
                      <CustomCheckbox
                        type="radio"
                        id="mss-otp-email"
                        checked={channelType === otpChannelTypes.MAIL}
                        label="Email"
                        value={otpChannelTypes.MAIL}
                        name="otpChannelType"
                        onChange={() => setChannelType(otpChannelTypes.MAIL)}
                      />
                    </div>
                  </div>
                </div>
                {/* <Form.Control.Feedback type="invalid"> */}
                {/*  {errors.mssEmail} */}
                {/* </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  onChange={event => setEmail(event.target.value)}
                  value={email}
                />
              </Form.Group>
              <Form.Group controlId="rpps">
                <Form.Label>
                  Identifiant national (rpps)
                </Form.Label>
                <Form.Control
                  value={rpps}
                  onChange={event => setRpps(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>
                  Mot de passe
                </Form.Label>
                <Form.Control
                  value={password}
                  type="password"
                  onChange={event => setPassword(event.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <ButtonWithLoader
                  type="submit"
                  label="Se connecter"
                  loading={isLoading(initAuthSection)}
                />
              </Form.Group>
            </Form>
          ) : (
            <Form onSubmit={event => finalizeAuth(event)}>
              <Form.Group>
                <Form.Label>
                  Code de vérification
                </Form.Label>
                <Form.Control
                  name="otp"
                  id="otp"
                  value={otp}
                  onChange={event => setOtp(event.target.value)}
                  autoFocus
                />
              </Form.Group>
              <Form.Group>
                <ButtonWithLoader
                  label="Envoyer"
                  type="submit"
                  loading={isLoading(finalizeAuthSection)}
                />
              </Form.Group>
            </Form>
          )}

        </div>
        <i className="ic-mss" />
      </div>
    </>
  );
}

MssOTPLogin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  otpChannelType: PropTypes.number,
  initAuthSection: PropTypes.object,
  finalizeAuthSection: PropTypes.object,
  mssEmail: PropTypes.string,
  esUser: PropTypes.object,
  accessRights: PropTypes.object.isRequired,
};
MssOTPLogin.defaultProps = {
  otpChannelType: otpChannelTypes.MOBILE,
  initAuthSection: {},
  finalizeAuthSection: {},
  mssEmail: '',
  esUser: {},
};

function mapStateToProps(state) {
  const {
    dmpConnectPersistedAppConfiguration,
    dmpconnect: {
      [apiSections.MSS_INIT_OTP_AUTH]: initAuthSection,
      [apiSections.MSS_FINALIZE_OTP_AUTH]: finalizeAuthSection,
    },
    dmpconnectUser: {
      esUser,
    },
  } = state;
  return {
    mssActive: isMssActive(state),
    mssEmail: getMssEmail(state),
    otpChannelType: getConfigurationValue('mssOtpChannelType', dmpConnectPersistedAppConfiguration),
    initAuthSection,
    finalizeAuthSection,
    esUser,
  };
}

export default checkAccessRights(connect(mapStateToProps)(MssOTPLogin));
