import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { canUpload, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import { dmpStatuses } from '../../dmpconnect/constants';
import TitleTooltip from '../Common/TitleTooltip';
import { setMssEmailContent, setShowMssPopup } from '../../dmpconnect/actions';

const DMPStatusExtraActions = ({
  ins, status, showUploadForm, ensRelated, dispatch, mssActive, lastUpdateDate, accessRights, showButtonLabels
}) => (
  <>
    {status === dmpStatuses.DMPExist && canUpload(accessRights) && (
      <>
        <TitleTooltip
          id={`upload-doc-dmp-${ins}`}
          text="Déposer un document"
          showTooltip={!showButtonLabels}
        >
          <button
            type="button"
            className={`btn btn-secondary btn-sm ${showButtonLabels ? '' : 'btn-icon-only'}`}
            onClick={() => showUploadForm(ins)}
          >
            <i className="ic-upload-document" />
            {showButtonLabels && 'Déposer un document'}
          </button>
        </TitleTooltip>
      </>
    )}
    {!!ensRelated && !!mssActive && lastUpdateDate >= '20220101' && (
      <>
        <TitleTooltip
          id={`mss-${ins}`}
          text="Envoyer un email au patient"
          showTooltip={!showButtonLabels}
        >
          <button
            type="button"
            className="btn btn-secondary btn-sm btn-icon-only ml-2"
            onClick={() => {
              dispatch(setMssEmailContent({
                recipients: `${ins.substring(0, ins.length - 1)}@patient.mssante.fr`,
              }));
              dispatch(setShowMssPopup(true));
            }}
          >
            <i className="ic-mon-espace-sante" style={{ fontSize: '1rem' }} />
            {showButtonLabels && 'Envoyer un email'}
          </button>
        </TitleTooltip>
      </>
    )}
  </>
);

DMPStatusExtraActions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  accessRights: PropTypes.object.isRequired,
  status: PropTypes.number,
  ins: PropTypes.string,
  showUploadForm: PropTypes.func,
  ensRelated: PropTypes.number,
  mssActive: PropTypes.bool,
  lastUpdateDate: PropTypes.string,
  showButtonLabels: PropTypes.bool,
};

DMPStatusExtraActions.defaultProps = {
  status: null,
  ins: null,
  showUploadForm: null,
  ensRelated: null,
  mssActive: false,
  lastUpdateDate: undefined,
  showButtonLabels: false,
};

function mapStateToProps(state) {
  const { accessRights } = getAccessRightsProps(state);
  return { accessRights };
}

export default connect(mapStateToProps)(DMPStatusExtraActions);
