import merge from 'lodash.merge';
import { dmpconnectConfigurationActionConstants, dmpLandingPages } from '../constants';
import env from '../../envVariables';

const initialStateFromEnv = {
  hrVsmAutoOpen: Number(env.REACT_APP_VSM_AUTO_OPEN) === 1,
  minimumMinorAge: Number(env.REACT_APP_MINIMUM_MINOR_AGE || 0),
  dmpLandingPage: env.REACT_APP_DMP_LANDING_PAGE || dmpLandingPages.PARCOURS_SOIN,

  mssActive: Number(env.REACT_APP_MSS) === 1,
};

const initialState = {
  lastChangeDateTime: null,
  changeInterval: 365, // days

  sidebarIsOpened: true,

  ...initialStateFromEnv,
};


export function userPreferences(state = initialState, action) {
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.SET_USER_PREFERENCE:
      return merge({}, state, { ...action.preferences });
    default:
      return state;
  }
}
