import React, { Component } from 'react';
import { API_TYPES, apiSections } from 'dmpconnectjsapp-base/constants';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  formatCreateDpConnector,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  getApiType, getConfigurationValue,
  getCpxCode,
  getPracticeLocationSettingFromState,
  getPracticeSetting,
} from 'dmpconnectjsapp-base/helpers/accessors';

import { getAction } from '../../dmpconnect/actions';
import Loading from '../Common/Loading/Loading';
import CenteredCard from '../Common/Card/CenteredCard';

class DpConnector extends Component {
  componentDidMount() {
    const {
      dispatch,
      dpConnector,
      serverName,
      cpxCode,
      practiceSetting,
      practiceLocationSetting,
      apiType,
      cpxLoginType,
    } = this.props;
    if (apiType !== API_TYPES.REST && !isReady(dpConnector)) {
      dispatch(getAction(
        commands.createDpConnector,
        apiSections.DP_CONNECTOR_SECTION,
        formatCreateDpConnector(
          cpxCode,
          serverName,
          practiceSetting,
          practiceLocationSetting,
          cpxLoginType,
        ),
      ));
    }
  }

  render() {
    const { dpConnector, children, apiType } = this.props;
    if (apiType !== API_TYPES.REST && !isReady(dpConnector)) {
      return <CenteredCard><Loading message="chargement" /></CenteredCard>;
    }
    return children;
  }
}

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.DP_CONNECTOR_SECTION]: dpConnector,
    },
    dmpconnectDpConfiguration: {
      serverName,
    },
    dmpConnectPersistedAppConfiguration,
  } = state;

  return {
    apiType: getApiType(state),
    dpConnector,
    serverName,
    cpxCode: getCpxCode(state),
    practiceSetting: getPracticeSetting(state),
    practiceLocationSetting: getPracticeLocationSettingFromState(state),
    cpxLoginType: getConfigurationValue('cpxLoginType', dmpConnectPersistedAppConfiguration),
  };
}

DpConnector.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  dpConnector: PropTypes.object,
  serverName: PropTypes.string,
  cpxCode: PropTypes.string,
  practiceSetting: PropTypes.string,
  practiceLocationSetting: PropTypes.string,
  apiType: PropTypes.string.isRequired,
  cpxLoginType: PropTypes.string,
};

DpConnector.defaultProps = {
  children: null,
  dpConnector: {},
  serverName: '',
  cpxCode: '',
  practiceSetting: '',
  practiceLocationSetting: '',
  cpxLoginType: undefined,
};


const ConnectedDPConnector = connect(mapStateToProps)(DpConnector);

export default ConnectedDPConnector;
