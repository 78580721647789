import * as React from 'react';
import PropTypes from 'prop-types';
import { parseHrDocument } from 'dmpconnectjsapp-base/utils/clinicalDocument';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import Button from 'react-bootstrap/Button';
import { push } from 'connected-react-router';
import { hrSections, hrSectionsLabels } from '../../dmpconnect/constants/hrConstants';
import { getCurrentFilter } from '../../dmpconnect/helpers/searchUtils';
import {
  defaultHRDocFilters,
  displayHrSections,
  hrSectionToCardInfos,
} from '../../dmpconnect/helpers/hr';
import Alert from '../Common/Message/Alert';
import { filterKey } from './HRDocument';
import MobileHrEntry from './MobileHrEntry';
import MobileMeds from './MobileMeds';


const MobileHRDocument = ({
  cdaContent, parsedHr, filters, hrSection, medsUrl, dispatch, atcCode, cipCode,
}) => {
  const [defaultData, setDefaultData] = React.useState(cdaContent ? parseHrDocument(cdaContent) : {});
  const [data, setData] = React.useState(defaultData);

  React.useEffect(() => {
    if (defaultData && !defaultData.error) {
      // setData(filterHrDocument(defaultData, from, to, author));
      setData(defaultData);
    }
  }, [defaultData]);

  React.useEffect(() => {
    if (cdaContent) {
      setDefaultData(parseHrDocument(cdaContent));
    }
  }, [cdaContent]);

  React.useEffect(() => {
    if (parsedHr) {
      setDefaultData(parsedHr);
    }
  }, [parsedHr]);

  return (
    <div className="hr-display">
      {defaultData && defaultData.error ? (
        <Alert type="danger">
          <div className="mb-3">Une erreur s&apos;est produite pendant la lecture du document.</div>
          <ReactJson
            src={defaultData.error}
            name="détails"
            key="hr_error"
            collapsed={0}
            collapseStringsAfterLength={70}
            displayObjectSize={false}
            displayDataTypes={false}
          />
        </Alert>
      ) : (
        <>
          {data && (
          <>
            {!hrSection ? (
              <div className="hr-sections">
                {displayHrSections.map((section) => {
                  const nbEntries = data[section] ? data[section].values.length : 0;
                  return (
                    <section key={section}>
                      <Button
                        variant="link"
                        block
                        disabled={nbEntries === 0}
                        onClick={() => dispatch(push(`${medsUrl}/${section}`))}
                      >
                        <span className="label">{hrSectionsLabels[section]}</span>
                        {nbEntries > 0 && (
                        <>
                          <span className="num-docs">{nbEntries}</span>
                          <i className="ic-chevron-right" />
                        </>
                        )}
                      </Button>
                    </section>
                  );
                })}
              </div>
            ) : (
              <div className="hr-section">
                {data[hrSection] && data[hrSection].values.length > 0 && (
                <>
                  {hrSection === hrSections.PRESCRIPTIONS ? (
                    <MobileMeds
                      entries={data[hrSection].values}
                      atcCode={atcCode}
                      cipCode={cipCode}
                      medsUrl={medsUrl}
                    />
                  ) : (
                    <>
                      {data[hrSection].values.map((section, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <MobileHrEntry key={`${hrSection}-${index}`} {...hrSectionToCardInfos(hrSection, section)} />
                      ))}
                    </>
                  )}

                </>
                )}
              </div>
            )}
          </>
          )}
        </>
      )}
    </div>
  );
};

MobileHRDocument.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cdaContent: PropTypes.string,
  filters: PropTypes.object,
  parsedHr: PropTypes.object,
  hrSection: PropTypes.string,
  atcCode: PropTypes.string,
  cipCode: PropTypes.string,
  medsUrl: PropTypes.string.isRequired,
};

MobileHRDocument.defaultProps = {
  filters: defaultHRDocFilters,
  cdaContent: undefined,
  parsedHr: undefined,
  hrSection: undefined,
  atcCode: undefined,
  cipCode: undefined,
};

function mapStateToProps(state) {
  return {
    filters: getCurrentFilter(state, filterKey, defaultHRDocFilters),
  };
}

export default connect(mapStateToProps)(MobileHRDocument);
