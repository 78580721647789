import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAccessRightsProps, isTransactionAllowed } from 'dmpconnectjsapp-base/rules/accessRights';

export default function CheckAccessRights(WrappedComponent, transactionToCheck = null) {
  const CheckedComponent = class extends React.Component {
    render() {
      const { accessRights } = this.props;
      const transactionAllowed = isTransactionAllowed(accessRights, transactionToCheck);

      if (!transactionAllowed) return null;

      return <WrappedComponent {...this.props} />;
    }
  };

  CheckedComponent.propTypes = {
    accessRights: PropTypes.object.isRequired,
  };

  return connect(getAccessRightsProps)(CheckedComponent);
}
