import * as React from 'react';
import PropTypes from 'prop-types';

const MobilePSPerformer = ({ performer }) => {
  const {
    person: {
      name = '', given = '',
    } = {},
    professionCode,
    // professionLabel,
    // specialty,
    rpps,
    formation,
    es: { name: esName } = {},
  } = performer;

  return (
    <>
      <div className="mobile-ps">
        {!!professionCode && (
        <span className={`mr-1 badge-profession badge-profession-${professionCode}-${formation ? '2' : '1'}`} />
        )}
        <div className="d-flex flex-column align-items-start justify-content-start">
          {!!(given || name) && <span className="name">{`${given} ${name}`}</span>}
          {!!rpps && <span className="rpps">{rpps}</span>}
          {!!esName && <span className="name">{esName}</span>}
        </div>
      </div>
    </>
  );
};
MobilePSPerformer.propTypes = {
  performer: PropTypes.object.isRequired,
};

export default MobilePSPerformer;
