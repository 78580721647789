import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { remoteUserAuthorizationStatuses } from '../../constants/remoteConstants';

export const generateGetCurrentDmpBody = (requestId, directAuthenticationDmpStatus) => {
  if (isReady(directAuthenticationDmpStatus)) {
    const {
      ExistingTestAnswer: {
        AdminData: {
          Ins: {
            s_insType: insType,
            s_ins: ins,
          },
          s_birthday: birthday,
          s_patientGivenName: givenName,
          s_patientFirstBirthGiven: firstGivenName,
          s_patientName: name,
          s_patientBirthName: birthName,
        },
        i_userAuthorization: authorization,
      },
    } = directAuthenticationDmpStatus;

    return {
      '@status': 'open',
      '@authorization': remoteUserAuthorizationStatuses[authorization],
      ins: ins + insType,
      name,
      birthName,
      given: firstGivenName || givenName,
      birthday,
    };
  }

  return {
    '@status': 'no-dmp',
  };
};

export const generateGetCurrentDmpResponse = (requestId, directAuthenticationDmpStatus) => ({
  '@RelatesTo': requestId,
  '@status': 'urn:oasis:names:tc:ebxml-regrep:ResponseStatusType:Success',
  dmp: { ...generateGetCurrentDmpBody(requestId, directAuthenticationDmpStatus) },
});
