import React from 'react';
import * as Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import TitleTooltip from '../../Common/TitleTooltip';
import { getAldVitaleUrl } from '../../../dmpconnect/helpers/nav';

function ALDButton({
  subSection, className,
}) {
  return (
    <>
      <TitleTooltip
        id={`ald-button-tooltip-${subSection}`}
        text="Lister les Affections Longue Durée"
      >
        <Link
          id={`ald-button-${subSection}`}
          className={`btn btn-sand btn-sm ${className}`}
          to={getAldVitaleUrl(subSection)}
        >
          ALD
        </Link>
      </TitleTooltip>
      {/* {popupOpened && ( */}
      {/*  <ALDPopup */}
      {/*    setPopupOpened={() => setPopupOpened(false)} */}
      {/*    popupTitle="Liste des ALD" */}
      {/*    popupOpened={popupOpened} */}
      {/*    vitaleIndex={vitaleIndex} */}
      {/*    ins={ins} */}
      {/*  /> */}
      {/* )} */}
    </>
  );
}

ALDButton.propTypes = {
  subSection: Proptypes.string,
  className: Proptypes.string,
};
ALDButton.defaultProps = {
  subSection: undefined,
  className: '',
};
export default ALDButton;
