import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Button } from 'react-bootstrap';
import { getFullName } from '../../dmpconnect/helpers/accessibleDMPList';
import { dmpSexes } from '../../dmpconnect/constants/dmpConstants';
import Loading from '../Common/Loading/Loading';
import { dmpStatuses, userAuthorizationStatuses } from '../../dmpconnect/constants';
import TitleTooltip from '../Common/TitleTooltip';
import DMPAuthorization, { displayModes } from '../DMPAuthorization/DMPAuthorization';

const MobilePatient = ({
  patient,
  dmpStatusLoading,
  ins, status, authorization, accessDMP, aldActive,
  closingDate, closingMessage, certifiedInsError,
  isTreatingPhysician,
  noButton,
  tlsSubSection,
}) => {
  const [showAuthForm, setShowAuthForm] = React.useState(false);

  return (
    <>
      <Button
        variant="link"
        block
        onClick={() => {
          if (
            status === dmpStatuses.DMPExist
                && authorization === userAuthorizationStatuses.AuthorizationExist
          ) {
            accessDMP(ins);
          } else {
            setShowAuthForm(true);
          }
        }}
      >
        <div className="mobile-card">
          <div className="mobile-card-body">
            <div className="patient-infos">
              <div className="name">
                {getFullName(
                  patient.name,
                  patient.given || patient.firstBirthGiven,
                  patient.birthName,
                )}
              </div>
              <div className="birth">
                {!!patient.birthday && moment.utc(patient.birthday, 'YYYYMMDD').isValid() && (
                <span className="font-weight-normal">
                  {`Né${patient.sex === dmpSexes.FEMALE ? 'e' : ''} le ${moment.utc(patient.birthday, 'YYYYMMDD').format('DD/MM/YYYY')}`}
                </span>
                )}
              </div>
            </div>
            <div className="patient-action">
              {dmpStatusLoading ? (
                <Loading size="sm" />
              ) : (
                <i className="ic-chevron-right" />
              )}
            </div>
          </div>
          {(
            (
              status === dmpStatuses.DMPExist
                && (
                  authorization === userAuthorizationStatuses.AuthorizationExpired
                    || authorization === userAuthorizationStatuses.NoAuthorization
                )
            )
            || status === dmpStatuses.DMPIsClosed
            || status === dmpStatuses.DMPNotFound
            || certifiedInsError
          ) && (
          <div className="patient-footer danger">
            {certifiedInsError ? (
              <span>
                <i className="la la-question-circle mr-1" />
                <span>Impossible de récupérer l&apos;identité certifiée</span>
              </span>
            ) : (
              <>
                {status === dmpStatuses.DMPExist && (
                <>
                  {(
                    authorization === userAuthorizationStatuses.AuthorizationExpired
                              || authorization === userAuthorizationStatuses.NoAuthorization
                  ) && (
                    <span className="d-inline-flex align-items-center">
                      <i className="ic-exclamation-triangle mr-2" />
                      Vous n’avez pas l’autorisation d’accès au DMP
                    </span>
                  )}
                </>
                )}
                {status === dmpStatuses.DMPIsClosed && (
                <>
                  <i className="ic-exclamation-triangle mr-2" />
                  <div>
                    <div>
                      DMP fermé depuis le
                      {' '}
                      {moment(closingDate).format('DD/MM/YYYY')}
                      .
                      {' '}
                    </div>
                    <TitleTooltip
                      id={`dmp-closed-reason-${ins}`}
                      text={closingMessage.length > 50 ? closingMessage : undefined}
                    >
                      <div>
                        Raison :
                        {' '}
                        {closingMessage.length > 100 ? `${closingMessage.substring(0, 50)}...` : closingMessage}
                      </div>
                    </TitleTooltip>
                  </div>
                </>

                )}
                {status === dmpStatuses.DMPNotFound && (
                <span className="d-inline-flex align-items-center">
                  <i className="ic-exclamation-triangle mr-2" />
                  <span>DMP inexistant</span>
                </span>
                )}
              </>
            )}
          </div>
          )}
        </div>
      </Button>
      {showAuthForm && (
        <DMPAuthorization
          standalone
          displayMode={displayModes.NORMAL}
          ins={patient.ins}
          onClose={() => setShowAuthForm(false)}
          isMobile
        />
      )}
    </>
  );
};

MobilePatient.propTypes = {
  patient: PropTypes.object.isRequired,
  status: PropTypes.number,
  authorization: PropTypes.number,
  closingDate: PropTypes.string,
  closingMessage: PropTypes.string,
  isTreatingPhysician: PropTypes.bool,
  ins: PropTypes.string,
  accessDMP: PropTypes.func,
  aldActive: PropTypes.bool,
  certifiedInsError: PropTypes.bool,
  noButton: PropTypes.bool,
  dmpStatusLoading: PropTypes.bool,
  tlsSubSection: PropTypes.string,
};
MobilePatient.defaultProps = {
  status: null,
  authorization: null,
  closingDate: null,
  closingMessage: null,
  ins: null,
  accessDMP: null,
  aldActive: false,
  certifiedInsError: false,
  isTreatingPhysician: false,
  noButton: false,
  dmpStatusLoading: false,
  tlsSubSection: null,
};

export default MobilePatient;
