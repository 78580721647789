import * as React from 'react';
import Loading from "../Common/Loading/Loading";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import getIsMobileFromState from "../../dmpconnect/helpers/isMobile";

const MobileCss = React.lazy(() => import('./MobileCss'));
const DesktopCss = React.lazy(() => import('./DesktopCss'));

const CssSelector = ({ isMobile }) => {
    return (
        <>
            <React.Suspense fallback={<Loading centered/>}>
                {isMobile ? <MobileCss/> : <DesktopCss/>}
            </React.Suspense>
        </>
    );
};

CssSelector.propTypes = {
    isMobile: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
    return {
        isMobile: getIsMobileFromState(state),
    }
}

export default connect(mapStateToProps)(CssSelector);
