import React from 'react';
import PropTypes from 'prop-types';

const TopNotification = ({ children }) => (
  <div className="top-notification">
    {children}
  </div>
);

TopNotification.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TopNotification;
