import gte from 'semver/functions/gte';
import gt from 'semver/functions/gt';
import valid from 'semver/functions/valid';

export const checkDMPConnectJSVersion = (version, minimumVersion) => (
  !!valid(version) && !!valid(minimumVersion) && gte(version, minimumVersion)
);
export const checkDMPConnectJSVersiongt = (version, minimumVersion) => (
  !!valid(version) && !!valid(minimumVersion) && gt(version, minimumVersion)
);
export const formatVersion = (version) => {
  const [major, minor, patch, beta] = version.split('.');

  return `${major}.${minor || 0}.${patch || 0}${beta ? `-beta${beta}` : ''}`;
};
