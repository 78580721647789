import { newsActionConstants } from '../constants';

const initialState = {};

export function news(state = initialState, action) {
  switch (action.type) {
    case newsActionConstants.DMPC_SET_USER_NEWS: {
      const {
        [action.psId]: newsArray,
        ...rest
      } = state;
      return { ...rest, [action.psId]: { news: action.news } };
    }
    default:
      return state;
  }
}
