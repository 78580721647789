export const groupBy = (arr, groupby) => arr.reduce((acc, curr) => {
  const { [groupby]: key, ...val } = curr;
  (acc[key] = acc[key] || []).push(val);
  return acc;
}, {});

export const groupByFunc = (arr, groupby) => arr.reduce((acc, curr) => {
  const groupByKey = groupby(curr);
  (acc[groupByKey] = acc[groupByKey] || []).push(curr);
  return acc;
}, {});

export const explodeHash = (hash) => {
  const cleanedHash = hash.slice(1);
  return cleanedHash.split('&').reduce((result, item) => {
    const parts = item.split('=');
    return { ...result, [parts[0]]: parts[1] };
  }, {});
};
