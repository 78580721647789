import { formatSendMssOtpAuthenticatedEmail, formatSendMssSmtpEmail, } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands, { mssSubTypes }                                       from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections, mssLoginTypes }                                  from 'dmpconnectjsapp-base/constants';
import { getApiType, getMssApiType }                                   from 'dmpconnectjsapp-base/helpers/accessors';
import moment                                                          from 'moment';
import * as yup                                                        from 'yup';
import '../../utils/formUtils';
import env                                                             from '../../../envVariables';
import { getAction, sendMssWebEmail, setMssConfiguration }             from '../../actions';
import { sendRemoteOutMessage }                                        from '../../actions/dmpconnectRemoteActions';
import { API_TYPES }                                                   from '../../constants';
import { errorActions }                                                from '../../errors/errorActions';
import { verifyJwtPromise }                                            from '../../sagas/remoteSagas';
import { generateRandomId, generateUniqueId }                          from '../../utils/dataUtils';
import { checkDMPConnectJSVersion }                                    from '../../utils/version';

export const MssConfigSchema = yup.object({
  mssEmail: yup.string()
    .email('Ce n\'est pas une adresse email valide (exemple@domaine.com)').required('Ce champs est requis'),
  // mssImapServer: yup.string().required('Ce champs est requis'),
  // mssSmtpServer: yup.string().required('Ce champs est requis'),
});

export const mssFolderTypes = {
  INBOX: 'inbox',
  SENT : 'sent',
  JUNK : 'junk',
  TRASH: 'trash',
};

export const isMssPatientEmail = (emailAdress) => {
  const regexp = /^(\d{6}[0-9ABab]\d{8})@patient.mssante.fr$/;
  return regexp.test(emailAdress);
};

export function dispatchMarkMessagesAsRead(dispatch, mssEmail, messageIds, read, folderId, mssApiType, imapSynchronous) {
  if (mssApiType === mssSubTypes.IMAP) {
    messageIds.forEach(messageId => dispatch(getAction(
      commands.updateMessagesMSS,
      apiSections.MSS_UPDATE_MESSAGE,
      {
        email     : mssEmail,
        messageIds: [messageId],
        operation : 'READ',
        add       : read,
        folderId,
      },
      {
        subSection  : messageId,
        subConfig   : mssApiType,
        synchronous : imapSynchronous,
        contextExtra: {
          email      : mssEmail,
          messageIds : [messageId],
          operation  : read === true ? 'READ' : 'UNREAD',
          mssApiType,
          priority   : true,
          forceAction: errorActions.NONE,
        },
      },
    )));
  } else {
    dispatch(getAction(
      commands.updateMessagesMSS,
      apiSections.MSS_UPDATE_MESSAGE,
      {
        email    : mssEmail,
        messageIds,
        operation: read === true ? 'READ' : 'UNREAD',
        folderId,
      },
      {
        subSection  : messageIds.join(','),
        subConfig   : mssApiType,
        synchronous : true,
        contextExtra: {
          email      : mssEmail,
          messageIds,
          operation  : read === true ? 'READ' : 'UNREAD',
          mssApiType,
          priority   : true,
          forceAction: errorActions.NONE,
        },
      },
    ));
  }
}

export function dispatchMoveMessagesTo(dispatch, mssEmail, messageIds, sourceFolderId, destinationFolderId, mssApiType, imapSynchronous) {
  if (mssApiType === mssSubTypes.IMAP) {
    // lastMessageId will be used to trigger the destination folder refresh
    // after the result of the last move, we trigger a refresh
    const lastMessageId = messageIds.slice(-1);
    
    messageIds.forEach((messageId) => {
      dispatch(getAction(
        commands.moveMSSMessages,
        apiSections.MSS_MOVE_MESSAGES,
        {
          email     : mssEmail,
          messageIds: [messageId],
          destinationFolderId,
          sourceFolderId,
        },
        {
          subSection  : messageId,
          subConfig   : mssApiType,
          synchronous : imapSynchronous,
          contextExtra: {
            mssApiType,
            priority          : true,
            email             : mssEmail,
            sourceFolderId,
            destinationFolderId,
            forceAction       : errorActions.NONE,
            refreshDestination: String(lastMessageId) === String(messageId),
          },
        },
      ));
    });
  } else {
    dispatch(getAction(
      commands.moveMSSMessages,
      apiSections.MSS_MOVE_MESSAGES,
      {
        email: mssEmail,
        messageIds,
        destinationFolderId,
        sourceFolderId,
      },
      {
        subSection  : messageIds.join(','),
        subConfig   : mssApiType,
        synchronous : true,
        contextExtra: {
          mssApiType,
          priority          : true,
          email             : mssEmail,
          sourceFolderId,
          destinationFolderId,
          forceAction       : errorActions.NONE,
          refreshDestination: true,
        },
      },
    ));
  }
}

export function dispatchDeleteMessages(dispatch, mssEmail, messageIds, folderId, mssApiType, imapSynchronous) {
  if (mssApiType === mssSubTypes.IMAP) {
    messageIds.forEach(messageId => dispatch(getAction(
      commands.deleteMssMessages,
      apiSections.MSS_DELETE_MESSAGES,
      {
        email     : mssEmail,
        messageIds: [messageId],
        folderId,
      },
      {
        subSection  : messageIds.join(','),
        subConfig   : mssApiType,
        synchronous : imapSynchronous,
        contextExtra: {
          mssApiType,
          priority   : true,
          forceAction: errorActions.NONE,
        },
      },
    )));
  } else {
    dispatch(getAction(
      commands.deleteMssMessages,
      apiSections.MSS_DELETE_MESSAGES,
      {
        email: mssEmail,
        messageIds,
        folderId,
      },
      {
        subSection  : messageIds.join(','),
        subConfig   : mssApiType,
        synchronous : true,
        contextExtra: {
          mssApiType,
          priority   : true,
          forceAction: errorActions.NONE,
        },
      },
    ));
  }
}

export function getSendMssEmailAction(mssApiType, apiType, emailContent, esUser, hpAuthenticationMode, subSection) {
  let action;
  if (mssApiType === mssSubTypes.WEB) {
    action = sendMssWebEmail(
      apiType === API_TYPES.REST
      ? formatSendMssOtpAuthenticatedEmail(emailContent, {
        ...esUser,
        hpAuthenticationMode: esUser.hpAuthenticationMode || hpAuthenticationMode,
      })
      : emailContent,
      esUser,
    );
  } else if (mssApiType === mssSubTypes.IMAP) {
    action = getAction(
      commands.sendMssSmtpEmail,
      apiSections.MSS_SEND_SMTP_EMAIL,
      formatSendMssSmtpEmail({ ...emailContent, esUser }, apiType === API_TYPES.REST),
      {
        synchronous : true,
        contextExtra: { priority: true, mssEmailConfig: emailContent.sender },
        silentError : true,
        subSection,
      },
    );
  }
  return action;
}

export const flatFolders = (folders = []) => folders.reduce((result, folder) => {
  if (folder.folders && folder.folders.length > 0) {
    return [...result, folder, ...flatFolders(folder.folders)];
  }
  return [...result, folder];
}, []);


export const isImapSynchronous = (state) => {
  const {
          dmpconnect: {
            [apiSections.SESSION_SECTION]: {
              s_serviceVersion,
            },
          },
        }          = state;
  const apiType    = getApiType(state);
  const mssApiType = getMssApiType(state);
  
  return !(
    [API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)
    && checkDMPConnectJSVersion(s_serviceVersion, '1.7.1')
    && mssApiType === mssSubTypes.IMAP
  );
};

export const getMssConfigFromOperatorConfig = (operatorConfig) => {
  let mssConfig;
  if (operatorConfig) {
    mssConfig = {
      mssLoginType: operatorConfig.loginType,
      mssApiType  : operatorConfig.api,
      mssOperator : operatorConfig.id,
    };
    if (Number(env.REACT_APP_PRODUCTON_MODE) === 1) {
      if (operatorConfig.prod) {
        mssConfig = {
          ...mssConfig,
          mssImapServer: operatorConfig.prod.imapServer,
          mssSmtpServer: operatorConfig.prod.smtpServer,
          
          mssImapPort: operatorConfig.prod.imapPort,
          mssSmtpPort: operatorConfig.prod.smtpPort,
        };
      }
    } else if (operatorConfig.dev) {
      mssConfig = {
        ...mssConfig,
        mssImapServer: operatorConfig.dev.imapServer,
        mssSmtpServer: operatorConfig.dev.smtpServer,
        
        mssImapPort: operatorConfig.dev.imapPort,
        mssSmtpPort: operatorConfig.dev.smtpPort,
      };
    }
  }
  return mssConfig;
};

const imapSmtpServerValidator            = yup.object({
  imapServer: yup.string().required(),
  imapPort  : yup.number().required(),
  smtpServer: yup.string().required(),
  smtpPort  : yup.number().required(),
}).notRequired().default(undefined);
export const mssOperatorsConfigValidator = yup.array().of(yup.object({
  id                 : yup.string().required(),
  name               : yup.string().required(),
  api                : yup.string().oneOf(Object.values(mssSubTypes)).required(),
  loginType          : yup.string().oneOf(Object.values(mssLoginTypes)).required(),
  showImapLogin      : yup.bool(),
  enableLoginPassword: yup.bool(),
  dev                : imapSmtpServerValidator,
  prod               : imapSmtpServerValidator,
}));

export const isMssTokenValid = async (externalPscAccessToken, mssPscAccessToken, mssLoginType, dispatch) => {
  if (!externalPscAccessToken || mssLoginType !== mssLoginTypes.PSC) {
    return true;
  }
  
  if (!mssPscAccessToken) {
    dispatch(setMssConfiguration('mssPscAccessToken', ''));
    dispatch(sendRemoteOutMessage({
      request: {
        '@data': 'pscAccessToken',
        '@id'  : `{${generateUniqueId().toUpperCase()}}`,
      },
    }));
    return false;
  }
  
  const { err } = await verifyJwtPromise(mssPscAccessToken);
  
  if (err) {
    dispatch(setMssConfiguration('mssPscAccessToken', ''));
    dispatch(sendRemoteOutMessage({
      request: {
        '@data': 'pscAccessToken',
        '@id'  : `{${generateUniqueId().toUpperCase()}}`,
        details: [{ ...err }],
      },
    }));
    return false;
  }
  
  return true;
};

export const formatMssMessageSubject = (patientInfos, attachmentsNumber, typeCodeLabel) => {
  const name     = patientInfos.s_patientBirthName || patientInfos.s_patientName;
  const given    = patientInfos.s_patientFirstBirthGiven;
  const birthday = moment.utc(patientInfos.s_birthday, 'YYYYMMDD').format('DD/MM/YYYY');
  if (attachmentsNumber > 1) {
    return `XDM/1.0/DDM+${attachmentsNumber} documents ${name} ${given} ${birthday}`;
  }
  return `XDM/1.0/DDM+${typeCodeLabel.substring(0, 40)} ${name} ${given} ${birthday}`;
};

export function generateMessageId() {
  const localPart = `${generateRandomId(8)}.${generateRandomId(4)}.${generateRandomId(4)}.${generateRandomId(4)}.${generateRandomId(12)}`;
  const domain    = 'efficience';
  
  return `${localPart}@${domain}`;
}

export function getDefaultMssAccountLabel(account, mssAccounts, mssOperatorsConfig) {
  if (!account.mssEmail) {
    return '';
  }
  const label = `${account.mssSenderWording || ''} <${account.mssEmail}>`;
  
  const config = mssOperatorsConfig.find(operator => operator.id === account.mssOperator);
  if (mssAccounts.filter(a => a.mssEmail === account.mssEmail && a.id !== account.id).length > 0 && config) {
    return `${label} - ${config.name}`;
  }
  return label;
}
