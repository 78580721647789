import { dmpconnectPDFsActionConstants } from '../constants';

export const setDMPAckPDF = content => ({
  type: dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_ACK,
  content,
});
export const setDMPOtpPDF = content => ({
  type: dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_OTP,
  content,
});
export const setDMPCreatePDF = content => ({
  type: dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_CREATE,
  content,
});
export const resetPDFs = () => ({
  type: dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_RESET,
});
