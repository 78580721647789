import { mssReceiptNotificationTypes } from 'dmpconnectjsapp-base/constants';
import { b64DecodeUnicode } from '../../utils/dataUtils';
import {
  extractAdditionalAuthors,
  extractEventCodes,
  extractInformants,
  extractIntendedRecipients, extractPerformer,
  extractTreatingPhysician,
} from './cdaData';
import { calculateVisibility } from '../../rules/documentRules';
import { extractInsiIdentity, extractJSONInsiIdentity } from './insi';
import {
  extractJSONAdditionalAuthors,
  extractJSONEventCodes,
  extractJSONInformants,
  extractJSONIntendedRecipients, extractJSONPerformer, extractJSONTreatingPhysician,
} from './cdaDataJSON';

function extractAttachment(attachment) {
  const parsedEventCodes = extractEventCodes(attachment);
  const parsedAdditionalAuthors = extractAdditionalAuthors(attachment);
  const parsedInformants = extractInformants(attachment);
  const parsedIntendedRecipients = extractIntendedRecipients(attachment);

  const {
    patientIns: { value: patientIns = '' } = {},
    uniqueId: { value: uniqueId = '' } = {},
    stylesheet: { value: stylesheet = '' } = {},
    fileContentInBase64: { value: fileContentInBase64 = '' } = {},
    documentTitle: { value: documentTitle = '' } = {},
    documentDescription: { value: documentDescription = '' } = {},
    documentCategory: { value: documentCategory = '' } = {},
    documentFormat: { value: documentFormat = '' } = {},
    healthcareSetting: { value: healthcareSetting = '' } = {},
    versionNumber: { value: versionNumber = '' } = {},
    setIdRoot: { value: setIdRoot = '' } = {},
    setIdExtension: { value: setIdExtension = '' } = {},
    replacedDocumentUniqueId: { value: replacedDocumentUniqueId = '' } = {},
    invisiblePatient: { value: invisiblePatient } = {},
    masquePS: { value: masquePS } = {},
    invisibleRepresentantLegal: { value: invisibleRepresentant } = {},
  } = attachment;

  return {
    patientIns,
    uniqueId,
    stylesheet,
    fileContentInBase64,
    documentTitle,
    documentDescription,
    documentCategory,
    documentFormat: Number(documentFormat),
    healthcareSetting,
    versionNumber,
    setIdRoot,
    setIdExtension,
    replacedDocumentUniqueId,
    treatingPhysician: extractTreatingPhysician(attachment),
    performer: extractPerformer(attachment),
    additionalAuthors: parsedAdditionalAuthors && parsedAdditionalAuthors.length > 0
      ? parsedAdditionalAuthors
      : undefined,
    intendedRecipients: parsedIntendedRecipients && parsedIntendedRecipients.length > 0
      ? parsedIntendedRecipients
      : undefined,
    informants: parsedInformants && parsedInformants.length > 0
      ? parsedInformants
      : undefined,
    eventCodes: parsedEventCodes,
    invisiblePatient,
    masquePS,
    invisibleRepresentant,
    documentVisibility: calculateVisibility(
      invisiblePatient,
      masquePS,
      invisibleRepresentant,
      true,
    ),
  };
}

function extractJSONAttachment(attachment) {
  const parsedEventCodes = extractJSONEventCodes(attachment);
  const parsedAdditionalAuthors = extractJSONAdditionalAuthors(attachment);
  const parsedInformants = extractJSONInformants(attachment);
  const parsedIntendedRecipients = extractJSONIntendedRecipients(attachment);

  const {
    patientIns = '',
    uniqueId = '',
    stylesheet = '',
    fileContentInBase64 = '',
    documentTitle = '',
    documentDescription = '',
    documentCategory = '',
    documentFormat = '',
    healthcareSetting = '',
    versionNumber = '',
    setIdRoot = '',
    setIdExtension = '',
    replacedDocumentUniqueId = '',
    invisiblePatient,
    masquePS,
    invisibleRepresentant,
  } = attachment;

  return {
    patientIns,
    uniqueId,
    stylesheet,
    fileContentInBase64,
    documentTitle,
    documentDescription,
    documentCategory,
    documentFormat: Number(documentFormat),
    healthcareSetting,
    versionNumber,
    setIdRoot,
    setIdExtension,
    replacedDocumentUniqueId,
    treatingPhysician: extractJSONTreatingPhysician(attachment),
    performer: extractJSONPerformer(attachment),
    additionalAuthors: parsedAdditionalAuthors && parsedAdditionalAuthors.length > 0
      ? parsedAdditionalAuthors
      : undefined,
    intendedRecipients: parsedIntendedRecipients && parsedIntendedRecipients.length > 0
      ? parsedIntendedRecipients
      : undefined,
    informants: parsedInformants && parsedInformants.length > 0
      ? parsedInformants
      : undefined,
    eventCodes: parsedEventCodes,
    invisiblePatient,
    masquePS,
    invisibleRepresentant,
    documentVisibility: calculateVisibility(
      invisiblePatient,
      masquePS,
      invisibleRepresentant,
      true,
    ),
  };
}

export const extractSendMssMessageParams = (data) => {
  try {
    const {
      recipient,
      cc,
      bcc,
      mssAccount: { value: mssAccount } = {},
      sendInBackground: { value: sendInBackground } = {},
      modal: { value: modal } = {},
      modalMessage: { value: modalMessage } = {},
      senderWording: { value: senderWording } = {},
      replyTo: { value: replyTo } = {},
      messageId: { value: messageId } = {},
      inReplyToMessageIds: { value: inReplyToMessageIds } = {},
      references: { value: references } = {},
      title: { value: title } = {},
      content: { value: content } = {},
      attachments,
      rawAttachments,
      returnReceiptTo: { value: returnReceiptTo } = {},
      dispositionNotificationTo: { value: dispositionNotificationTo } = {},
      AdditionalPatientIdentifiers,
      insIsNotQualified: { value: insIsNotQualified } = {},
      ignorePdfA1Transparency: { value: ignorePdfA1Transparency } = {},
      disabledPdfA1Conversion: { value: disabledPdfA1Conversion } = {},
      disableIheXdmPdfDataMatrixBlock: { value: disableIheXdmPdfDataMatrixBlock } = {},
      disableIheXdmPdfTitlePage: { value: disableIheXdmPdfTitlePage } = {},
      getDocumentContent: { value: getDocumentContent } = {},
      forbidRecipientAnswer: { value: forbidRecipientAnswer } = {},
    } = data;

    let recipients;
    if (recipient && recipient.email) {
      if (!Array.isArray(recipient.email)) {
        recipients = [recipient.email];
      } else {
        recipients = recipient.email;
      }
    }
    let ccs;
    if (cc && cc.email) {
      if (!Array.isArray(cc.email)) {
        ccs = [cc.email];
      } else {
        ccs = cc.email;
      }
    }
    let bccs;
    if (bcc && bcc.email) {
      if (!Array.isArray(bcc.email)) {
        bccs = [bcc.email];
      } else {
        bccs = bcc.email;
      }
    }

    let attachmentArray;
    if (attachments && attachments.attachment) {
      if (!Array.isArray(attachments.attachment)) {
        attachmentArray = [attachments.attachment];
      } else {
        attachmentArray = attachments.attachment;
      }
    }

    let AdditionalPatientIdentifiersArray;
    if (AdditionalPatientIdentifiers && AdditionalPatientIdentifiers.AdditionalPatientIdentifier) {
      if (!Array.isArray(AdditionalPatientIdentifiers.AdditionalPatientIdentifier)) {
        AdditionalPatientIdentifiersArray = [AdditionalPatientIdentifiers.AdditionalPatientIdentifier];
      } else {
        AdditionalPatientIdentifiersArray = AdditionalPatientIdentifiers.AdditionalPatientIdentifier;
      }
    }

    let rawAttachmentArray;
    if (rawAttachments && rawAttachments.rawAttachment) {
      if (!Array.isArray(rawAttachments.rawAttachment)) {
        rawAttachmentArray = [rawAttachments.rawAttachment];
      } else {
        rawAttachmentArray = rawAttachments.rawAttachment;
      }
    }

    let notificationReceiver;
    let notificationReceiversType;
    if (returnReceiptTo) {
      notificationReceiver = returnReceiptTo;
      notificationReceiversType = mssReceiptNotificationTypes.DSN;
    }
    if (dispositionNotificationTo) {
      notificationReceiver = dispositionNotificationTo;
      notificationReceiversType = mssReceiptNotificationTypes.MDN;
    }
    let insiIdentity;
    if (data.patient) {
      insiIdentity = extractInsiIdentity(data.patient);
    }

    return {
      mssAccount,
      sendInBackground,
      modal,
      modalMessage,
      fromRemoteControl: true,
      senderWording,
      replyTo,
      messageId,
      inReplyToMessageIds,
      references,
      recipients: recipients.map(email => email.value).join(';'),
      cc: ccs ? ccs.map(email => email.value).join(';') : '',
      bcc: bccs ? bccs.map(email => email.value).join(';') : '',
      title,
      // replace unicode control chars
      messageContent: content ? b64DecodeUnicode(content).replace(/\p{C}/gu, '') : '',
      notificationReceiver,
      notificationReceiversType,
      attachments: attachmentArray
        ? attachmentArray.map(attachment => extractAttachment(attachment))
        : [],
      otherAttachments: rawAttachmentArray
        ? rawAttachmentArray.map(attachment => ({
          contentType: attachment.contentType.value,
          fileContentInBase64: attachment.fileContentInBase64.value,
          documentTitle: attachment.documentTitle.value,
        }))
        : [],
      insiIdentity,
      AdditionalPatientIdentifiers: AdditionalPatientIdentifiersArray
        ? AdditionalPatientIdentifiersArray.map(identifier => ({
          s_root: identifier.patientIdentifierRootOid ? identifier.patientIdentifierRootOid.value : undefined,
          s_extension: identifier.patientIdentifier ? identifier.patientIdentifier.value : undefined,
        }))
        : undefined,
      insIsNotQualified,
      ignorePdfA1Transparency,
      disabledPdfA1Conversion,
      disableIheXdmPdfTitlePage,
      disableIheXdmPdfDataMatrixBlock,
      getDocumentContent: Number(getDocumentContent) === 1,
      endConversation: Number(forbidRecipientAnswer) === 1,
    };
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const extractJSONSendMssMessageParams = (data) => {
  try {
    const {
      mssAccount,
      recipient: recipients,
      cc: ccs,
      bcc: bccs,
      sendInBackground,
      modal,
      modalMessage,
      senderWording,
      replyTo,
      messageId,
      inReplyToMessageIds,
      references,
      title,
      content,
      attachments: attachmentArray,
      rawAttachments: rawAttachmentArray,
      returnReceiptTo,
      dispositionNotificationTo,
      AdditionalPatientIdentifiers,
      insIsNotQualified,
      ignorePdfA1Transparency,
      disabledPdfA1Conversion,
      disableIheXdmPdfTitlePage,
      disableIheXdmPdfDataMatrixBlock,
      getDocumentContent,
      forbidRecipientAnswer,
    } = data;

    let notificationReceiver;
    let notificationReceiversType;
    if (returnReceiptTo) {
      notificationReceiver = returnReceiptTo;
      notificationReceiversType = mssReceiptNotificationTypes.DSN;
    }
    if (dispositionNotificationTo) {
      notificationReceiver = dispositionNotificationTo;
      notificationReceiversType = mssReceiptNotificationTypes.MDN;
    }
    let insiIdentity;
    if (data.patient) {
      insiIdentity = extractJSONInsiIdentity(data.patient);
    }

    return {
      mssAccount,
      sendInBackground,
      modal,
      modalMessage,
      fromRemoteControl: true,
      senderWording,
      replyTo,
      messageId,
      inReplyToMessageIds,
      references,
      recipients: recipients.join(';'),
      cc: ccs ? ccs.join(';') : '',
      bcc: bccs ? bccs.join(';') : '',
      title,
      // replace unicode control chars
      messageContent: content ? b64DecodeUnicode(content).replace(/\p{C}/gu, '') : '',
      notificationReceiver,
      notificationReceiversType,
      attachments: attachmentArray
        ? attachmentArray.map(attachment => extractJSONAttachment(attachment))
        : [],
      otherAttachments: rawAttachmentArray || [],
      insiIdentity,
      AdditionalPatientIdentifiers: AdditionalPatientIdentifiers
        ? AdditionalPatientIdentifiers.map(identifier => ({
          s_root: identifier.patientIdentifierRootOid,
          s_extension: identifier.patientIdentifier,
        }))
        : undefined,
      insIsNotQualified,
      ignorePdfA1Transparency,
      disabledPdfA1Conversion,
      disableIheXdmPdfTitlePage,
      disableIheXdmPdfDataMatrixBlock,
      getDocumentContent,
      endConversation: forbidRecipientAnswer,
    };
  } catch (e) {
    console.log(e);
    return false;
  }
};
