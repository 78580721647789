export const mssOperatorsConfig = [
  {
    id: 'mailiz-web-cpx',
    name: 'Mailiz WS - authentification CPX',
    api: 'WEB',
    loginType: 'CPX',
  },
  {
    id: 'mailiz-web-otp',
    name: 'Mailiz WS - authentification OTP',
    api: 'WEB',
    loginType: 'OTP',
  },
  {
    id: 'mailiz-imap-cpx',
    name: 'Mailiz API_LPS - authentification CPx',
    api: 'IMAP',
    loginType: 'CPX',
    showImapLogin: false,
  },
  {
    id: 'mailiz-imap-cert',
    name: 'Mailiz API_LPS - authentification Certificat',
    api: 'IMAP',
    loginType: 'CERT',
    showImapLogin: false,
  },
  {
    id: 'mailiz-imap-psc',
    name: 'Mailiz API_LPS - authentification PSC',
    api: 'IMAP',
    loginType: 'PSC',
    showImapLogin: false,
    dev: {
      imapServer: 'mail-psc.formation.mailiz.mssante.fr',
      imapPort: 143,
      smtpServer: 'mail-psc.formation.mailiz.mssante.fr',
      smtpPort: 587,
    },
    prod: {
      imapServer: 'mail-psc.mailiz.mssante.fr',
      imapPort: 143,
      smtpServer: 'mail-psc.mailiz.mssante.fr',
      smtpPort: 587,
    },
  }
];
