import { dmpCommandFailureContextualizedType, dmpCommandSuccessContextualizedType, } from 'dmpconnectjsapp-base/actions';
import commands                                                                      from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections }                                                               from 'dmpconnectjsapp-base/constants';
import { getApiType, getDmpConnectJSHost, getDmpConnectJSPort, }                     from 'dmpconnectjsapp-base/helpers/accessors';
import JSZip                                                                         from 'jszip';
import moment                                                                        from 'moment';
import { call, delay, put, race, select, take, takeEvery, }                          from 'redux-saga/effects';
import env                                                                           from '../../envVariables';
import { getAction, gotLogs }                                                        from '../actions';
import { API_TYPES, dmpconnectApplicationActionConstants }                           from '../constants';
import { getSavedLogsLevel }                                                         from '../helpers/logs';


function* getLogsLevelHandler() {
  yield put(getAction(
    commands.getLogLevels,
    apiSections.GET_LOG_LEVELS,
    undefined,
    {
      silentError: true,
    },
  ));
}

function* setLogLevelsHandler(action) {
  const { dmpConnectLogLevel, dmpConnectJsLogLevel } = action;
  
  yield put(getAction(
    commands.setLogLevels,
    apiSections.SET_LOG_LEVELS,
    { dmpConnectLogLevel, dmpConnectJsLogLevel },
    {
      silentError: true,
    },
  ));
  
  yield take([
    dmpCommandSuccessContextualizedType(apiSections.SET_LOG_LEVELS),
    dmpCommandFailureContextualizedType(apiSections.SET_LOG_LEVELS),
  ]);
  yield call(getLogsLevelHandler);
}

export function* setDefaultLogsLevel() {
  const apiType = yield select(getApiType);
  if (apiType !== API_TYPES.REST) {
    const savedLoglevels = yield select(getSavedLogsLevel);
    yield call(getLogsLevelHandler);
    const logLevels = yield take([
      dmpCommandSuccessContextualizedType(apiSections.GET_LOG_LEVELS),
      dmpCommandFailureContextualizedType(apiSections.GET_LOG_LEVELS),
    ]);
    if (logLevels.type === dmpCommandSuccessContextualizedType(apiSections.GET_LOG_LEVELS)) {
      const { data: { i_dmpConnectLogLevel, i_dmpConnectJsLogLevel } } = logLevels;
      const { dmpConnectLogLevel, dmpConnectJsLogLevel }               = savedLoglevels;
      if (dmpConnectLogLevel !== i_dmpConnectLogLevel || dmpConnectJsLogLevel !== i_dmpConnectJsLogLevel) {
        if (
          dmpConnectLogLevel !== Number(env.REACT_APP_DEFAULT_DMPC_LOG_LEVEL)
          || dmpConnectJsLogLevel !== Number(env.REACT_APP_DEFAULT_DMPCJS_LOG_LEVEL)
        ) {
          yield call(setLogLevelsHandler, { dmpConnectLogLevel, dmpConnectJsLogLevel });
        }
      }
    }
  }
}

function* resetLogLevelsHandler() {
  yield put(getAction(
    commands.setLogLevels,
    apiSections.SET_LOG_LEVELS,
    {
      dmpConnectLogLevel  : Number(env.REACT_APP_DEFAULT_DMPC_LOG_LEVEL),
      dmpConnectJsLogLevel: Number(env.REACT_APP_DEFAULT_DMPCJS_LOG_LEVEL),
    },
    {
      silentError: true,
    },
  ));
  
  yield take([
    dmpCommandSuccessContextualizedType(apiSections.SET_LOG_LEVELS),
    dmpCommandFailureContextualizedType(apiSections.SET_LOG_LEVELS),
  ]);
  yield call(getLogsLevelHandler);
}

const generateZip = async zip => zip.generateAsync({ type: 'base64' });

function* getLogsHandler(action) {
  const { resetLogLevel, error } = action;
  const zip                      = new JSZip();
  
  if (error) {
    const { s_requestFrameInBase64, s_answerFrameInBase64, ...filteredError } = error;
    
    if (s_requestFrameInBase64) {
      zip.file('request.xml', s_requestFrameInBase64, { base64: true });
    }
    
    if (s_answerFrameInBase64) {
      zip.file('response.xml', s_answerFrameInBase64, { base64: true });
    }
    
    zip.file('efficience-error.json', JSON.stringify(filteredError, null, 4));
  }
  
  
  const apiType = yield select(getApiType);
  
  if ([API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)) {
    const dmpcjsHost = yield select(getDmpConnectJSHost);
    const dmpcjsPort = yield select(getDmpConnectJSPort);
    
    try {
      const { response } = yield race({
        response: call(fetch, `https://${dmpcjsHost}:${dmpcjsPort}`, {
          method: 'get',
        }),
        timeout : delay(30 * 1000),
      });
      
      if (response.status > 0) {
        const data = yield call([response, response.text]);
        zip.file('sessions.html', data);
      }
    } catch (e) {
      console.error('error while gettings connector sessions', e);
    }
  }
  
  yield put(getAction(
    commands.getSystemInformation,
    apiSections.GET_SYSTEM_INFO,
    undefined,
    {
      silentError: true,
    },
  ));
  const systemInfoResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.GET_SYSTEM_INFO),
    dmpCommandFailureContextualizedType(apiSections.GET_SYSTEM_INFO),
  ]);
  
  if (systemInfoResult.type === dmpCommandSuccessContextualizedType(apiSections.GET_SYSTEM_INFO)) {
    zip.file('system-informations.json', JSON.stringify(systemInfoResult.data, null, 4));
  }
  
  yield put(getAction(
    commands.getLogsTail,
    apiSections.GET_LOG_TAIL,
    {
      getServerLog     : 1,
      getDmpConnectLog : 1,
      nbServerLines    : 5000,
      nbDmpConnectLines: 10000,
    },
    {
      silentError: true,
    },
  ));
  const masterResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.GET_LOG_TAIL),
    dmpCommandFailureContextualizedType(apiSections.GET_LOG_TAIL),
  ]);
  
  
  if (masterResult.type === dmpCommandSuccessContextualizedType(apiSections.GET_LOG_TAIL)) {
    const master                                                 = zip.folder('master');
    const { data: { s_dmpConnectLogBase64, s_serverLogBase64 } } = masterResult;
    
    master.file('dmp-connect.log', s_dmpConnectLogBase64, { base64: true });
    master.file(
      apiType !== API_TYPES.JSON ? 'dmpconnect-json.log' : 'dmpconnect-js2.log',
      s_serverLogBase64,
      { base64: true },
    );
  }
  
  if (apiType !== API_TYPES.JSON) {
    yield put(getAction(
      commands.getSessionLogsTail,
      apiSections.GET_SESSION_LOG_TAIL,
      {
        getServerLog     : 1,
        getDmpConnectLog : 1,
        nbServerLines    : 5000,
        nbDmpConnectLines: 10000,
      },
      {
        silentError: true,
      },
    ));
    const slaveResult = yield take([
      dmpCommandSuccessContextualizedType(apiSections.GET_SESSION_LOG_TAIL),
      dmpCommandFailureContextualizedType(apiSections.GET_SESSION_LOG_TAIL),
    ]);
    
    if (slaveResult.type === dmpCommandSuccessContextualizedType(apiSections.GET_SESSION_LOG_TAIL)) {
      const slave                                                  = zip.folder('slave');
      const { data: { s_dmpConnectLogBase64, s_serverLogBase64 } } = slaveResult;
      
      slave.file('dmp-connect.log', s_dmpConnectLogBase64, { base64: true });
      slave.file('dmpconnect-js2.log', s_serverLogBase64, { base64: true });
    }
  }
  
  const zipResult = yield call(generateZip, zip);
  
  const downloadUrl = `data:application/zip;base64,${zipResult}`;
  // Create a link element
  const link        = document.createElement('a');
  
  // Set link's href to point to the Blob URL
  link.href     = downloadUrl;
  link.download = `efficience-dmpcjs-logs-${moment().format('YYYY-MM-DD_HH-mm-ss')}.zip`;
  
  // Append link to the body
  document.body.appendChild(link);
  
  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new window.MouseEvent('click', {
      bubbles   : true,
      cancelable: true,
      view      : window,
    }),
  );
  
  // Remove link from body
  document.body.removeChild(link);
  
  if (resetLogLevel === true) {
    yield call(resetLogLevelsHandler);
  }
  
  yield put(gotLogs());
}

export const handleLogsCallbacks = function* () {
  yield takeEvery(dmpconnectApplicationActionConstants.GET_LOG_LEVELS, getLogsLevelHandler);
  yield takeEvery(dmpconnectApplicationActionConstants.RESET_LOG_LEVELS, resetLogLevelsHandler);
  yield takeEvery(dmpconnectApplicationActionConstants.SET_LOG_LEVELS, setLogLevelsHandler);
  yield takeEvery(dmpconnectApplicationActionConstants.GET_LOGS, getLogsHandler);
};
