import { DmpcJsLogLevel, DmpcLogLevels } from 'dmpconnectjsapp-base/constants';

export const areLogsDetailed = logLevelSection => !!logLevelSection.i_dmpConnectLogLevel && logLevelSection.i_dmpConnectLogLevel >= DmpcLogLevels.DEV
    && !!logLevelSection.i_dmpConnectJsLogLevel && logLevelSection.i_dmpConnectJsLogLevel >= DmpcJsLogLevel.DEBUG;

export const getSavedLogsLevel = (state) => {
  const {
    dmpConnectPersistedAppConfiguration: {
      dmpConnectLogLevel,
      dmpConnectJsLogLevel,
    },
  } = state;

  return {
    dmpConnectLogLevel,
    dmpConnectJsLogLevel,
  };
};
