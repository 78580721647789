import { isLoading } from 'dmpconnectjsapp-base/helpers/common';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import React from 'react';
import { clearSubSection } from 'dmpconnectjsapp-base/actions';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatGetMssHpInfosParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNosCodesFromState } from 'dmpconnectjsapp-base/helpers/accessors';
import { getAction } from '../../dmpconnect/actions';
import { existsInText } from '../../dmpconnect/helpers/searchUtils';
import { isEmailValid } from '../../dmpconnect/utils/dataUtils';

const AddressEmailAsyncTypeAhead = ({
  dispatch,
  selectedAddresses,
  onChange,
  getHpSection,
  subSection,
  isInvalid,
  multiple,
  labelKey,
  id,
  categoriesProfession,
}) => {
  const ref = React.useRef();
  const [emailError, setEmailError] = React.useState(false);
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (search) {
        dispatch(clearSubSection(apiSections.MSS_GET_HP_INFOS, subSection));
        const [name, given] = search.trim().split(' ');
        dispatch(getAction(
          commands.getMssHpInfos,
          apiSections.MSS_GET_HP_INFOS,
          formatGetMssHpInfosParams(
            `${name}`,
            given ? `${given}` : undefined,
          ),
          {
            subSection,
          },
        ));
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [search]);

  const searchRecipients = (query) => {
    if (query.trim().length >= 4) {
      if (!isEmailValid(query.trim())) {
        setSearch(query);
      }
    }
  };
  function checkEmailParts(queryParts) {
    setEmailError(false);

    queryParts.forEach((part) => {
      if (!!part && isEmailValid(part)) {
        ref.current.clear();
        ref.current.focus();
        onChange([...selectedAddresses, { s_name: part, s_mail: part }]);
      } else if (!!part && !isEmailValid(part)) {
        setEmailError(true);
      }
    });
  }

  const handleInputChange = (event) => {
    if ([' ', ','].includes(event.key)) {
      event.stopPropagation();
      const { value } = event.target;
      const queryParts = value.trim().split(event.key);
      checkEmailParts(queryParts);
    }
  };

  const onBlur = (event) => {
    const queryParts = event.target.value.split(/ |,/);
    checkEmailParts(queryParts);
  };

  const deDuplicateRecipients = () => {
    const recipients = getHpSection.PSList;
    if (!recipients) return [];

    return [...new Map(recipients.map(
      ({
        s_name, s_given, s_title, s_mail, s_codeProfession, s_city, s_idNat, s_codeCategorieProfession,
      }) => [s_mail, {
        s_name, s_given, s_title, s_mail, s_codeProfession, s_city, s_idNat, s_codeCategorieProfession,
      }],
    )).values()];
  };

  const filterBy = (option, filterProps) => {
    const { s_name = '', s_given = '', s_title = '' } = option;
    const { text = '' } = filterProps;
    const string = `${s_name} ${s_given} ${s_title}`;
    // return s_commonName.toLowerCase().indexOf(text.replace(/\*/g, '').trim().toLowerCase()) !== -1;
    return existsInText(string.toLowerCase(), text.replace(/\*/g, '').trim().toLowerCase());
  };

  const list = deDuplicateRecipients();

  return (
    <>
      <AsyncTypeahead
        useCache={false}
        inputProps={{ className: 'form-control-sm' }}
        className="custom-typeahead"
        filterBy={filterBy}
        ref={ref}
        id={id}
        isLoading={isLoading(getHpSection)}
        labelKey={option => `${option.s_name} ${option.s_given || ''} ${option.s_title ? `- ${option.s_title}` : ''}`}
        minLength={3}
        onSearch={searchRecipients}
        onKeyDown={event => handleInputChange(event)}
        onBlur={event => onBlur(event)}
        selected={selectedAddresses}
        options={list}
        placeholder="Ajouter un destinataire ..."
        searchText="Recherche en cours ..."
        emptyLabel="Aucun résultat"
        multiple={multiple}
        isInvalid={isInvalid || emailError}
        onChange={selection => onChange(selection)}
        renderMenuItemChildren={(option, { text }, index) => (
          <div className="d-flex flex-column mss-recipients">
            <div className="d-flex align-items-center mss-recipient-name">
              <span className={`mr-1 badge-profession badge-profession-${option.s_codeProfession}-1`} />
              {option.s_name}
              {' '}
              {option.s_given}
            </div>
            <div className="mss-recipient-email">
              <small className="text-gray-400">
                {option.s_title}
                {option.s_city ? ` à ${option.s_city}` : ''}
              </small>
            </div>
            <div className="mss-recipient-email">
              <small className="text-gray-400">
                {option.s_mail}
              </small>
            </div>
            {option.s_idNat && (
            <div className="d-flex align-items-center mss-recipient-idNat">
              <small className="text-gray-400">
                Id. Nat. :
                {' '}
                {option.s_idNat}
              </small>
            </div>
            )}
            {option.s_codeCategorieProfession && categoriesProfession && (
            <div className="d-flex align-items-center mss-recipient-idNat">
              <small className="text-gray-400">
                Fonction :
                {' '}
                {categoriesProfession[option.s_codeCategorieProfession] || option.s_codeCategorieProfession}
              </small>
            </div>
            )}
            {index < list.length && <hr />}
          </div>
        )}
      />
      {emailError && (
      <div className="invalid-feedback d-block">
        Une des adresses n&apos;est pas valide.
      </div>
      )}
    </>
  );
};

function mapStateToProps(state, props) {
  const {
    dmpconnect: {
      [apiSections.MSS_GET_HP_INFOS]: {
        [props.subSection]: getHpSection,
      } = {},
    },
  } = state;
  const nosCodes = getNosCodesFromState(state);
  const categoriesProfessions = nosCodes['tre_r37-typeprofessionfonction'];
  return {
    getHpSection,
    categoriesProfession: categoriesProfessions && categoriesProfessions.length > 0
      ? categoriesProfessions.reduce((result, current) => ({ ...result, [current.s_code]: current.s_displayName }), {})
      : undefined,
  };
}

AddressEmailAsyncTypeAhead.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedAddresses: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  getHpSection: PropTypes.object,
  subSection: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  multiple: PropTypes.bool,
  labelKey: PropTypes.string,
  id: PropTypes.string.isRequired,
  categoriesProfession: PropTypes.object,
};

AddressEmailAsyncTypeAhead.defaultProps = {
  selectedAddresses: [],
  getHpSection: {},
  isInvalid: false,
  multiple: true,
  labelKey: 's_name',
  categoriesProfession: undefined,
};

const ConnectedAddressEmailAsyncTypeAhead = connect(mapStateToProps)(AddressEmailAsyncTypeAhead);

export default ConnectedAddressEmailAsyncTypeAhead;
