import { objectWithoutTheseKeys } from './dataUtils';

export const indexedDBFromLocalStorage = (localStorageKey) => {
  const localStorageState = localStorage.getItem(localStorageKey);
  if (localStorageState) {
    const parsedState = JSON.parse(localStorageState);
    return Object.entries(objectWithoutTheseKeys(parsedState, ['_persist'])).reduce(
      (result, [key, value]) => ({ ...result, [key]: JSON.parse(value) }),
      {},
    );
  }
  return {};
};
