import React from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
} from 'react-table';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Pagination from './Pagination';
import TitleTooltip from '../TitleTooltip';
import CustomCheckbox from '../Form/Input/CustomCheckbox';

export const pagerPositions = {
  BOTTOM: 'bottom',
  HEADER: 'header',
};

export default function PaginatedTable({
  data,
  columns,
  initialSortBy,
  defaultPageSize,
  enablePageSizeChange,
  onSort,
  noHeader,
  rowProps,
  extraHeader,
  pagerPosition,
  useSelection,
  onSelectionChange,
  selectionColumnId,
  extraSubHeader,
  ...props
}) {
  const {
    getTableProps,
    getTableBodyProps,
    getToggleAllPageRowsSelectedProps,
    toggleAllRowsSelected,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex, pageSize, sortBy, selectedRowIds,
    },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetPage: false,
      initialState: {
        sortBy: initialSortBy,
        pageSize: defaultPageSize,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (useSelection) {
        hooks.visibleColumns.push(visibleColumns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            // eslint-disable-next-line react/prop-types
            Header: () => (
              <CustomCheckbox id="selection-header" {...getToggleAllPageRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            // eslint-disable-next-line react/prop-types
            Cell: ({ row }) => (
              <CustomCheckbox id={`selection-${row.id}`} {...row.getToggleRowSelectedProps()} />
            ),
            colProps: () => ({
              style: {
                width: '50px',
                minWdth: '50px',
                maxWdth: '50px',
              },
            }),
          },
          ...visibleColumns,
        ]);
      }
    },
  );

  React.useEffect(() => {
    onSort(sortBy);
  }, [sortBy]);

  React.useEffect(() => {
    if (onSelectionChange) {
      const ids = rows.filter(row => selectedRowIds[row.id] === true).map(row => row.original[selectionColumnId]);
      onSelectionChange(ids);
    }
  }, [selectedRowIds]);

  React.useEffect(() => {
    toggleAllRowsSelected(false);
  }, [pageIndex]);

  React.useEffect(() => {
    if ((rows || []).length < pageIndex * pageSize) {
      gotoPage(0);
    }
  }, [rows, pageIndex, pageSize]);

  // if (!data || data.length === 0) {
  //   return null;
  // }

  // Render the UI for your table
  return (
    <>
      {(
        extraHeader
        || pagerPosition === pagerPositions.HEADER
        || (noHeader && useSelection)
      ) && (
        <>
          <div className="table-extra-header">
            <div className="table-extra-header-selection">
              <CustomCheckbox id="selection-header" {...getToggleAllPageRowsSelectedProps()} />
            </div>
            <div className="table-extra-header-content">
              {extraHeader}
            </div>

            <div className="table-extra-header-pager">
              {pagerPosition === pagerPositions.HEADER && (
              <>
                <span>
                  {(pageIndex * pageSize) + 1}
                  {' - '}
                  {(pageIndex * pageSize) + pageSize <= rows.length ? (pageIndex * pageSize) + pageSize : rows.length}
                  {' sur '}
                  {rows.length}
                </span>
                <TitleTooltip id="table-pager-prev" text="Page précédente" placement="bottom">
                  <Button
                    variant="borderless-secondary"
                    size="sm"
                    className="btn-icon-only"
                    onClick={() => { if (canPreviousPage) previousPage(); }}
                  >
                    <i className="ic-chevron-left" />
                  </Button>
                </TitleTooltip>

                <TitleTooltip id="table-pager-prev" text="Page suivante" placement="bottom">
                  <Button
                    variant="borderless-secondary"
                    size="sm"
                    className="btn-icon-only"
                    onClick={() => { if (canNextPage) nextPage(); }}
                  >
                    <i className="ic-chevron-right" />
                  </Button>
                </TitleTooltip>
              </>
              )}
            </div>
          </div>
          <div className="table-extra-sub-header">
            {extraSubHeader}
          </div>
        </>
      )}
      <BootstrapTable
        {...props}
        {...getTableProps()}
      >
        {!noHeader && (
        <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={`th-${column.id}`}
                    className={`th-${column.id} ${column.isSorted ? 'sorted' : ''} typography-default-text-title`}
                    style={{
                      verticalAlign: 'top',
                      width: column.fixedWidth,
                      minWdth: column.fixedWidth,
                      maxWdth: column.fixedWidth,
                    }}
                  >
                    <div {...column.getHeaderProps(column.getSortByToggleProps({ title: 'Trier' }))} className="d-flex align-items-center">
                      {column.render('Header')}
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {column.isSorted ? (column.isSortedDesc
                        ? <i className="ic-arrow-down" />
                        : <i className="ic-arrow-up" />) : ''}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
        </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(rowProps(row))}>
                {row.cells.map(cell => (
                  <td
                    className={`td-${cell.column.id}`}
                    {...cell.getCellProps(cell.column.colProps ? cell.column.colProps(row) : undefined)}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
      {pagerPosition === pagerPositions.BOTTOM && (
      <Pagination
        previousPage={previousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        pageSize={pageSize}
        pageCount={pageCount}
        enablePageSizeChange={enablePageSizeChange}
      />
      )}
    </>
  );
}

PaginatedTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  initialSortBy: PropTypes.array,
  defaultPageSize: PropTypes.number,
  enablePageSizeChange: PropTypes.bool,
  onSort: PropTypes.func,
  noHeader: PropTypes.bool,
  rowProps: PropTypes.func,
  extraHeader: PropTypes.node,
  extraSubHeader: PropTypes.node,
  pagerPosition: PropTypes.string,
  useSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  selectionColumnId: PropTypes.func,
};

PaginatedTable.defaultProps = {
  initialSortBy: [],
  defaultPageSize: 10,
  enablePageSizeChange: true,
  onSort: () => {},
  noHeader: false,
  rowProps: () => ({}),
  extraHeader: null,
  extraSubHeader: null,
  pagerPosition: pagerPositions.BOTTOM,
  useSelection: false,
  onSelectionChange: null,
  selectionColumnId: null,
};
