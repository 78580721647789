import { apiSections, dmpconnectActionConstants } from 'dmpconnectjsapp-base/constants';
import { getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import { getEsUser, getHealthcareSetting } from 'dmpconnectjsapp-base/helpers/accessors';
import { js2xml, xml2js } from 'xml-js';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import rename from 'deep-rename-keys';
import { toast } from 'react-toastify';
import {
  dmpconnectRemoteActionConstants,
} from '../constants';
import { getSessionId } from '../helpers';
import { formatUploadDocumentParams } from '../helpers/sendDocument';
import {
  convertJsResultToXmlReadyObject,
  parseXmlToJsResult,
  remoteFormats,
  remoteResponseChannels,
} from '../helpers/remote';
import { getAction } from './dmpconnectActions';
import {
  getRemoteExchangeFormat,
  getRemoteExportChannel,
  getRemoteExportChannelUrl,
  isRemoteControlActive,
} from '../sagas/remoteSagas';
import { postMessageToIframeParent } from '../utils/iframe';
import { b64EncodeUnicode } from '../utils/dataUtils';

export const receivedRemoteRequest = (request, source) => {
  const result = xml2js(request, { compact: true });

  const { _declaration, ...xml } = result;

  let jsRequest = parseXmlToJsResult(xml);
  const { request: { '@responseChannel': responseChannel, ...requestRest } } = jsRequest;

  if (!responseChannel) {
    jsRequest = { request: { ...requestRest, '@responseChannel': remoteResponseChannels.IFRAME } };
  }

  return ({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_RECEIVED_REQUEST,
    data: {
      message: {
        RemoteCommands: [jsRequest],
        s_status: 'OK',
      },
    },
    source,
  });
};

export const receivedJSONRemoteRequest = (request, source) => {
  const { request: { responseChannel, ...requestRest } } = request;
  let jsRequest = request;
  if (!responseChannel) {
    jsRequest = { request: { ...requestRest, responseChannel: remoteResponseChannels.IFRAME, type: 'JSON' } };
  }

  return ({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_RECEIVED_REQUEST,
    data: {
      message: {
        RemoteCommands: [jsRequest],
        s_status: 'OK',
      },
    },
    source,
  });
};

export const remoteExportEmail = (email, mssApiType) => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_EXPORT_EMAIL,
  email,
  mssApiType,
});
export const remoteExportEmailDone = email => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_EXPORT_EMAIL_DONE,
  email,
});

export const sendRemoteOutMessage = message => (dispatch, getState) => {
  const remoteControlActive = isRemoteControlActive(getState());
  const remoteExportChannel = getRemoteExportChannel(getState());
  const remoteExportChannelUrl = getRemoteExportChannelUrl(getState());
  const remoteFormat = getRemoteExchangeFormat(getState());

  const remoteOutActive = !!remoteControlActive && remoteExportChannel === remoteResponseChannels.CONNECTOR;
  if (remoteOutActive) {
    dispatch({
      type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
      command: {
        s_commandName: 'hl_sendRemoteOutMessage',
        s_sessionId: getSessionId(getState()),
        Message: message,
      },
      context: {
        section: 'sendRemoteOutMessage',
      },
    });
  } else {
    let xmlResponse;
    if (remoteFormat === remoteFormats.JSON) {
      xmlResponse = JSON.stringify({ ...rename(message, key => key.replace(/^@/, '')) }, null, 4);
    } else {
      const xmlReady = convertJsResultToXmlReadyObject(message);
      const xml = js2xml(xmlReady, { compact: true, spaces: 4 });
      xmlResponse = `<?xml version="1.0" encoding="utf-8"?>\n${xml}`;
    }

    if (remoteExportChannel.toLowerCase() === remoteResponseChannels.URL.toLowerCase()) {
      console.log('outgoingMessage_post', xmlResponse);
      fetch(remoteExportChannelUrl, {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/xml',
        },
        body: xmlResponse,
      })
        .then((res) => {
          if (!res.ok) {
            const erreur = (
              // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
              <>
                {' HTTP '}
                {res.status}
                {' '}
                -
                {' '}
                {res.statusText}
              </>
            );
            const errorMessage = 'Une erreur s\'est produite pendant l\'export';
            res.json()
              .then((json) => {
                toast((
                  // eslint-disable-next-line react/react-in-jsx-scope
                  <>
                    {/* eslint-disable-next-line react/react-in-jsx-scope */}
                    <div>
                      {json.message || errorMessage}
                      {/* eslint-disable-next-line react/react-in-jsx-scope */}
                      <br />
                      {json.erreur || erreur}
                    </div>
                  </>
                ),
                {
                  type: 'error',
                  position: 'top-right',
                  closeOnClick: true,
                  autoClose: 10000,
                  hideProgressBar: true,
                  draggable: false,
                });
              })
              .catch(() => {
                toast((
                  // eslint-disable-next-line react/react-in-jsx-scope
                  <>
                    {/* eslint-disable-next-line react/react-in-jsx-scope */}
                    <div>
                      {message}
                      {/* eslint-disable-next-line react/react-in-jsx-scope */}
                      <br />
                      {erreur}
                    </div>
                  </>
                ),
                {
                  type: 'error',
                  position: 'top-right',
                  closeOnClick: true,
                  autoClose: 10000,
                  hideProgressBar: true,
                  draggable: false,
                });
              });
          }
        })
        .catch((e) => {
          toast('Erreur de connection pendant l\'export',
            {
              type: 'error',
              position: 'top-right',
              closeOnClick: true,
              autoClose: 10000,
              hideProgressBar: true,
              draggable: false,
            });
        });
    } else if (remoteExportChannel.toLowerCase() === remoteResponseChannels.IFRAME.toLowerCase() || remoteFormat === remoteFormats.JSON) {
      postMessageToIframeParent({ type: 'remoteNotification', data: b64EncodeUnicode(xmlResponse) });
    }
  }
};
export const remoteSendLoginSuccessNotification = () => (
  (dispatch, getState) => dispatch({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_notifyPsConnectedToRemoteOut',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.REMOTE_LOGIN_NOTIFICATION,
    },
  }));

export const remoteSendlogoutSuccessNotification = () => (
  (dispatch, getState) => dispatch({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_notifyPsLoggedOutToRemoteOut',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.REMOTE_LOGIN_NOTIFICATION,
      silentError: true,
    },
  }));


export const startRemoteMonitoring = () => (dispatch, getState) => (
  dispatch({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_START,
    command: {
      s_commandName: 'hl_startRemoteControlInMonitoring',
      s_sessionId: getSessionId(getState()),
    },
  })
);
export const stopRemoteMonitoring = () => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_STOP,
});

export const checkRemoteControlStatus = () => (dispatch, getState) => (
  dispatch({
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'hl_remoteControlStatus',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.REMOTE_CONTROL_STATUS,
    },
    silentError: true,
  })
);
export const startRemoteControlProcess = (checkStatus = false) => ({
  type: dmpconnectRemoteActionConstants.START_REMOTE_CONTROL_PROCESS,
  checkStatus,
});
export const startRemoteControl = (checkStatus = false) => (dispatch, getState) => (
  dispatch({
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'hl_startRemoteControl',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.START_REMOTE_CONTROL,
      checkStatus,
    },
  })
);
export const stopRemoteControlProcess = (checkStatus = false) => ({
  type: dmpconnectRemoteActionConstants.STOP_REMOTE_CONTROL_PROCESS,
  checkStatus,
});
export const stopRemoteControl = (checkStatus = false) => (dispatch, getState) => {
  dispatch({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_MONITORING_STOP,
  });
  dispatch({
    type: dmpconnectActionConstants.DMPC_SEND_COMMAND,
    command: {
      s_commandName: 'hl_stopRemoteControl',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.STOP_REMOTE_CONTROL,
      checkStatus,
    },
    silentError: true,
  });
};


export const sendRemoteResponse = answer => (dispatch, getState) => {
  const { '@RelatesTo': id } = answer;
  dispatch({
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_sendRemoteInAnswer',
      s_sessionId: getSessionId(getState()),
      answer,
    },
    context: {
      section: apiSections.REMOTE_CONTROL_REQUEST_RESPONSE_SECTION,
      subSection: id,
    },
  });
};

export const clearRemoteSection = section => ({
  type: dmpconnectRemoteActionConstants.DMPC_CLEAR_REMOTE_SECTION,
  section,
});

export const remoteFindPatients = params => (dispatch => dispatch(
  getAction(
    commands.findPatient,
    apiSections.REMOTE_FIND_PATIENTS_SECTION,
    { ...params },
    { remote: true },
  ),
));

export const remoteGetDirectAuthenticationDMPStatus = ins => (dispatch, getState) => {
  const { accessRights: { psId } } = getAccessRightsProps(getState());

  return dispatch(getAction(
    commands.getDirectAuthenticationDMPStatus,
    apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION,
    { s_ins: ins },
    {
      subSection: `${ins}/${psId}`,
      contextParams: { ins, performer: psId },
      remote: true,
    },
  ));

  // return dispatch(
  //   {
  //     type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
  //     command: {
  //       s_commandName: 'hl_getDirectAuthenticationDMPStatus',
  //       s_ins: ins,
  //       s_sessionId: getSessionId(getState()),
  //     },
  //     context: {
  //       section: apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION,
  //       subSection: `${ins}/${psId}`,
  //       params: { ins, performer: psId },
  //     },
  //   },
  // );
};

// export const remoteExportDocument = (uniqueId, ins) => ((dispatch, getState) => dispatch(
//   {
//     type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
//     command: {
//       s_commandName: 'hl_exportDocumentToRemoteOutByUniqueId',
//       s_sessionId: getSessionId(getState()),
//       s_documentUniqueId: uniqueId,
//       s_ins: ins,
//     },
//     context: {
//       section: apiSections.REMOTE_EXPORT_DOCUMENT_SECTION,
//       subSection: uniqueId,
//     },
//   },
// ));

export const remoteExportDocument = (uniqueUUid, ins) => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_EXPORT_DOCUMENT,
  uniqueUUid,
  ins,
});

export const remoteGetOpenDMPConsent = (params, ins) => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_OPEN_DMP_CONSENT,
  params,
  ins,
});

export const remoteAnswerOpenDMPConsent = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_OPEN_DMP_CONSENT,
  consent,
}));

export const remoteGetCreateDMPConsent = (params, ins) => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_CREATE_DMP_CONSENT,
  params,
  ins,
});

export const remoteAnswerCreateDMPConsent = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_CREATE_DMP_CONSENT,
  consent,
}));

export const remoteCreateDMPShowForm = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_CREATE_DMP_SHOW_FORM,
}));
export const remoteCreateDMPHideForm = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_CREATE_DMP_HIDE_FORM,
}));

export const remoteGetReactivateDMPConsent = (params, ins) => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_REACTIVATE_DMP_CONSENT,
  params,
  ins,
});

export const remoteAnswerReactivateDMPConsent = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_REACTIVATE_DMP_CONSENT,
  consent,
}));

export const remoteReactivateDMPShowForm = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_REACTIVATE_DMP_SHOW_FORM,
}));
export const remoteReactivateDMPHideForm = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_REACTIVATE_DMP_HIDE_FORM,
}));

export const remoteCreateDMP = (params, ins) => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_createDmpAndOtp',
      s_ins: ins,
      ...params,
      s_sessionId: getSessionId(getState()),
    },
    context: {
      params,
      section: apiSections.REMOTE_CREATE_DMP,
    },
  },
));

export const remoteReactivateDMP = (params, ins) => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_reactivateDmp',
      s_ins: ins,
      ...params,
      s_sessionId: getSessionId(getState()),
    },
    context: {
      params,
      section: apiSections.REMOTE_REACTIVATE_DMP,
    },
  },
));

export const remoteGetAcknowlegmentPdf = (ins, CivilStatus) => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_createAcknowledgementPdf',
      CivilStatus,
      s_ins: ins,
      i_returnAsFile: 0,
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.REMOTE_GET_PATIENT_ACK_PDF,
    },
  },
));
export const remoteGetPatientWebPdf = (ins, mobilePhone, mail) => ((dispatch, getState) => dispatch(
  {
    type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
    command: {
      s_commandName: 'hl_getPatientWebAccessPdf',
      s_otpPhone: mobilePhone,
      s_otpEmail: mail,
      s_ins: ins,
      i_returnAsFile: 0,
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.REMOTE_GET_PATIENT_WEB_PDF,
    },
  },
));


// TODO : check ES-REST mode pilotable
// export const remoteSendDocument = params => (
//   (dispatch, getState) => dispatch(
//     {
//       type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
//       command: {
//         s_commandName: 'hl_sendDocument',
//         ...params,
//         s_sessionId: getSessionId(getState()),
//         timeout: 600,
//       },
//       context: {
//         section: apiSections.REMOTE_SEND_DOCUMENT,
//       },
//     },
//   )
// );
export const remoteSendDocument = params => (
  (dispatch, getState) => {
    const {
      dmpconnectApplication: {
        submitEngine,
      },
    } = getState();

    return dispatch(getAction(
      commands.sendDocument,
      apiSections.REMOTE_SEND_DOCUMENT,
      { ...params, esUser: getEsUser(getState()), submitEngine: params.submitEngine || submitEngine },
      { remote: true },
    ));
  }
);

// export const remoteDeleteDocument = params => (
//   (dispatch, getState) => dispatch(
//     {
//       type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
//       command: {
//         s_commandName: 'hl_deleteDocument',
//         ...params,
//         s_sessionId: getSessionId(getState()),
//       },
//       context: {
//         section: apiSections.REMOTE_DELETE_DOCUMENT,
//       },
//     },
//   )
// );
export const remoteDeleteDocument = params => (
  (dispatch, getState) => {
    const {
      dmpconnectApplication: {
        submitEngine,
      },
    } = getState();

    return dispatch(getAction(
      commands.deleteDocument,
      apiSections.REMOTE_DELETE_DOCUMENT,
      { ...params, submitEngine: params.submitEngine || submitEngine },
      { remote: true },
    ));
  }
);

// export const remoteGetCertifiedIdentity = ({
//   nirOD,
//   birthDate,
//   birthRank,
// }) => (
//   (dispatch, getState) => dispatch({
//     type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
//     command: {
//       s_commandName: 'hl_getCertifiedIdentity',
//       s_nirOD: nirOD,
//       s_birthDate: birthDate,
//       i_birthRank: birthRank,
//       s_sessionId: getSessionId(getState()),
//     },
//     context: {
//       section: apiSections.REMOTE_CERTIFIFIED_IDENTITY_SECTION,
//     },
//   }));

export const remoteGetCertifiedIdentity = ({
  nirOD,
  birthDate,
  birthRank,
}) => (
  dispatch => dispatch(getAction(
    commands.getCertifiedIdentity,
    apiSections.REMOTE_CERTIFIFIED_IDENTITY_SECTION,
    {
      s_nirOD: nirOD,
      s_birthDate: birthDate,
      i_birthRank: birthRank,
    },
  ))
);

export const remoteGetCloseDMPConsent = () => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_CLOSE_DMP_CONSENT,
});

export const remoteAnswerCloseDMPConsent = consent => (dispatch => dispatch({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_CLOSE_DMP_CONSENT,
  consent,
}));

export const remoteValidateCDADocuments = (requestId, document) => (dispatch, getState) => {
  const healthcareSetting = getHealthcareSetting(getState());

  const formatedValues = formatUploadDocumentParams(document);

  dispatch(getAction(
    commands.validateDocuments,
    apiSections.REMOTE_VALIDATE_DOCUMENT,
    {
      ins: document.ins,

      base64Content: formatedValues.data,
      stylesheetBase64: document.stylesheetBase64,
      title: formatedValues.title,
      description: formatedValues.description,
      category: formatedValues.typeCode,
      visibility: formatedValues.documentVisibility,
      format: formatedValues.format,
      healthcareSetting: formatedValues.healthcareSetting || healthcareSetting,
      creationDate: formatedValues.creationDate,
      serviceStartDate: formatedValues.serviceStartDate,
      serviceStopDate: formatedValues.serviceStopDate,
      replacedDocumentId: formatedValues.replaceDocumentId,
      retrieveDocumentUuid: document.retrieveDocumentUuid,
      setIdRoot: formatedValues.setIdRoot,
      setIdExtension: formatedValues.setIdExtension,
      versionNumber: formatedValues.versionNumber,

      eventCodes: document.eventCodes,
      Informants: document.informants,
      Performer: document.performer,
      TreatingPhycisian: document.treatingPhysician,
      AdditionalAuthors: document.additionalAuthors,
      IntendedRecipients: document.intendedRecipients,

      uniqueId: document.uniqueId,
      Identity: document.Identity,

      transcodeTypeCode: 1,
      getCdaHeaders: 1,
      getContent: 1,
      getCdaContent: 1,
      esUser: getEsUser(getState()),
      AdditionalPatientIdentifiers: document.AdditionalPatientIdentifiers,
    },
    {
      subSection: requestId,
      silentError: true,
      remote: true,
      forceEs: true,
    },
  ));
  // dispatch(
  //   {
  //     type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND,
  //     command: {
  //       s_commandName: 'hl_validateDocuments',
  //       s_sessionId: getSessionId(getState()),
  //       Documents: [{
  //         s_title: formatedValues.title,
  //         s_description: formatedValues.description,
  //         s_category: formatedValues.typeCode,
  //         i_visibility: formatedValues.documentVisibility,
  //         i_format: formatedValues.format,
  //         s_contentInBase64: formatedValues.data,
  //         EventCodes: (document.eventCodes || []).map(eventCode => ({
  //           s_code: eventCode.code,
  //           s_classification: eventCode.classification,
  //           s_description: eventCode.description,
  //         })),
  //         TreatingPhysician: document.treatingPhysician,
  //         Performer: document.performer,
  //         AdditionalAuthors: document.additionalAuthors,
  //         Informants: document.informants,
  //         s_versionNumber: formatedValues.versionNumber,
  //         s_setIdRoot: formatedValues.setIdRoot,
  //         s_setIdExtension: formatedValues.setIdExtension,
  //       }],
  //       s_ins: document.ins,
  //       s_healthcareSetting: healthcareSetting,
  //       s_submissionSetTitle: formatedValues.title,
  //       i_transcodeTypeCode: 1,
  //       i_getCdaHeaders: 1,
  //       i_getContent: 1,
  //       // i_getCdaContent: 1,
  //       timeout: 600,
  //     },
  //     context: {
  //       section: apiSections.REMOTE_VALIDATE_DOCUMENT,
  //       subSection: requestId,
  //     },
  //     silentError: true,
  //   },
  // );
};

export const remoteSubmitDoc = (requestId, document) => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SUBMIT_DOC,
  requestId,
  document,
});

export const setRemoteDocIns = ins => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SUBMIT_DOC_INS,
  ins,
});

export const remoteSubmitDocRefuse = () => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_SUBMIT_DOC_REFUSE,
});

export const remoteSendMssMessageRefused = () => ({
  type: dmpconnectRemoteActionConstants.DMPC_REMOTE_MSS_SEND_MESSAGE_REFUSED,
});
