import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

function SwitchCheckbox({
  checked, onLabel, offLabel, onChange, id, name, showLabel, disabled, ...props
}) {
  return (
    <>
      <Form.Check
        id={id}
        type="checkbox"
        className="pl-0 d-flex align-items-center"
        {...props}
      >
        <Form.Check.Input
          type="checkbox"
          className="tgl tgl-success"
          name={name}
          checked={!!checked}
          onChange={event => onChange(event)}
          disabled={disabled}
        />
        <Form.Check.Label className="tgl-btn" />
        {showLabel && (
          <span
            className="tgl-label"
            onClick={() => onChange({ target: { type: 'checkbox', checked: !checked, name } })}
          >
            {checked ? onLabel : offLabel}
          </span>
        )}
      </Form.Check>

    </>
  );
}

SwitchCheckbox.defaultProps = {
  onChange: null,
  onLabel: 'Oui',
  offLabel: 'Non',
  showLabel: false,
  disabled: false,
};

SwitchCheckbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SwitchCheckbox;
