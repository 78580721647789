import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoading } from 'dmpconnectjsapp-base/helpers/common';
import DMPSelector from '../DMPSearch/DMPSelector';
import {
  remoteGetDirectAuthenticationDMPStatus,
  setRemoteDocIns,
} from '../../dmpconnect/actions/dmpconnectRemoteActions';
import { isInsValid } from '../../dmpconnect/helpers';

class RemoteDMPSelector extends Component {
  selectINS(ins) {
    const { dispatch } = this.props;
    if (ins && isInsValid(ins)) {
      dispatch(remoteGetDirectAuthenticationDMPStatus(ins));
    }
    dispatch(setRemoteDocIns(ins));
  }

  render() {
    const {
      ins,
      directAuthenticationDMPStatus,
      panel, setPanel,
    } = this.props;

    const {
      ExistingTestAnswer: {
        AdminData: {
          s_birthday: birthday = '',
          s_patientGivenName: givenName = '',
          s_patientFirstBirthGiven: firstGivenName = '',
          s_patientName: name = '',
          s_patientBirthName: birthName = '',
        } = {},
      } = {},
    } = directAuthenticationDMPStatus;

    const loading = isLoading(directAuthenticationDMPStatus);
    return (
      <DMPSelector
        ins={ins}
        loading={loading}
        patientInfos={{
          name,
          givenName: firstGivenName || givenName || '',
          birthday,
          birthName,
        }}
        onSelect={selectedIns => this.selectINS(selectedIns)}
        panel={panel}
        setPanel={setPanel}
      />
    );
  }
}

RemoteDMPSelector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ins: PropTypes.string.isRequired,
  directAuthenticationDMPStatus: PropTypes.object.isRequired,
  panel: PropTypes.string.isRequired,
  setPanel: PropTypes.func.isRequired,
};

const ConnectedRemoteDMPSelector = connect()(RemoteDMPSelector);

export default ConnectedRemoteDMPSelector;
