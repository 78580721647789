import PropTypes            from 'prop-types';
import React                from 'react';
import { getKosSummary }    from '../../../dmpconnect/constants/ohif';
import { b64DecodeUnicode } from '../../../dmpconnect/utils/dataUtils';

const KosDocument = ({ dicomKos, ohifConfig, content }) => {
  const
    {
      GeneralStudy     : {
        s_description: studyName = '',
      } = {},
      SrDocumentContent: {
        s_textValue: kosSummary = ''
      } = {},
    } = dicomKos;
  
  let summary;
  if (!kosSummary) {
    summary = getKosSummary(dicomKos);
  } else {
    summary = kosSummary;
  }
  
  return <>
    {(summary || studyName) && (
      <>
        <div className="documentPage txt mb-3">
          {summary || studyName}
        </div>
      </>
    )}
    
    <div className="documentPage txt">{b64DecodeUnicode(content)}</div>
  </>;
};

KosDocument.propTypes = {
  dicomKos  : PropTypes.object.isRequired,
  ohifConfig: PropTypes.object.isRequired,
  content   : PropTypes.string.isRequired,
};

export default KosDocument;
