import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { extractDate, formatDate } from 'dmpconnectjsapp-base/helpers/accessors';
import VitaleCardDateNotice from './VitaleCardDateNotice';

class VitaleCardDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedDate: '',
      isValid: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { formattedVitaleCardDateYYYYMMDD } = props;
    const dateVitaleCard = extractDate(formattedVitaleCardDateYYYYMMDD, 'YYYYMMDD');
    const formattedDate = formatDate(dateVitaleCard, 'DD/MM/YYYY');

    return { isValid: dateVitaleCard.isValid(), formattedDate };
  }

  render() {
    const { formattedDate, isValid } = this.state;
    const { vitaleCardDate, formattedVitaleCardDateYYYYMMDD, className } = this.props;
    return (
      <React.Fragment>
        {isValid ? (
          <span className={className}>
            {formattedDate}
          </span>
        ) : (
          <span>
            {vitaleCardDate}
          </span>
        )}
        <VitaleCardDateNotice
          vitaleCardDate={vitaleCardDate}
          formattedVitaleCardDateYYYYMMDD={formattedVitaleCardDateYYYYMMDD}
        />
      </React.Fragment>
    );
  }
}

VitaleCardDate.propTypes = {
  vitaleCardDate: PropTypes.string.isRequired,
  formattedVitaleCardDateYYYYMMDD: PropTypes.string.isRequired,
  className: PropTypes.string,
};
VitaleCardDate.defaultProps = {
  className: '',
};
export default VitaleCardDate;
