import Dropzone from 'react-dropzone';
import React from 'react';
import PropTypes from 'prop-types';

const InputFileWithTokens = ({
  id, onDrop, onDelete, files, fileNameTransfo, disabled,
}) => (
  <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} disabled={disabled}>
    {({ getRootProps, getInputProps }) => (
      <div
        {...getRootProps()}
        className="rbt-input-multi form-control rbt-input custom-input-file"
        id={id}
      >
        <div className="rbt-input-wrapper">
          <input {...getInputProps()} />
          {files.map((file, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="rbt-token rbt-token-removeable" key={`files_${id}_${index}`}>
              {fileNameTransfo ? fileNameTransfo(file.documentTitle) : file.documentTitle}
              <button
                tabIndex="-1"
                aria-label="Remove"
                className="close rbt-close rbt-token-remove-button"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onDelete(file, index);
                }}
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Remove</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    )}
  </Dropzone>
);

InputFileWithTokens.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  files: PropTypes.array,
  id: PropTypes.string.isRequired,
  fileNameTransfo: PropTypes.func,
  disabled: PropTypes.bool,
};

InputFileWithTokens.defaultProps = {
  files: [],
  fileNameTransfo: null,
  disabled: false,
};

export default InputFileWithTokens;
