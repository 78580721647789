import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';
import { groupBy } from '../../../dmpconnect/helpers/jsUtils';
import CustomTypeahead from '../../Common/Form/Input/CustomTypeahead';

const renderGroupMenu = (results, menuProps, state) => {
  let index = 0;
  const groups = groupBy(results, 'group');
  const items = Object.keys(groups).map(group => (
    <Fragment key={`categoryInputGroup${group.replace(/\s/g, '')}`}>
      {index !== 0 && <Menu.Divider />}
      <Menu.Header>{group}</Menu.Header>
      {groups[group].map((i) => {
        const item = (
          <MenuItem key={index} option={i} position={index}>
            <Highlighter search={state.text}>
              {i.label}
            </Highlighter>
          </MenuItem>
        );

        index += 1;
        return item;
      })}
    </Fragment>
  ));

  return <Menu {...menuProps}>{items}</Menu>;
};

const CategoryInput = ({
  groupedCategories, onChange, value, error, name, isInvalid, placeholder,
}) => (
  <>
    <CustomTypeahead
      className={`custom-typeahead ${isInvalid ? 'is-invalid' : ''}`}
      name={name}
      id={name}
      options={
        groupedCategories.flatMap(meta => meta.types
          .filter(type => type.valid === true)
          .filter(type => type.label !== 'Note de vaccination')
          .map(cat => ({ ...cat, group: meta.label })))
      }
      selectionRule={item => item.code === value}
      labelKey="label"
      valueKey="code"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      isInvalid={isInvalid || !groupedCategories.flatMap(meta => meta.types)
        .find(item => item.code === value)}
      renderMenu={renderGroupMenu}
    />
    <Form.Control.Feedback type="invalid">
      {error}
    </Form.Control.Feedback>
  </>
);

CategoryInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  groupedCategories: PropTypes.array.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  placeholder: PropTypes.string,
};

CategoryInput.defaultProps = {
  value: null,
  error: null,
  isInvalid: false,
  placeholder: 'Sélectionner une catégorie ...',
};

export default CategoryInput;
