import merge from 'lodash.merge';
import moment from 'moment';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';
import {
  dmpconnectAccessibleDmpListActionsConstants,
  dmpStatuses,
  userAuthorizationStatuses,
} from '../constants';
import env from '../../envVariables';
import { insTypes } from '../constants/dmpConstants';
import {
  indexedDBFromLocalStorage,
} from '../utils/reduxStorage';

const initialState = indexedDBFromLocalStorage('persist:efficience_td04');

function completeIns(ins) {
  if (ins.length === 15) { // le suffix est manquant
    if (Number(env.REACT_APP_PRODUCTON_MODE) !== 1) {
      return `${ins}${insTypes.TEST_INS}`;
    }
    return `${ins}${insTypes.REAL_INS}`;
  }
  return ins;
}

export function deduplicatePatientsList(array) {
  if (Array.isArray(array)) {
    const mapped = array.reduce(
      (map, dmp) => {
        const ins = completeIns(dmp.s_ins);
        return map.set(
          ins,
          {
            ...map.get(ins),
            ...dmp,
            s_ins: ins, // forcer le nouvel ins, pour ne pas reprendre celui sans le suffix
          },
        );
      },
      new Map(), // valeur initiale
    );

    return [...mapped.values()];
  }

  return array;
}

function cacheAccessibleDMPList(state, action, mergeResults = true) {
  const { AccessibleDmps = [] } = action.data;
  const { subSection } = action.context;

  const {
    [subSection]: {
      AccessibleDmps: cachedDmps = [],
    } = {},
    ...restCache
  } = state;

  const dmps = AccessibleDmps.map(dmp => ({
    ...dmp,
    s_patientName: !['NON RENSEIGNE', 'NON_RENSEIGNE'].includes(dmp.s_patientName) ? dmp.s_patientName : '',
  }));

  // merger les résultats
  // on concatène les 2 tableaux
  // on déboublonne en créant une Map : si l'objet existe déjà, on assigne le nouveau à l'existant (object assign map.get)
  // on retourne les valeurs uniques Map.values()

  const merged = deduplicatePatientsList(mergeResults === true ? cachedDmps.concat(dmps) : dmps);

  return {
    [subSection]: {
      AccessibleDmps: merged,
      timestamp: moment().format('DD/MM/YYYY HH:mm:ss'),
      // lastResultIds: saveResultIds ? AccessibleDmps.map(dmp => dmp.s_ins) : lastResultIds,
    },
    ...restCache,
  };
}
function resetList(state, action) {
  const {
    [action.psId]: cache,
    ...restCache
  } = state;

  return {
    ...restCache,
  };
}
function importList(state, action) {
  return cacheAccessibleDMPList(state, {
    data: action.list,
    context: {
      subSection: action.psId,
    },
  }, false);
}

function updateCacheAccessibleDMPListFromDirectAuth(state, action) {
  const {
    data: {
      ExistingTestAnswer: {
        i_dmpStatus: dmpStatus,
        i_ensRelatedDmp: ensRelated,
        i_userAuthorization: authorization,
        AdminData: {
          s_birthday,
          s_insC,
          s_patientGivenName,
          s_patientFirstBirthGiven,
          s_patientName,
          s_patientBirthName,
          // Ins: {
          //   s_ins,
          //   s_insType,
          // } = {},
        },
      } = {},
      i_treatingPhysician: isTreatingPhysician,
    },
    context: { params: { ins, performer } },
  } = action;


  // if (ins !== `${s_ins}${s_insType}`) return state;

  const {
    [performer]: {
      AccessibleDmps: dmpList = [],
      timestamp,
    } = {},
    ...cache
  } = state;

  const now = moment().format('YYYYMMDDHHmmss');

  const dmpIndex = dmpList.findIndex(dmp => dmp.s_ins === ins);

  // le dmp n'est pas ouvert, on le retire du cache
  if (
    [dmpStatuses.DMPIsClosed, dmpStatuses.DMPNotFound].includes(dmpStatus)
    || (
      dmpStatus === dmpStatuses.DMPExist
      && ![
        userAuthorizationStatuses.AuthorizationExist,
        userAuthorizationStatuses.AuthorizationExpired,
      ].includes(authorization)
    )
  ) {
    if (dmpIndex === -1) return state;
    dmpList.splice(dmpIndex, 1);
  } else if (
    dmpStatus === dmpStatuses.DMPExist
    && [
      userAuthorizationStatuses.AuthorizationExist,
      userAuthorizationStatuses.AuthorizationExpired,
    ].includes(authorization)
  ) {
    const dmp = {
      s_ins: ins,
      isTreatingPhysician,
      s_birthday,
      s_insC,
      s_patientGivenName,
      s_patientFirstBirthGiven,
      s_patientBirthName,
      s_patientName: !['NON RENSEIGNE', 'NON_RENSEIGNE'].includes(s_patientName) ? s_patientName : '',
      ensRelated,
    };

    if (dmpIndex === -1) {
      dmp.s_lastAccessDate = now;
      dmp.s_lastAddDate = now;
      dmp.s_lastUpdateDate = now;
      // dmp.s_patientBirthName = '';
      dmpList.push(dmp);
    } else {
      dmpList[dmpIndex] = { ...dmpList[dmpIndex], ...dmp };
    }
  }

  return {
    [performer]: {
      AccessibleDmps: deduplicatePatientsList(dmpList),
      timestamp,
      // previousTimeStamp,
    },
    ...cache,
  };
}

const updateCacheLastAccessDate = (state, action) => {
  const { ins, performer } = action;

  const {
    [performer]: {
      AccessibleDmps: dmpList = [],
      timestamp,
    } = {},
    ...cache
  } = state;

  const now = moment();

  const dmpIndex = dmpList.findIndex(dmp => dmp.s_ins === ins);
  if (dmpIndex > -1) {
  // update last access date
    const localAccess = moment(dmpList[dmpIndex].localAccessDate, 'YYYYMMDDHHmmss');
    const serverAccess = moment(dmpList[dmpIndex].s_lastAccessDate, 'YYYYMMDDHHmmss');

    let newAccessDate = dmpList[dmpIndex].s_lastAccessDate;
    if (Math.abs(localAccess.diff(now, 'day')) >= 1 && localAccess.diff(serverAccess, 'day') >= 1) {
      newAccessDate = localAccess.format('YYYYMMDDHHmmss');
    }
    dmpList[dmpIndex] = {
      ...dmpList[dmpIndex],
      localAccessDate: now.format('YYYYMMDDHHmmss'),
      s_lastAccessDate: newAccessDate,
    };

    return {
      [performer]: {
        AccessibleDmps: deduplicatePatientsList(dmpList),
        timestamp,
      },
      ...cache,
    };
  }

  return state;
};


function associateIdentityToPatient(state, action) {
  const { ins, identity, performer } = action;

  const {
    [performer]: {
      AccessibleDmps: dmpList = [],
      timestamp,
    } = {},
    ...cache
  } = state;

  return {
    [performer]: {
      AccessibleDmps: deduplicatePatientsList(
        dmpList.map(dmp => (
          dmp.s_ins === ins
            ? ({ ...dmp, insiIdentity: identity })
            : dmp
        )),
      ),
      timestamp,
    },
    ...cache,
  };
}

export function dmpconnectAccessibleDmpList(state = initialState, action) {
  switch (action.type) {
    case dmpCommandSuccessContextualizedType(apiSections.ACCESSIBLE_DMP_LIST_SECTION):
      return cacheAccessibleDMPList(state, action);
    case dmpconnectAccessibleDmpListActionsConstants.IMPORT_ACCESSIBLE_DMPS_LIST:
      return importList(state, action);
    case dmpconnectAccessibleDmpListActionsConstants.RESET_LIST:
      return resetList(state, action);
    case dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION):
      return merge({}, state, updateCacheAccessibleDMPListFromDirectAuth(state, action));
    case dmpconnectAccessibleDmpListActionsConstants.UPDATE_LAST_ACCESS:
      return merge({}, state, updateCacheLastAccessDate(state, action));
    case dmpconnectAccessibleDmpListActionsConstants.ASSOCIATE_INSI_IDENTITY:
      return associateIdentityToPatient(state, action);
    default:
      return state;
  }
}
