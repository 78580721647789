import * as React from 'react';
import PropTypes from 'prop-types';
import { extractDate } from 'dmpconnectjsapp-base/helpers/accessors';
import SimpleTable from '../Common/Table/SimpleTable';
import HRPersonMiniCard from './HRPersonMiniCard';

const columns = [
  {
    accessor: 'date',
    id: 'date',
    Cell: ({ value }) => (extractDate(value, 'YYYYMMDD').format('DD/MM/YYYY')),
    Header: 'Date de l\'acte',
    disableFilters: true,
  },
  {
    accessor: 'code',
    id: 'code',
    Header: 'Code de l\'acte',
    disableFilters: true,
  },
  {
    accessor: 'label',
    id: 'label',
    Header: 'Libellé de l\'acte',
    disableFilters: true,
  },
  {
    id: 'performer',
    Header: 'Réalisé par',
    disableFilters: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => <HRPersonMiniCard person={row.original.performer} />,
  },
  {
    accessor: 'performer.es.name',
    id: 'es',
    Header: 'Etablissement',
    disableFilters: true,
  },
];

const HRMedicalCareSection = ({ section }) => (
  <>
    {section && section.values && section.values.length > 0 && (
      <>
        <h1>{section.title}</h1>
        <SimpleTable
          columns={columns}
          data={section.values}
          striped
          responsive
          initialSortBy={[{ desc: true, id: 'date' }]}
        />
      </>
    )}
  </>
);

HRMedicalCareSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default HRMedicalCareSection;
