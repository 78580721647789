import * as React from 'react';
import { getConfigurationValue, isAirActive } from 'dmpconnectjsapp-base/helpers/accessors';
import { isTransactionAllowed, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { getAccessibleDMPListCache } from '../../../dmpconnect/helpers/accessibleDMPList';
import checkAccessRights from '../../AccessRights/CheckAccessRights';
import AccessByAvailableDMP from '../../DMPSearch/AccessByAvailableDMP';
import { associateInsiIdentity, setModalConfirmation } from '../../../dmpconnect/actions';
import { closeConfirmModal } from '../../../dmpconnect/helpers/documentUtils';

const AssociateInsiIdentityToPatient = ({
  dispatch, accessibleDMPList, identity, accessRights, ins,
}) => {
  const [showSelectionModal, setShowSelectionModal] = React.useState(false);

  const associateIdentity = (selectedIns) => {
    closeConfirmModal(dispatch);
    dispatch(associateInsiIdentity(selectedIns, identity, accessRights.psId));
  };

  const confirmAssociation = (selectedIns) => {
    setShowSelectionModal(false);
    dispatch(setModalConfirmation({
      show: true,
      title: 'Substituer l\'identité du patient ? (DEV)\n',
      message: (
        <>
          Les traits d&apos;identité affichés dans l&apos;interface resteront ceux du DMP.
        </>
      ),
      confirmButtonText: 'Oui',
      confirmButtonVariant: 'primary',
      cancelButtonText: 'Non',
      handleConfirm: () => associateIdentity(selectedIns),
      handleCancel: () => setShowSelectionModal(true),
    }));
  };

  return (
    <>
      <Button
        type="button"
        size="sm"
        onClick={() => {
          if (ins) {
            confirmAssociation(ins);
          } else {
            setShowSelectionModal(true);
          }
        }}
      >
        {`Associer l'identité à ${ins ? 'ce' : 'un'} patient (DEV)`}
      </Button>
      <Modal
        size="lg"
        show={showSelectionModal}
        onHide={() => setShowSelectionModal(false)}
      >
        <Modal.Header closeButton><Modal.Title as="h5">Choisir le patient à associer à l&apos;identité</Modal.Title></Modal.Header>
        <Modal.Body>
          <AccessByAvailableDMP
            selectIns={selectedIns => confirmAssociation(selectedIns)}
            selector
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

AssociateInsiIdentityToPatient.propTypes = {
  dispatch: PropTypes.func.isRequired,
  accessibleDMPList: PropTypes.object,
  identity: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  accessRights: PropTypes.object.isRequired,
  ins: PropTypes.string,
};
AssociateInsiIdentityToPatient.defaultProps = {
  accessibleDMPList: {},
  ins: undefined,
};

function mapStateToProps(state, props) {
  const { accessRights } = props;
  const {
    dmpconnectAccessibleDmpList: accessibleDMPListCache,
    dmpconnectConnectorConfig,
  } = state;

  const air = isAirActive(state);
  const airOnly = getConfigurationValue('airOnly', dmpconnectConnectorConfig);
  return {
    accessibleDMPList: getAccessibleDMPListCache(
      accessibleDMPListCache,
      accessRights.psId,
      air && !airOnly ? accessRights.esId : null,
      isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS),
    ),
  };
}

export default checkAccessRights(connect(mapStateToProps)(AssociateInsiIdentityToPatient));
