import { informantRelationType } from '../../constants/informants';

export function extractJSONAdresses(informant) {
  const { addresses = [] } = informant;
  return addresses.map(address => ({
    i_type: Number(address.type),
    s_country: address.countrylue,
    s_state: address.state || '',
    s_city: address.city || '',
    s_postalCode: address.postalCode || '',
    s_houseNumber: address.houseNumber || '',
    s_houseNumberNumeric: address.houseNumberNumeric || '',
    i_streetNameType: address.streetNameType ? Number(address.streetNameType) : 0,
    s_streetName: address.streetName || '',
    s_additionalLocator: address.additionalLocator || '',
    s_unitId: address.unitId || '',
    s_postBox: address.postBox || '',
    s_precInct: address.precInct || '',
  }));
}

export function extractJSONTelecoms(informant) {
  const { telecoms = [] } = informant;
  return telecoms.map(telecom => ({
    i_type: Number(telecom.type),
    i_usage: telecom.usage ? Number(telecom.usage) : 512,
    s_value: telecom.value,
  }));
}

export function extractJSONEventCodes(document) {
  const { EventCodes = [] } = document;

  return EventCodes.map(eventCode => ({
    code: eventCode.code,
    classification: eventCode.classification,
    description: eventCode.description,
  }));
}

export function extractJSONAdditionalAuthors(document) {
  const { additionalAuthors = [] } = document;

  return additionalAuthors.map(author => ({
    s_hpName: author.name,
    s_hpGiven: author.given,
    s_hpProfession: author.profession,
    s_hpProfessionOid: author.professionOid,
    s_hpSpecialty: author.specialty,
    s_hpInternalId: author.internalId,
    s_hpInternalIdType: author.internalIdType || undefined,
    i_hpAuthenticationMode: author.authenticationMode ? Number(author.authenticationMode) : 25,
  }));
}

export function extractJSONInformants(document) {
  const { informants = [] } = document;

  return informants.map((informant) => {
    let addresses;
    let telecoms;

    if (informant.addresses) {
      addresses = extractJSONAdresses(informant);
    }
    if (informant.telecoms) {
      telecoms = extractJSONTelecoms(informant);
    }
    return ({
      s_name: informant.name,
      s_given: informant.given,
      i_type: Number(informant.type),
      i_relationType: informant.relationType ? Number(informant.relationType) : informantRelationType.unknown,
      Addresses: addresses,
      Telecoms: telecoms,
    });
  });
}

export function extractJSONIntendedRecipients(document) {
  const { intendedRecipients = [] } = document;

  return intendedRecipients.map((intendedRecipient) => {
    let addresses;
    let telecoms;

    if (intendedRecipient.addresses) {
      addresses = extractJSONAdresses(intendedRecipient);
    }
    if (intendedRecipient.telecoms) {
      telecoms = extractJSONTelecoms(intendedRecipient);
    }
    return ({
      s_hpName: intendedRecipient.name,
      s_hpGiven: intendedRecipient.given,
      s_hpProfession: intendedRecipient.profession || undefined,
      s_hpProfessionOid: intendedRecipient.professionOid || undefined,
      s_hpSpecialty: intendedRecipient.specialty || undefined,
      s_hpInternalId: intendedRecipient.internalId,
      s_hpInternalIdType: intendedRecipient.internalIdType || undefined,
      i_hpAuthenticationMode: intendedRecipient.authenticationMode ? Number(intendedRecipient.authenticationMode) : 25,
      Addresses: addresses,
      Telecoms: telecoms,
    });
  });
}

export function extractJSONTreatingPhysician(document) {
  if (document.treatingPhysician) {
    const {
      treatingPhysician: {
        name,
        given,
        profession,
        professionOid,
        specialty,
        internalId,
        internalIdType,
        authenticationMode,
      } = {},
    } = document;

    return {
      s_hpName: name,
      s_hpGiven: given,
      s_hpProfession: profession,
      s_hpProfessionOid: professionOid,
      s_hpSpecialty: specialty,
      s_hpInternalId: internalId,
      s_hpInternalIdType: internalIdType,
      i_hpAuthenticationMode: Number(authenticationMode),
    };
  }
  return undefined;
}

export function extractJSONPerformer(document) {
  if (document.performer) {
    const {
      performer: {
        name,
        given,
        profession,
        professionOid,
        specialty,
        internalId,
        internalIdType,
        authenticationMode,
      } = {},
    } = document;

    return {
      Hp: {
        s_hpName: name,
        s_hpGiven: given,
        s_hpProfession: profession,
        s_hpProfessionOid: professionOid,
        s_hpSpecialty: specialty,
        s_hpInternalId: internalId,
        s_hpInternalIdType: internalIdType,
        i_hpAuthenticationMode: Number(authenticationMode),
      },
    };
  }

  return undefined;
}
