import { dmpGenders, dmpSexes, guardianRoles } from './dmpConstants';
import { dmpStatuses, documentFormats, userAuthorizationStatuses } from './index';

export const remoteSexes = {
  U: dmpSexes.UNKNOWN,
  M: dmpSexes.MALE,
  F: dmpSexes.FEMALE,
};

export const remoteGenders = {
  U: dmpGenders.UNKNOWN,
  M: dmpGenders.MR,
  Mme: dmpGenders.MRS,
};

export const remoteGuardianRoles = {
  NONE: guardianRoles.GUARDIAN_NONE,
  FTH: guardianRoles.GUARDIAN_FATHER,
  MTH: guardianRoles.GUARDIAN_MOTHER,
  STPFTH: guardianRoles.GUARDIAN_STEPFATHER,
  STPMTH: guardianRoles.GUARDIAN_STEPMOTHER,
  GRFTH: guardianRoles.GUARDIAN_GRANDFATHER,
  GRMTH: guardianRoles.GUARDIAN_GRANDMOTHER,
  GGRFTH: guardianRoles.GUARDIAN_GREAT_GRANDFATHER,
  GGRMTH: guardianRoles.GUARDIAN_GREAT_GRANDMOTHER,
  AUNT: guardianRoles.GUARDIAN_AUNT,
  UNCLE: guardianRoles.GUARDIAN_UNCLE,
  BRO: guardianRoles.GUARDIAN_BROTHER,
  SIS: guardianRoles.GUARDIAN_SISTER,
  RESPRSN: guardianRoles.GUARDIAN_GUARDIAN,
};

export const remoteDmpStatuses = {
  [dmpStatuses.DMPExist]: 'open',
  [dmpStatuses.DMPIsClosed]: 'closed',
  [dmpStatuses.DMPNotFound]: 'no-dmp',
  [dmpStatuses.DMPError]: 'error',
};

export const remoteUserAuthorizationStatuses = {
  [userAuthorizationStatuses.AuthorizationError]: 'no',
  [userAuthorizationStatuses.AuthorizationExist]: 'valide',
  [userAuthorizationStatuses.AuthorizationExpired]: 'outdated',
  [userAuthorizationStatuses.AuthorizationDenied]: 'no',
  [userAuthorizationStatuses.NoAuthorization]: 'no',
};
export const remoteDocumentFormats = {
  text: documentFormats.textPlain,
  rtf: documentFormats.richTextFormat,
  jpeg: documentFormats.jpeg,
  tiff: documentFormats.tiff,
  pdf: documentFormats.pdf,
  vsm: documentFormats.vsm,
  dlu: documentFormats.dlu,
  fludr: documentFormats.fludr,
  fludt: documentFormats.fludt,
  bio: documentFormats.biologicalReport,
  kos: documentFormats.kos,
};
