import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  getError, hasError, isLoading, isReady,
} from 'dmpconnectjsapp-base/helpers/common';
import { getConfigurationValue, isAirActive } from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps, isTransactionAllowed, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import { getDirectAuthenticationDMPStatus } from '../../dmpconnect/actions';
import SectionStatusWrapper from '../Common/SectionStatus/SectionStatusWrapper';
import { getDirectAuthenticationStatus } from '../../dmpconnect/helpers/directAuthenticationDMPStatus';
import Loading from '../Common/Loading/Loading';
import { isModalError } from '../../dmpconnect/helpers/error';
import { getAccessibleDMPListCache } from '../../dmpconnect/helpers/accessibleDMPList';

const DMPStatusProvider = ({
  dmpStatus,
  certifiedIns,
  certifiedInsError,
  showLoading,
  showError,
  children,
  dispatch,
  getOnMount,
  accessibleDmp,
}) => {
  const [isRunning, setIsRunning] = React.useState(false);
  useEffect(() => {
    if (!isRunning && getOnMount && certifiedIns && !isReady(dmpStatus) && !hasError(dmpStatus) && !isLoading(dmpStatus)) {
      setIsRunning(true);
      dispatch(getDirectAuthenticationDMPStatus(certifiedIns, null, true));
    }
  }, [isRunning, isReady(dmpStatus), hasError(dmpStatus), isLoading(dmpStatus), certifiedIns, getOnMount]);

  React.useEffect(() => {
    if (isReady(dmpStatus) || hasError(dmpStatus)) {
      setIsRunning(false);
    }
  }, [isReady(dmpStatus), hasError(dmpStatus)]);

  const {
    ExistingTestAnswer: {
      s_closingDate: closingDate,
      s_closingMessage: closingMessage,
      i_dmpStatus: status,
      i_ensRelatedDmp: ensRelated,
      i_userAuthorization: authorization,
      psAuthorization,
      esAuthorization,
      i_sex: sex,
      AdminData: {
        Ins: {
          s_ins,
          s_insType,
        } = {},
        s_birthday: birthday,
        s_patientGivenName: givenName,
        s_patientFirstBirthGiven: firstGivenName,
        s_patientName: name,
        s_patientBirthName: birthName,
      } = {},
    } = {},
    i_treatingPhysician: isTreatingPhysician,
    showEmergency,
  } = dmpStatus;

  const error = getError(dmpStatus);

  return (
    <SectionStatusWrapper
      error={showError && !isModalError(error) ? error : null}
      loading={showLoading && isLoading(dmpStatus)}
      loadingContent={<Loading centered={false} size="sm" message={null} />}
      showChildrenOnError={!showError}
    >
      {React.cloneElement(children, {
        dmpStatusLoading: isLoading(dmpStatus),
        dmpStatusError: error,
        dmpStatusReady: isReady(dmpStatus),
        closingDate,
        closingMessage,
        status,
        ensRelated,
        authorization,
        psAuthorization,
        esAuthorization,
        isTreatingPhysician: isTreatingPhysician === 1,
        showEmergency,
        birthday,
        givenName: firstGivenName || givenName,
        name,
        birthName,
        sex,
        ins: `${s_ins}${s_insType}`,
        certifiedInsError,
        lastUpdateDate: accessibleDmp ? accessibleDmp.s_lastUpdateDate : undefined,
      })}
    </SectionStatusWrapper>
  );
};

DMPStatusProvider.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showLoading: PropTypes.bool,
  showError: PropTypes.bool,
  getOnMount: PropTypes.bool,
  dmpStatus: PropTypes.object,
  accessibleDmp: PropTypes.object,
  certifiedIns: PropTypes.string,
  certifiedInsError: PropTypes.bool,
};

DMPStatusProvider.defaultProps = {
  showLoading: true,
  showError: true,
  dmpStatus: {},
  accessibleDmp: {},
  certifiedIns: null,
  getOnMount: false,
  certifiedInsError: false,
};

function mapStateToProps(state, props) {
  const {
    certifiedIns,
  } = props;
  const { accessRights } = getAccessRightsProps(state);
  const {
    dmpconnectAccessibleDmpList: accessibleDMPListCache,
    dmpconnectConnectorConfig,
  } = state;
  const air = isAirActive(state);
  const airOnly = getConfigurationValue('airOnly', dmpconnectConnectorConfig);
  const accessibleDMPList = getAccessibleDMPListCache(
    accessibleDMPListCache,
    accessRights.psId,
    air && !airOnly ? accessRights.esId : null,
    isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS),
  );

  let accessibleDmp = {};
  if (accessibleDMPList) {
    accessibleDmp = (accessibleDMPList.AccessibleDmps || []).find(d => d.s_ins === certifiedIns);
  }

  return {
    accessibleDmp,
    dmpStatus: getDirectAuthenticationStatus(state, certifiedIns),
  };
}

const ConnectedDMPStatusProvider = connect(mapStateToProps)(DMPStatusProvider);

export default ConnectedDMPStatusProvider;
