import { clearSubSection }                           from 'dmpconnectjsapp-base/actions';
import { formatGetInsFromIdentityInformationParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands                                      from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections }                               from 'dmpconnectjsapp-base/constants';
import { extractDate, getInsiConfig }                from 'dmpconnectjsapp-base/helpers/accessors';
import { hasError, isLoading, isReady }              from 'dmpconnectjsapp-base/helpers/common';
import PropTypes                                     from 'prop-types';
import * as React                                    from 'react';
import { connect }                                   from 'react-redux';
import { getAction }                                 from '../../../dmpconnect/actions';
import { isMssActive }                               from '../../../dmpconnect/helpers';
import { fixQualifIdentityOid, getQualifiedInsINSi } from '../../../dmpconnect/helpers/certifiedIdentity';
import env                                           from '../../../envVariables';
import CheckAccessRights                             from '../../AccessRights/CheckAccessRights';
import Alert                                         from '../../Common/Message/Alert';
import TLSiConnector                                 from '../TLSiConnector';
import AssociateInsiIdentityToPatient                from './AssociateInsiIdentityToPatient';
import INSiResult                                    from './INSiResult';
import InsManualSearchForm                           from './InsManualSearchForm';

const InsManualSearch = ({
  dispatch,
  identity,
  insiConfig,
  defaultValues,
  showTitle,
  ins,
  tlsiServerName,
  selectIns,
  showUploadForm,
  mssActive,
  selector,
}) => {
  React.useEffect(() => {
    dispatch(clearSubSection(apiSections.INS_FROM_VITALE_CARD, 'search'));
  }, []);
  
  const submit = (form) => {
    dispatch(clearSubSection(apiSections.INS_FROM_VITALE_CARD, 'search'));
    dispatch(getAction(
      commands.getInsFromIdentityInformation,
      apiSections.INS_FROM_VITALE_CARD,
      {
        s_insiUrl: tlsiServerName,
        ...formatGetInsFromIdentityInformationParams({
          name         : form.name,
          given        : form.given,
          birthday     : extractDate(form.birthday, 'DD/MM/YYYY').format('YYYYMMDD'),
          sex          : Number(form.sex),
          idam         : insiConfig.idam,
          numAm        : insiConfig.numAm,
          lpsName      : insiConfig.lpsName,
          lpsVersion   : insiConfig.lpsVersion,
          billingNumber: insiConfig.billingNumber,
        }),
      },
      {
        subSection : 'search',
        silentError: true,
      },
    ));
  };
  
  return (
    <TLSiConnector>
      {showTitle && <h4>Recherche d&apos;INS par traits d&apos;identité</h4>}
      <Alert type="info">
        Veuillez saisir les traits d&apos;identité du patient.
        <br/>
        <br/>
        Le nom, le prénom et le lieu ne doivent contenir que des caractères majuscules, pas d&apos;accent, ni de c&eacute;dille.
        <br/>
        Seuls les espaces, les apostrophes et les tirets sont acceptés.
      </Alert>
      <InsManualSearchForm
        loading={isLoading(identity)}
        onSubmit={form => submit(form)}
        defaultValues={defaultValues}
      />
      <INSiResult
        certifiedInsLoading={isLoading(identity)}
        certifiedIdentityResult={fixQualifIdentityOid(identity)}
        showAccessDmpButton={!!selectIns}
        accessDmp={selectIns}
        showUploadForm={showUploadForm}
        mssActive={mssActive}
        selector={selector}
      />
      {Number(env.REACT_APP_DEBUG) === 1 && isReady(identity) && (
        <>
          <div className="text-right">
            <AssociateInsiIdentityToPatient
              identity={identity}
              ins={ins}
            />
          </div>
        </>
      )}
    </TLSiConnector>
  );
};

function mapStateToProps(state, props) {
  const {
          dmpconnect               : {
            [apiSections.INS_FROM_VITALE_CARD]: {
              search: identity = {},
            },
          },
          dmpconnectConnectorConfig: {
            tlsiServerName,
          },
        }          = state;
  const insiConfig = getInsiConfig(state);
  
  return {
    certifiedInsError  : hasError(identity),
    certifiedInsLoading: isLoading(identity),
    certifiedInsReady  : isReady(identity),
    certifiedIns       : isReady(identity) ? getQualifiedInsINSi(identity) : null,
    insiConfig,
    identity,
    tlsiServerName,
    mssActive          : isMssActive(state),
  };
}

InsManualSearch.propTypes = {
  dispatch      : PropTypes.func.isRequired,
  insiConfig    : PropTypes.object.isRequired,
  identity      : PropTypes.object,
  defaultValues : PropTypes.object,
  showTitle     : PropTypes.bool,
  ins           : PropTypes.string,
  tlsiServerName: PropTypes.string,
  selectIns     : PropTypes.func,
  showUploadForm: PropTypes.func,
  selector: PropTypes.bool,
};

InsManualSearch.defaultProps   = {
  identity      : {},
  defaultValues : undefined,
  showTitle     : true,
  ins           : undefined,
  tlsiServerName: '',
  selectIns     : undefined,
  showUploadForm: null,
  selector: false,
};
const ConnectedInsManualSearch = CheckAccessRights(connect(mapStateToProps)(InsManualSearch));
export default ConnectedInsManualSearch;
