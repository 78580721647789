import React from 'react';
import env from '../../envVariables';
import { formatVersion } from '../../dmpconnect/utils/version';

export default function FooterDP() {
  const version = env.REACT_APP_VERSION;

  return (
    <div id="footer" className="pb-1 d-flex align-items-end justify-content-center">
      <div className="">
        DP Consult version
        {' '}
        {formatVersion(version)}
        . Copyright 2000-2180, icanopée. Tous droits réservés
      </div>
    </div>
  );
}
