const patients = [
  {
    name: 'ERENTIEL',
    given: 'JEFF',
    birthday: '19980712',
    sex: 2,
  },
  {
    name: 'ENS-GRUBER',
    given: 'HANS',
    birthday: '19480221',
    sex: 2,
  },
  {
    name: 'ENS-GRUBER',
    given: 'SIMON',
    birthday: '19460221',
    sex: 2,
  },
  {
    name: 'TOME',
    given: 'ZARAH',
    birthday: '19770909',
    sex: 3,
  },
];
export default patients;
