import PropTypes                                from 'prop-types';
import React                                    from 'react';
import { Typeahead as ReactBootstrapTypeahead } from 'react-bootstrap-typeahead';
import defaultFilterBy                          from 'react-bootstrap-typeahead/lib/utils/defaultFilterBy';
import Form                                     from 'react-bootstrap/Form';

/**
 * This class is responsible for setting a few sensible defaults on the typeahead object.
 */
export default class CustomTypeahead extends React.Component {
  /**
   * This shows all of the values when you click into the typeahead, even if something is selected.  See more
   * here: https://stackoverflow.com/a/50376581/491553
   */
  static showAllOnClick(option, props) {
    if (props.multiple) {
      const newProps = { ...props, filterBy: ['label'] };
      return defaultFilterBy(option, newProps);
    }
    
    if (props.selected && props.selected.length) {
      // Display all the options if there's a selection.
      return true;
    }
    
    // Otherwise filter on some criteria.
    let { label } = option;
    if (typeof props.labelKey === 'function') {
      label = props.labelKey(option);
    }
    return label && label.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
  }
  
  static getSelectedChoice(choices, value, rule) {
    if (value === null) {
      return [];
    }
    const selectedValue = choices.find((item, index) => rule(item, index));
    if (selectedValue === undefined) {
      return [];
    }
    return [selectedValue];
  }
  
  render() {
    const {
            options,
            selectionRule,
            value,
            name,
            onChange,
            valueKey,
            id,
            returnItem,
            ...props
          } = this.props;
    
    return (
      <ReactBootstrapTypeahead
        flip
        onFocus={event => event.target.select()}
        emptyLabel="Aucun résultat trouvés ..."
        options={options}
        name={name}
        id={`${id}-list`}
        selected={CustomTypeahead.getSelectedChoice(options, value, selectionRule)}
        onChange={(selected) => {
          if (returnItem) {
            onChange(selected.length > 0 ? selected[0] : undefined);
          } else {
            onChange({ target: { value: (selected.length > 0) ? selected[0][valueKey] : '', name } });
          }
          
        }}
        renderInput={({ inputRef, ...inputProps }) => <Form.Control name={name} {...inputProps} />}
        {...props}
      />
    );
  }
}

CustomTypeahead.propTypes = {
  options      : PropTypes.array.isRequired,
  selectionRule: PropTypes.func.isRequired,
  value        : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  name         : PropTypes.string.isRequired,
  positionFixed: PropTypes.bool,
  inputProps   : PropTypes.object,
  filterBy     : PropTypes.func,
  onChange     : PropTypes.func.isRequired,
  valueKey     : PropTypes.string,
  id           : PropTypes.string.isRequired,
  returnItem   : PropTypes.bool,
};

CustomTypeahead.defaultProps = {
  filterBy     : CustomTypeahead.showAllOnClick, // Show all of the results even if something is selected
  positionFixed: true, // Display the typeahead above scrollbars
  inputProps   : { autoComplete: 'off' }, // Turn off autocomplete in Chrome
  value        : null,
  valueKey     : 'value',
  returnItem   : false,
};
