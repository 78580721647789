import * as React from 'react';
import PropTypes from 'prop-types';
import { extractDate } from 'dmpconnectjsapp-base/helpers/accessors';
import SimpleTable from '../Common/Table/SimpleTable';
import HRPersonMiniCard from './HRPersonMiniCard';

const columns = [
  {
    accessor: 'date',
    id: 'date',
    Cell: ({ value }) => (extractDate(value, 'YYYYMMDD').format('DD/MM/YYYY')),
    Header: 'Date de prescription',
    disableFilters: true,
  },
  {
    accessor: 'cipLabel',
    id: 'cipLabel',
    Header: 'Vaccin',
    disableFilters: true,
  },
  {
    accessor: 'atcLabel',
    id: 'atcLabel',
    Header: 'Valence vaccinale',
    disableFilters: true,
  },
  {
    id: 'author',
    Header: 'Prescrit par',
    disableFilters: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => <HRPersonMiniCard person={row.original.author} />,
  },
  {
    accessor: 'author.es.name',
    id: 'author_es',
    Header: 'Etablissement',
    disableFilters: true,
  },
  {
    accessor: 'performer.es.name',
    id: 'performer',
    Header: 'Délivré par',
    disableFilters: true,
  },
];

const HrVaccinationSection = ({ section }) => (
  <>
    {section && section.values && section.values.length > 0 && (
      <>
        <h1>{section.title}</h1>
        <SimpleTable
          columns={columns}
          data={section.values}
          striped
          responsive
          initialSortBy={[{ desc: true, id: 'date' }]}
        />
      </>
    )}
  </>
);

HrVaccinationSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default HrVaccinationSection;
