import { lazy } from 'react';

class ChunkLoadError extends Error {
  constructor(message, originalError) {
    super(message);
    this.originalError = originalError;
    this.name = 'ChunkLoadError';
  }
}

const lazyWithRetry = componentImport => lazy(async () => {
  try {
    return await componentImport();
  } catch (error) {
    throw new ChunkLoadError(error.message, error);
  }
});
export default lazyWithRetry;
