import { put, select, takeEvery } from 'redux-saga/effects';

import { softwareErrors } from 'dmpconnectjsapp-base/errors';
import { clearSession, logoutSuccess, resetApplication } from 'dmpconnectjsapp-base/actions';
import { push } from 'connected-react-router';
import { cardStatuses, dmpconnectMonitoringActionConstants } from '../constants';
import {
  getCpxCardStatus as getCpxCardStatusAccessor,
  isUserLoggedIn as isUserLoggedInAccessor,
} from '../helpers';


import { setModalError, setRemovedCPX, stopCpxMonitoring } from '../actions';
import { errorTypes } from '../errors/errorConfiguration';
import { createError, createModalError } from '../errors';

const handleCPXEvents = function* ({ data }) {
  const isLoggedIn = yield select(isUserLoggedInAccessor);
  const cpxCardStatus = yield select(getCpxCardStatusAccessor);
  const { message } = data;

  yield put(setRemovedCPX(false));

  if (message !== undefined) {
    if (message.error) {
      const error = createError(
        errorTypes.SoftwareErrors,
        softwareErrors.DMPCONNECTJS_MISSING_OR_UNREACHABLE,
      );
      yield put(setModalError(createModalError(error)));
    }

    if (
      data.message.i_cardStatus === cardStatuses.NoCardInserted
      && (isLoggedIn || cpxCardStatus === 'OK')
    ) {
      yield put(setRemovedCPX(true));
      yield put(stopCpxMonitoring());
      yield put(logoutSuccess());
      yield put(clearSession());
      yield put(resetApplication());
      yield put(push('/'));
    }
  }
};

export const handleCPXMonitoringUpdate = function* () {
  yield takeEvery(dmpconnectMonitoringActionConstants.DMPC_CPX_MONITORING_UPDATE, handleCPXEvents);
};
