import { dmpconnectPDFsActionConstants } from '../constants';

const initialState = {};

const setAckPDF = (state, content) => {
  const {
    otp,
    create,
  } = state;

  return { otp, create, ack: content };
};

const setOtpPDF = (state, content) => {
  const {
    ack,
    create,
  } = state;

  return { ack, create, otp: content };
};
const setCreatePDF = (state, content) => {
  const {
    ack,
    otp,
  } = state;

  return { ack, otp, create: content };
};

export function dmpconnectPDFs(state = initialState, action) {
  switch (action.type) {
    case dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_RESET:
      return initialState;
    case dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_ACK:
      return setAckPDF(state, action.content);
    case dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_OTP:
      return setOtpPDF(state, action.content);
    case dmpconnectPDFsActionConstants.DMPC_SET_DMP_PDF_CREATE:
      return setCreatePDF(state, action.content);
    default:
      return state;
  }
}
