import {
  actorTypes, canUpload, isTransactionAllowed, transactions,
} from 'dmpconnectjsapp-base/rules/accessRights';
import { documentStatuses, documentVisibilityStatuses, isHiddenFor } from 'dmpconnectjsapp-base/helpers/findDocuments';

const uniqueUUidSeparator = '__';

export const calculateVisibility = (patientHidden, healthcareProfessionalHidden, guardianHidden, forUpload = false) => {
  const patientHiddenBool = [true, '1', 1].includes(patientHidden);
  const healthcareProfessionalHiddenBool = [true, '1', 1].includes(healthcareProfessionalHidden);
  const guardianHiddenBool = [true, '1', 1].includes(guardianHidden);

  if (!patientHiddenBool && !healthcareProfessionalHiddenBool && !guardianHiddenBool) {
    return forUpload ? documentVisibilityStatuses.NORMAL : null;
  }
  return (patientHiddenBool ? documentVisibilityStatuses.PATIENT_HIDDEN : 0)
      + (healthcareProfessionalHiddenBool ? documentVisibilityStatuses.HEALTCARE_PROFESSIONAL_HIDDEN : 0)
      + (guardianHiddenBool ? documentVisibilityStatuses.GUARDIAN_HIDDEN : 0);
};

/**
 * Test if ps owns the document
 * @param document
 * @param psId
 * @returns {*}
 */
export const ownsDocument = (document, psId) => document.Authors && document.Authors.some(item => item.s_hpInternalId === psId);
// export const ownsDocument = (document, psId) => document.Authors && document.Authors.some((item) => {
//   const internalId = item.s_hpInternalId;
//
//   if (internalId.indexOf('/') > -1) {
//     const ids = internalId.split('/');
//     return psId === ids[1];
//   }
//
//   return item.s_hpInternalId === psId;
// });
export const getUniqueUUid = (uniqueId, uuid) => `${uniqueId}${uniqueUUidSeparator}${uuid}`;
export const explodeUniqueUUid = (uniqueUUid) => {
  const [uniqueId, uuid] = uniqueUUid.split(uniqueUUidSeparator);
  return { uniqueId, uuid };
};

export const canEditDocument = (document, accessRights) => ownsDocument(document, accessRights.psId)
  && canUpload(accessRights)
  && document.i_document_Status !== documentStatuses.ARCHIVED
  && document.i_document_Status !== documentStatuses.DEPRECATED;

export const canEditDocumentStatus = (document, accessRights) => (
  document.i_document_Status !== documentStatuses.DEPRECATED
  && (
    isTransactionAllowed(accessRights, transactions.ARCHIVE_DOC) === true
    || isTransactionAllowed(accessRights, transactions.UNARCHIVE_DOC) === true
  )
  && (
    accessRights.actorType !== actorTypes.PS_NO_PHYSICIAN
    || ownsDocument(document, accessRights.psId)
  )
);

export const canEditDocumentVisibility = (document, accessRights) => (
  document.i_document_Status !== documentStatuses.ARCHIVED
  && document.i_document_Status !== documentStatuses.DEPRECATED
  && (
    isTransactionAllowed(accessRights, transactions.HIDE_DOC_TO_PS) === true
    || isTransactionAllowed(accessRights, transactions.UNHIDE_DOC_TO_PS) === true
    || isTransactionAllowed(accessRights, transactions.UNHIDE_DOC_TO_PATIENT) === true
  )
);

export const canDeleteDocument = (document, accessRights) => ownsDocument(document,
  accessRights.psId)
  && document.i_document_Status !== documentStatuses.ARCHIVED
  && document.i_document_Status !== documentStatuses.DEPRECATED
  && isTransactionAllowed(accessRights, transactions.DELETE_DOC) === true;

// export const canConsultDocumentBack = (document, accessRights) => (
//   (
//     isTransactionAllowed(accessRights, transactions.CONSULT_DOCUMENT_HPS) === true
//       && (
//         !isHiddenFor(document.i_document_Visibility,
//           documentVisibilityStatuses.HEALTCARE_PROFESSIONAL_HIDDEN)
//         || ownsDocument(document, accessRights.psId)
//       )
//   )
//     || isTransactionAllowed(accessRights, transactions.CONSULT_DOCUMENT_NHPS) === true
// );

export const canConsultDocument = (document, accessRights) => {
  const hidden = isHiddenFor(document.i_document_Visibility, documentVisibilityStatuses.HEALTCARE_PROFESSIONAL_HIDDEN);

  return (
    (
      // fichier caché, mais propriétaire du document ou MT
      hidden
      && (
        (
          ownsDocument(document, accessRights.psId)
          || accessRights.treatingPhysician === true
        )
        // && isTransactionAllowed(accessRights, transactions.CONSULT_DOCUMENT_HPS) === true
      )
    )
    || (
      !hidden
      // && isTransactionAllowed(accessRights, transactions.CONSULT_DOCUMENT_NHPS) === true
    )
  );
};
