import { clearSection }                                               from 'dmpconnectjsapp-base/actions';
import { apiSections, mssLoginTypes, mssReceiptNotificationTypes, }   from 'dmpconnectjsapp-base/constants';
import {
  getActiveMssAccountConfig, getApiType, getDefaultMssConfig, getMssAccounts, getMssReplyTo
}                                                                     from 'dmpconnectjsapp-base/helpers/accessors';
import {
  getAccessRightsProps
}                                                                     from 'dmpconnectjsapp-base/rules/accessRights';
import PropTypes                                                      from 'prop-types';
import React                                                          from 'react';
import { Button, Form, Row }                                          from 'react-bootstrap';
import { connect }                                                    from 'react-redux';
import { deleteMssAccount, setModalConfirmation, setUserPreferences } from '../../dmpconnect/actions';
import { isMssActive }                                                from '../../dmpconnect/helpers';
import {
  closeConfirmModal
}                                                                     from '../../dmpconnect/helpers/documentUtils';
import { getDefaultMssAccountLabel }                                  from '../../dmpconnect/helpers/mss';
import SwitchCheckbox                                                 from '../Common/Form/Input/SwitchCheckbox';
import Alert                                                          from '../Common/Message/Alert';
import MssAccountSelector                                             from './MssAccountSelector';
import MssConfig                                                      from './MssConfig';
import MssOTPLogin                                                    from './MssOTPLogin';

function MssConfiguration({
  dispatch,
  columnLayout,
  mssActive,
  config,
  title,
  login,
  otpLogin,
  mssAccounts,
  mssDefaultConfig,
  psId,
  mssActiveAccount,
}) {
  if (otpLogin) {
    return <MssOTPLogin/>;
  }
  
  const [enabled, setEnabled]             = React.useState(mssActive);
  const [addingAccount, setAddingAccount] = React.useState(false);
  
  React.useEffect(() => {
    dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
    dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
    
    return () => {
      dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
      dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
    };
  }, []);
  
  const submitMss = () => {
    dispatch(setUserPreferences({ mssActive: enabled }));
    // TODO : handle this change in MSSConfig
    // TODO : handle cert change when switching accounts
    // dispatch(setMssConfiguration('mssReceiptNotificationType', receiptNotificationType));
  };
  
  const confirmDeleteMessage = React.useCallback(() => {
    dispatch(setModalConfirmation({
      show                : true,
      title               : 'Supprimer un compte MSS',
      message             : (
        <>
          Confirmez vous la suppression du compte
          <br/>
          {getDefaultMssAccountLabel(mssActiveAccount, mssAccounts, mssDefaultConfig.mssOperatorsConfig)}
          <br/>
          ?
        </>
      ),
      confirmButtonText   : 'Oui, supprimer',
      confirmButtonVariant: 'danger',
      cancelButtonText    : 'Annuler',
      handleConfirm       : () => {
        dispatch(deleteMssAccount(psId, mssActiveAccount));
        dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
        dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
        closeConfirmModal(dispatch);
      },
    }));
  }, [psId, mssActiveAccount]);
  
  return (
    <>
      <div className="d-flex justify-content-between align-items-start w-full">
        <div className="flex-grow-1">
          {title && (
            <h5 className="mb-4">{title}</h5>
          )}
          
          <h5>Gestion des comptes</h5>
          {mssAccounts.length > 0 && (
            <Form.Group>
              <Form.Label>Choix du compte</Form.Label>
              <MssAccountSelector disabled={addingAccount}/>
            </Form.Group>
          )}
          {addingAccount
           ? (
             <Alert type="info" className="align-items-center">
               <div className="d-flex align-items-center justify-content-between">
                 <span>Création d'un nouveau compte</span>
                 <Button
                   size="sm"
                   variant="outline-secondary"
                   onClick={() => setAddingAccount(false)}
                 >
                   Annuler
                 </Button>
               </div>
             </Alert>
           )
           : (
             <>
               <Button
                 size="sm"
                 variant="primary"
                 onClick={() => {
                   setAddingAccount(true);
                   dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
                   dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
                 }}
               >
                 <i className="ic-add-white"/>
                 Ajouter un compte
               </Button>
               {mssAccounts.length > 0 && (
                 <Button
                   size="sm"
                   className="ml-3"
                   variant="danger"
                   onClick={() => confirmDeleteMessage()}
                 >
                   <i className="ic-delete"/>
                   Supprimer ce compte
                 </Button>
               )}
             </>
           )
          }
          < Form className="flex-grow-1 mr-5 mt-3">
            {config && (
              <>
                <h6>Configuration de l&apos;accès MSS</h6>
                {!login && (
                  <Form.Group as={columnLayout ? Row : undefined}>
                    <Form.Label column={columnLayout} sm="4">
                      Activer MSS
                    </Form.Label>
                    <div className={`d-flex v-centered ${columnLayout ? 'col-sm-8' : undefined}`}>
                      <SwitchCheckbox
                        id="mssActive"
                        onChange={(event) => {
                          setEnabled(event.target.checked);
                          dispatch(setUserPreferences({ mssActive: event.target.checked }));
                        }}
                        name="mssActive"
                        checked={enabled}
                        showLabel
                        onLabel="Activé"
                        offLabel="Désactivé"
                      />
                    </div>
                  </Form.Group>
                )}
              </>
            )}
            
            {/* {loginType === mssLoginTypes.OTP ? ( */}
            {/*   <MssConfigOTP */}
            {/*     newAccount={addingAccount} */}
            {/*     columnLayout={columnLayout} */}
            {/*     config={config} */}
            {/*     mssApiType={'ddd'} */}
            {/*     mssLoginType={'ddd'} */}
            {/*     mssActive={enabled} */}
            {/*     mssReceiptNotificationType={'ddd'} */}
            {/*     onSubmit={() => submitMss()} */}
            {/*     mssReplyTo={'sss'} */}
            {/*     mssOperator={'ddd'} */}
            {/*     advancedContent={'edd'} */}
            {/*   /> */}
            {/* ) : ( */}
            
            {(mssAccounts.length > 0 || addingAccount) && (
              <MssConfig
                key={addingAccount ? 'new' : mssActiveAccount.id}
                columnLayout={columnLayout}
                config={config}
                mssActive={enabled}
                onSubmit={() => submitMss()}
                onEnd={() => setAddingAccount(false)}
                mssDefaultConfig={mssDefaultConfig}
                mssAccounts={mssAccounts}
                mssAccount={addingAccount ? {} : mssActiveAccount}
              />
            )}
            {/* )} */}
          </Form>
        </div>
        <i className="ic-mss"/>
      </div>
    </>
  );
}

MssConfiguration.propTypes    = {
  dispatch        : PropTypes.func.isRequired,
  columnLayout    : PropTypes.bool,
  mssActive       : PropTypes.bool,
  config          : PropTypes.bool,
  login           : PropTypes.bool,
  otpLogin        : PropTypes.bool,
  title           : PropTypes.string,
  connectorApiType: PropTypes.string.isRequired,
  mssAccounts     : PropTypes.array,
  mssDefaultConfig: PropTypes.object.isRequired,
  mssActiveAccount: PropTypes.object,
};
MssConfiguration.defaultProps = {
  columnLayout              : true,
  mssActive                 : false,
  config                    : true,
  login                     : false,
  otpLogin                  : false,
  title                     : '',
  mssReceiptNotificationType: mssReceiptNotificationTypes.DSN,
  mssAccounts               : [],
  mssDefaultConfig          : {},
  mssActiveAccount          : {},
};

function mapStateToProps(state, props) {
  const mssDefaultConfig = getDefaultMssConfig(state);
  const { accessRights } = getAccessRightsProps(state);
  
  const mssActiveAccount = getActiveMssAccountConfig(state);
  
  return {
    psId                      : accessRights.psId,
    mssActive                 : isMssActive(state),
    mssReceiptNotificationType: mssDefaultConfig.mssReceiptNotificationType,
    mssReplyTo                : getMssReplyTo(state),
    otpLogin                  : mssActiveAccount.mssLoginType === mssLoginTypes.OTP && props.login === true,
    connectorApiType          : getApiType(state),
    mssAccounts               : getMssAccounts(state),
    mssActiveAccount,
    mssDefaultConfig,
  };
}

export default connect(mapStateToProps)(MssConfiguration);
