import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { groupByFunc } from '../../dmpconnect/helpers/jsUtils';

const months = [
  { short: 'J', month: 'janvier' },
  { short: 'F', month: 'février' },
  { short: 'M', month: 'mars' },
  { short: 'A', month: 'avril' },
  { short: 'M', month: 'mai' },
  { short: 'J', month: 'juin' },
  { short: 'J', month: 'juillet' },
  { short: 'A', month: 'aout' },
  { short: 'S', month: 'septembre' },
  { short: 'O', month: 'octobre' },
  { short: 'N', month: 'novembre' },
  { short: 'D', month: 'décembre' },
];

const MobileMedsTimeline = ({ occurrences, cipCode, color }) => {
  const groupByMonth = React.useMemo(() => groupByFunc(
    occurrences,
    entry => (entry.date ? moment(entry.date, 'YYYYMMDD').format('MMMM') : undefined),
  ), [occurrences]);
  return (
    <div className={`meds-timeline ${color}`}>
      <div className="meds-line" />
      <div className="meds-info">
        {months.map(month => (
          <div className="meds-timeline-month" key={`${cipCode}-${month.month}`}>
            <div
              className={`
                marker
                ${groupByMonth[month.month] ? 'fill' : 'empty'}
                ${groupByMonth[month.month] && groupByMonth[month.month].length > 1 ? 'number' : ''}
              `}
            >
              {groupByMonth[month.month] && groupByMonth[month.month].length > 1 ? groupByMonth[month.month].length : ''}
            </div>
            <div className="month">{month.short}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
MobileMedsTimeline.propTypes = {
  occurrences: PropTypes.array,
  cipCode: PropTypes.string,
  color: ['red', 'blue'],
};

MobileMedsTimeline.defaultProps = {
  occurrences: [],
  cipCode: undefined,
  color: 'red',
};

export default MobileMedsTimeline;
