export const stepsStatus = {
  loading: 'loading',
  success: 'success',
  error  : 'error',
};

export const pacsSyncStatuses = {
  synced    : 1,
  error     : 0,
  toBeSynced: -1,
};
