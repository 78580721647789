import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';

export const getParcoursSoinUrl = (ins, yearIndex, monthIndex, dayIndex) => {
  let url = `/dmp/${encodeIns(ins)}/parcours-soin`;

  if (Number(yearIndex) >= 0) {
    url = `${url}/${yearIndex}`;

    if (Number(monthIndex) >= 0) {
      url = `${url}/${monthIndex}`;
    }

    if (Number(dayIndex) >= 0) {
      url = `${url}/${dayIndex}`;
    }
  }
  return url;
};
export const medsUrlTypes = {
  HR: 'hr',
  MEDS: 'meds',
};
export const getMedsUrl = (ins, type, hrSection, actCode, cipCode) => {
  let url = `/dmp/${encodeIns(ins)}/${type}`;

  if (!!actCode && !!cipCode) {
    url = `${url}/${hrSection}`;
  }
  return url;
};

export const getSearchDocsUrl = ins => `/dmp/${encodeIns(ins)}/search-docs`;
export const getAldDmpUrl = ins => `/dmp/${encodeIns(ins)}/ald`;
export const getAldVitaleUrl = subSection => `/ald/${encodeIns(subSection)}`;
export const getDocumentsUrl = ins => `/dmp/${encodeIns(ins)}/documents`;
export const getImageryUrl = ins => `/dmp/${encodeIns(ins)}/imagery`;
export const getMobileLandingUrl = ins => `/dmp/${encodeIns(ins)}/menu`;
export const getMobileUploadUrl = ins => `/upload/${encodeIns(ins)}`;
export const getAdminUpdateUrl = ins => `/dmp/${encodeIns(ins)}/administration/update`;

export const backPagesTypes = {
  DASHBOARD: 'dashboard',
  DMP: 'dmp',
  PREVIOUS: 'previous',
  MSS: 'mss',
  MOBILE_LANDING: 'mobile-landing',
};

export const getBackPage = (type, state) => {
  const {
    dmpconnectApplication: {
      backPages: {
        [type]: backPage,
      },
    },
  } = state;

  return backPage;
};

export const getHomePagePath = (state) => {
  const {
    dmpConnectPersistedAppConfiguration: {
      // efficienceDP,
      mssClientMode,
    },
  } = state;

  // if (efficienceDP) return '/dp';
  if (mssClientMode) return '/mss';
  return '/home';
};
