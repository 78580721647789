import { apiSections } from 'dmpconnectjsapp-base/constants';
import { authenticationTypes, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { getUserConfiguration } from 'dmpconnectjsapp-base/helpers/accessors';

export const getUserInfos = (state) => {
  const {
          dmpconnect: {
            [apiSections.CPX_CARD_SECTION]: cpxCard,
          },
          dmpconnectUser: {
            esUser,
          },
        } = state;
  const { accessRights: { authenticationType } } = getAccessRightsProps(state);
  
  if (authenticationType === authenticationTypes.DIRECT && isReady(cpxCard)) {
    return {
      name: cpxCard.s_name,
      given: cpxCard.s_given,
      rpps: cpxCard.s_internalId,
    };
  }
  
  if ([authenticationTypes.AIR, authenticationTypes.INDIRECT].includes(authenticationType) && esUser) {
    return {
      name: esUser.hpName,
      given: esUser.hpGiven,
      rpps: esUser.hpInternalId,
    };
  }
  
  return null;
};

export const professionOidToCardType = (oid) => {
  if (oid.slice(-1) === '8') return '2';
  return '1';
};

export const getUserConfigFromState = (state, key) => {
  const { accessRights: { psId } } = getAccessRightsProps(state);
  const { dmpconnectCPxConfiguration } = state;
  return getUserConfiguration(
    { s_internalId: psId },
    key,
    dmpconnectCPxConfiguration,
    '',
  );
};

export const getHealthcareSettings = (cpxCard, practiceLocationSetting) => {
  const { PracticeLocations } = cpxCard;
  if (PracticeLocations && PracticeLocations[practiceLocationSetting]) {
    const practiceLocation = PracticeLocations[practiceLocationSetting];
    return practiceLocation.s_practiceLocationHealthcareSettings;
  }
  return '';
}

export const getBillingNumber = (cpxCard, practiceLocationSetting) => {
  const { PracticeLocations } = cpxCard;
  if (PracticeLocations && PracticeLocations[practiceLocationSetting]) {
    const practiceLocation = PracticeLocations[practiceLocationSetting];
    let billingNumber;
    
    if (practiceLocation.s_practiceLocationBillingNumber) {
      billingNumber = practiceLocation.s_practiceLocationBillingNumber.substring(1);
    }
    
    if (!billingNumber && practiceLocation.s_practiceLocationStructureId) {
      billingNumber = practiceLocation.s_practiceLocationStructureId.substring(1);
    }
    
    if (billingNumber) {
      
      const regex = /^\w+$/; // accept only alphanumeric chars
      if (regex.test(billingNumber)) {
        
        return billingNumber;
      }
    }
  }
  return '';
}
