import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import DMPDocumentContent from './DMPDocumentContent';
import TitleTooltip from '../Common/TitleTooltip';


const DMPDocumentPreview = ({
  content, headerCda, information, classname, pager, buttons, stylesheetApplied, isMobile, ...props
}) => {
  const [showMetadata, setShowMetadata] = React.useState(false);

  return (
    <div className={`${stylesheetApplied ? 'flex-grow-1' : ''} ${classname}`} {...props}>
      <DMPDocumentContent
        stylesheetApplied={stylesheetApplied}
        showHeadersWhenStylesheetApplied={showMetadata}
        information={information}
        content={content}
        preview
        pager={pager}
        vsmClassName={`vsm ${showMetadata ? '' : 'hide-meta'}`}
        isMobile={isMobile}
      />
      {buttons}
      {headerCda && (
      <TitleTooltip
        id="preview-metadata-button"
        text={showMetadata ? 'Masquer les métadonnées' : 'Afficher les métadonnées'}
      >
        <Button
          size="sm"
          variant="outline-secondary"
          className="btn-icon-only metadata-button"
          onClick={() => setShowMetadata(!showMetadata)}
        >
          {showMetadata ? <i className="ic-visibility-off" /> : <i className="ic-visibility-on" />}
        </Button>
      </TitleTooltip>
      )}
    </div>
  );
};

DMPDocumentPreview.propTypes = {
  content: PropTypes.string.isRequired,
  information: PropTypes.object.isRequired,
  classname: PropTypes.string,
  pager: PropTypes.bool,
  stylesheetApplied: PropTypes.bool,
  buttons: PropTypes.node,
  headerCda: PropTypes.string,
  isMobile: PropTypes.bool,
};

DMPDocumentPreview.defaultProps = {
  classname: '',
  pager: false,
  stylesheetApplied: false,
  buttons: null,
  headerCda: null,
  isMobile: false,
};

export default DMPDocumentPreview;
