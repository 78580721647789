import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({
  children, type, className, iconAlign,
}) => (
  <div className={`alert alert-${type} d-flex align-items-${iconAlign} ${className}`}>
    <span className={`alert-icon alert-icon-${type}`} />
    <div className="alert-content">
      {children}
    </div>
  </div>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconAlign: PropTypes.string,
};

Alert.defaultProps = {
  className: '',
  iconAlign: 'start',
};

export default Alert;
