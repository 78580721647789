import { LOCATION_CHANGE }                                                           from 'connected-react-router';
import { dmpCommandFailureContextualizedType, dmpCommandSuccessContextualizedType, } from 'dmpconnectjsapp-base/actions';
import { apiSections }                                                               from 'dmpconnectjsapp-base/constants';
import merge                                                                         from 'lodash.merge';
import { dmpconnectApplicationActionConstants, searchPanels, }                       from '../constants';
import { backPagesTypes }                                                            from '../helpers/nav';

const initialState = {
  urlProcessed                 : false,
  saasTokenOk                  : true,
  dashboardInitOk              : false,
  modalError                   : null,
  dmpSearchOpenedPanel         : searchPanels.ACCESS_BY_AVAILABLE_DMP_PANEL,
  openALDPopup                 : false,
  selectedALDVitaleIndex       : undefined,
  selectedALDIns               : undefined,
  showMssPopup                 : false,
  mssContent                   : {
    sender                         : '',
    recipients                     : '',
    cc                             : '',
    bcc                            : '',
    title                          : '',
    messageContent                 : '',
    isHtml                         : false,
    attachments                    : undefined,
    otherAttachments               : undefined,
    inReplyToMessageIds            : '',
    references                     : '',
    senderWording                  : '',
    messageId                      : '',
    sendInBackground               : false,
    modal                          : false,
    modalMessage                   : '',
    replyTo                        : '',
    fromRemoteControl              : false,
    notificationReceiver           : '',
    notificationReceiversType      : undefined,
    insiIdentity                   : undefined,
    AdditionalPatientIdentifiers   : undefined,
    insIsNotQualified              : false,
    ignorePdfA1Transparency        : false,
    disabledPdfA1Conversion        : false,
    disableIheXdmPdfTitlePage      : false,
    disableIheXdmPdfDataMatrixBlock: false,
  },
  backPages                    : {},
  vitaleCardSerialNumber       : null,
  apCvContextToDestroy         : false,
  mssDownloadedAttachment      : {},
  showLoadingBackdrop          : false,
  loadingBackdropMessage       : '',
  gettingLogs                  : false,
  showLogsPopup                : false,
  logsPopupError               : undefined,
  logsPopupOnClose             : undefined,
  submitEngine                 : undefined,
  mobilePreviewActive          : false,
  loginTab                     : undefined,
  messageEventLoggedInEvtSent  : false,
  timeSynchronizationDifference: 0,
};

export function dmpconnectApplication(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...state, urlProcessed: action.payload.action === 'POP' ? false : state.urlProcessed };
    case dmpconnectApplicationActionConstants.DMPS_SET_URL_PROCESSED:
      return { ...state, urlProcessed: action.processed };
    case dmpconnectApplicationActionConstants.DMPC_RESET_APPLICATION:
      return initialState;
    case dmpconnectApplicationActionConstants.DMPC_SET_DMP_SEARCH_PANEL_OPENED:
      return merge({}, state, { dmpSearchOpenedPanel: action.panel });
    case dmpconnectApplicationActionConstants.DMPC_SET_MODAL_ERROR: {
      const { modalError, ...newState } = state;
      return merge({}, newState, { modalError: action.modalError });
    }
    case dmpconnectApplicationActionConstants.DMPC_CLEAR_MODAL_ERROR: {
      const { modalError, ...newState } = state;
      return { ...newState, modalError: null };
    }
    case dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT:
      return merge({}, state, { dashboardInitOk: false });
    case dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT_OK:
      return merge({}, state, { dashboardInitOk: action.status });
    case dmpconnectApplicationActionConstants.DMPC_ALD_POPUP_OPENED:
      return merge({}, state, {
        openALDPopup          : action.opened,
        selectedALDVitaleIndex: action.vitaleIndex,
        selectedALDIns        : action.ins,
      });
    case dmpconnectApplicationActionConstants.DMPC_DP_POPUP_OPENED:
      return merge({}, state, {
        openDPPopup          : action.opened,
        selectedDPVitaleIndex: action.vitaleIndex,
        selectedDPIns        : action.ins,
      });
    case dmpconnectApplicationActionConstants.DMPC_MSS_POPUP_OPENED:
      return merge({}, state, { showMssPopup: action.show });
    case dmpconnectApplicationActionConstants.DMPC_MSS_RESET_EMAIL_CONTENT:
      return {
        ...state,
        mssContent: {
          ...initialState.mssContent,
          forcedIns: !!action.forcedIns,
        },
      };
    case dmpconnectApplicationActionConstants.DMPC_MSS_SET_EMAIL_CONTENT:
      return merge({}, state, { mssContent: action.content });
    case dmpconnectApplicationActionConstants.DMPC_MSS_ADD_EMAIL_ATTACHEMENT:
      return merge({}, state, {
        mssContent: {
          ...state.mssContent,
          attachments: [...state.mssContent.attachments || [], action.document],
        },
      });
    case dmpconnectApplicationActionConstants.DMPC_SAAS_TOKEN_VALIDATED: {
      return merge({}, state, { saasTokenOk: action.validated });
    }
    case dmpconnectApplicationActionConstants.DMPC_SET_MODAL_CONFIRMATION: {
      return merge({}, state, { modalConfirmation: action.modalConfirmation });
    }
    case dmpconnectApplicationActionConstants.DMPC_SET_MODAL_INFORMATION: {
      return merge({}, state, { modalInformation: action.modalInformation });
    }
    case dmpconnectApplicationActionConstants.DMPC_SHOW_UPLOAD_DOCUMENT_MODAL: {
      return merge({}, state, { modalUpload: { ...action } });
    }
    case dmpconnectApplicationActionConstants.DMPC_SET_BACK_PAGE: {
      return merge({}, state, {
        backPages: {
          [action.backType]        : action.backPage,
          [backPagesTypes.PREVIOUS]: action.backPage,
        },
      });
    }
    case dmpconnectApplicationActionConstants.SET_VITALE_CARD_SERIAL_NUMBER: {
      return merge({}, state, { vitaleCardSerialNumber: action.serialNumber });
    }
    
    case dmpCommandSuccessContextualizedType(apiSections.GET_APCV_CONTEXT): {
      return merge({}, state, { apCvContextToDestroy: true });
    }
    case dmpCommandFailureContextualizedType(apiSections.GET_APCV_CONTEXT):
    case dmpCommandSuccessContextualizedType(apiSections.RELEASE_APCV_CONTEXT):
      return merge({}, state, { apCvContextToDestroy: false });
    
    case dmpconnectApplicationActionConstants.DMPC_API_LOGIN_CHECK_VALUES:
      return merge({}, state, { apiLoginCheckValues: action.values });
    
    case dmpconnectApplicationActionConstants.SHOW_LOADING_BACKDROP:
      return merge({}, state, {
        showLoadingBackdrop   : action.showLoadingBackdrop,
        loadingBackdropMessage: action.loadingBackdropMessage,
      });
    
    case dmpconnectApplicationActionConstants.SET_IS_FIRST_LOGIN:
      return merge({}, state, { isFirstLogin: action.value });
    
    case dmpconnectApplicationActionConstants.GOT_LOGS:
      return merge({}, state, { gettingLogs: false });
    case dmpconnectApplicationActionConstants.GET_LOGS:
      return merge({}, state, { gettingLogs: true });
    case dmpconnectApplicationActionConstants.SHOW_LOGS_POPUP:
      return merge({}, state, {
        showLogsPopup   : action.show,
        logsPopupError  : action.error,
        logsPopupOnClose: action.onClose,
      });
    
    case dmpconnectApplicationActionConstants.SET_SUBMIT_ENGINE:
      return merge({}, state, { submitEngine: action.submitEngine });
    
    case dmpconnectApplicationActionConstants.SET_LOGIN_TAB:
      return merge({}, state, { loginTab: action.loginTab });
    
    case dmpconnectApplicationActionConstants.SET_FORCED_CPX_READER:
      return { ...state, forcedCpxReader: action.readerName };
    case dmpconnectApplicationActionConstants.SET_FORCED_VITALE_READER:
      return { ...state, forcedVitaleReader: action.readerName };
    case dmpconnectApplicationActionConstants.SET_MOBILE_PREVIEW_ACTIVE:
      return merge({}, state, { mobilePreviewActive: action.active });
    case dmpconnectApplicationActionConstants.MESSAGE_EVENT_LOGGED_IN_EVT_SENT:
      return { ...state, messageEventLoggedInEvtSent: action.sent };
    case dmpCommandSuccessContextualizedType(apiSections.GET_TIME_SYNCHRONIZATION_DIFFERENCE):
      return { ...state, timeSynchronizationDifference: action.data.f_timeSynchronizationDifference };
    case dmpCommandSuccessContextualizedType(apiSections.SESSION_SECTION):
      if (action.data.f_timeSynchronizationDifference) {
        return { ...state, timeSynchronizationDifference: action.data.f_timeSynchronizationDifference };
      } else {
        return state;
      }
    default:
      return state;
  }
}
