import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';
import { dateRangePresets } from '../../../../dmpconnect/constants';

const prepareHandleChange = (event, handleChange) => {
  const {
    target: {
      dataset: {
        target, from, to, code,
      },
    },
  } = event;
  handleChange(target, from, to, code);
};

export default function DateRangePresetBar({
  handleChange, target, activePreset, size,
}) {
  return (
    <ButtonGroup>
      <Button
        key="bar-date-range-preset-default"
        size={size}
        variant="sand"
        data-code="default"
        data-from=""
        data-to=""
        data-target={target}
        onClick={event => prepareHandleChange(event, handleChange)}
        active={activePreset === 'default'}
      >
        Toujours
      </Button>
      {dateRangePresets.filter(preset => preset.bar === true).map(preset => (
        <Button
          key={`bar-date-range-preset-${preset.code}`}
          size={size}
          variant="sand"
          data-code={preset.code}
          data-from={preset.from}
          data-to={preset.to}
          data-target={target}
          onClick={event => prepareHandleChange(event, handleChange)}
          active={activePreset === preset.code}
        >
          {preset.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

DateRangePresetBar.propTypes = {
  handleChange: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
  activePreset: PropTypes.string,
  size: PropTypes.string,
};

DateRangePresetBar.defaultProps = {
  activePreset: 'default',
  size: '',
};
