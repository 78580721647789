import Dropzone from 'react-dropzone';
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { documentUploadSchema, initialValues } from '../../dmpconnect/helpers/sendDocument';
import DMPDocumentUpload from '../DMPDocument/DMPDocumentUpload';
import getIsMobileFromState from '../../dmpconnect/helpers/isMobile';
import { getFullName } from '../../dmpconnect/helpers/accessibleDMPList';
import TitleTooltip from '../Common/TitleTooltip';
import { documentFormatNames } from '../../dmpconnect/constants';

const MssDocumentSelector = ({
  id, onDrop, onDelete, files, fileNameTransfo, disabled, patientInfos, ins, isMobile,
  i_cumulMasquePsPatient, i_minorFunctionManagement,
}) => {
  const uploadForm = React.useRef();
  const title = React.useMemo(() => {
    if (patientInfos) {
      return `Ajouter une pièce jointe concernant ${getFullName(patientInfos.s_patientName, patientInfos.s_patientFirstBirthGiven, patientInfos.s_patientBirthName)}`;
    }
    return 'Sélectionner une PJ';
  }, [patientInfos]);

  return (
    <>
      <Dropzone
        onDrop={acceptedFiles => uploadForm.current.onDrop(acceptedFiles)}
        disabled={disabled}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="rbt-input-multi form-control rbt-input custom-input-file"
            id={id}
          >
            <div className="rbt-input-wrapper">
              <input {...getInputProps()} />
              {files.map((file, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TitleTooltip
                  id={`files_${id}_${index}_tooltip`}
                  text={(
                    <span>
                      {file.documentFilename}
                      <br />
                      {file.documentCategoryLabel || file.documentCategory}
                      <br />
                      {documentFormatNames[file.documentFormat]}
                    </span>
                  )}
                >
                  <div className="rbt-token rbt-token-removeable" key={`files_${id}_${index}`}>
                    {file.documentTitle}
                    <button
                      tabIndex="-1"
                      aria-label="Remove"
                      className="close rbt-close rbt-token-remove-button"
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onDelete(file, index);
                      }}
                    >
                      <span aria-hidden="true">×</span>
                      <span className="sr-only">Remove</span>
                    </button>
                  </div>
                </TitleTooltip>
              ))}
            </div>
          </div>
        )}
      </Dropzone>

      <Formik
        validationSchema={documentUploadSchema(i_cumulMasquePsPatient, i_minorFunctionManagement)}
        onSubmit={(values) => {
          onDrop(values);
          uploadForm.current.handleClose();
        }}
        initialValues={{ ...initialValues }}
        render={
          props => (
            <DMPDocumentUpload
              submitButtonLabel="Ajouter aux PJ"
              directAuthenticationDMPStatus={{ s_status: 'OK' }}
              isMobile={isMobile}
              asPopup={!isMobile}
              ref={uploadForm}
              noFileType
              onlyForm
              ins={ins}
              fullTitle={title}
              {...props}
            />
          )
        }
      />
    </>
  );
};

MssDocumentSelector.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  files: PropTypes.array,
  id: PropTypes.string.isRequired,
  fileNameTransfo: PropTypes.func,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  patientInfos: PropTypes.object.isRequired,
  ins: PropTypes.string.isRequired,
  i_cumulMasquePsPatient: PropTypes.number.isRequired,
  i_minorFunctionManagement: PropTypes.number.isRequired,
};

MssDocumentSelector.defaultProps = {
  files: [],
  fileNameTransfo: null,
  disabled: false,
  isMobile: false,
};
function mapStateToProps(state) {
  const {
    dmpconnectInteropCodes: {
      [apiSections.DMP_PARAMETERS]: {
        i_cumulMasquePsPatient,
        i_minorFunctionManagement,
      },
    },
  } = state;
  return {
    isMobile: getIsMobileFromState(state),
    i_cumulMasquePsPatient,
    i_minorFunctionManagement,
  };
}
export default connect(mapStateToProps)(MssDocumentSelector);
