import moment from 'moment';

/**
 * Validate stringDate
 * @param stringDate
 * @param formats
 * @param nullable
 * @returns {boolean}
 */
export const isDateValid = (stringDate, formats = ['DD/MM/YYYY'], nullable = true) => {
  if ((stringDate === null || stringDate === '') && nullable) return true;
  const date = moment(stringDate, formats, true);
  if (!date.isValid()) return false;
  return date.year() >= 1600 && date.year() <= 2100;
};

export const isHourValid = (stringHour, format = 'HH:mm:ss', nullable = true) => {
  if ((stringHour === null || stringHour === '') && nullable) return true;
  const hour = moment(stringHour, [format], true);
  return hour.isValid();
};
