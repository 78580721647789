import {
  call,
  put, select, take, takeEvery,
} from 'redux-saga/effects';
import { getInsiConfig } from 'dmpconnectjsapp-base/helpers/accessors';
import { authenticationTypes, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import {
  formatGetDp,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { dmpCommandFailureContextualizedType, dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';
import { stringSex } from '../constants/dmpConstants';
import { dpActionsConstants } from '../constants';
import { getAction, getInsFromVitaleCard } from '../actions';


const handleGetRecordStatus = function* (action) {
  const { context: { params: { ndp, origin }, getDpCallAfterSuccess = false } } = action;

  if (ndp && origin) {
    yield put(getAction(
      commands.dpDirectory_GetRecordStatus,
      apiSections.DP_GET_RECORD_STATUS,
      {
        s_ndp: ndp,
        i_ndpOrigin: origin,
      },
      {
        subSection: ndp,
        contextExtra: { getDpCall: getDpCallAfterSuccess },
      },
    ));
  }
};

const getInsiIdentotyFromState = (state, ndp) => {
  const {
    [apiSections.INS_FROM_VITALE_CARD]: {
      [ndp]: identity,
    } = {},
  } = state;

  return identity ? identity.Identity : undefined;
};
const getInsiIdentity = function* (infos) {
  const insiConfig = yield select(getInsiConfig);
  const { accessRights } = yield select(getAccessRightsProps);

  let identity = yield select(getInsiIdentotyFromState, infos.ndp);

  if (!identity) {
    if (insiConfig.active && accessRights.authenticationType === authenticationTypes.DIRECT) {
      yield put(getInsFromVitaleCard({
        ins2Patient: infos.vitaleIndex === undefined || infos.vitaleIndex === -1
          ? {
            name: infos.name,
            given: infos.given,
            birthday: infos.birthDay,
            sex: stringSex[infos.sexe],
          }
          : null,
        vitaleIndex: infos.vitaleIndex,
        dmpStatusCall: false,
        subSection: infos.ndp,
      }));

      const result = yield take([
        dmpCommandSuccessContextualizedType(apiSections.INS_FROM_VITALE_CARD),
        dmpCommandFailureContextualizedType(apiSections.INS_FROM_VITALE_CARD),
      ]);
      if (result.data.Identity) {
        identity = { ...result.data.Identity };
      }
    }
  }

  return identity || {};
};

export const getDpStatus = function* (action) {
  const { infos, consultType = 1 } = action;
  const identity = yield call(getInsiIdentity, infos);

  yield put(getAction(
    commands.dpGetMedicationDispensations,
    apiSections.GET_DP,
    formatGetDp(infos.ndp, 1, identity, consultType),
    {
      subSection: infos.ndp,
    },
  ));
};

export const getDpStatusAfterRecordSuccess = function* (action) {
  const { context: { subSection, vitaleIndex, getDpCall = true } } = action;
  if (getDpCall) {
    const infos = { vitaleIndex, ndp: subSection };
    const identity = yield call(getInsiIdentity, infos);

    yield put(getAction(
      commands.dpGetMedicationDispensations,
      apiSections.GET_DP,
      formatGetDp(subSection, 1, identity, 1),
      {
        subSection,
      },
    ));
  }
};

export const handleDpCallbacks = function* () {
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.DP_DELETE_RECORD), handleGetRecordStatus);
  yield takeEvery(dpActionsConstants.DMPC_GET_DP_STATUS, getDpStatus);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.DP_GET_RECORD_STATUS), getDpStatusAfterRecordSuccess);
};
