import { apiSections } from 'dmpconnectjsapp-base/constants';
import { dmpconnectVirtualPrinterActionConstants } from '../constants';
import { getSessionId } from '../helpers';

export const startVirtualPrinterMonitoring = () => (dispatch, getState) => (
  dispatch({
    type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_MONITORING_START,
    command: {
      s_commandName: 'hl_startPrintedDocumentsMonitoring',
      s_sessionId: getSessionId(getState()),
    },
  })
);
export const stopVirtualPrinterMonitoring = () => ({
  type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_MONITORING_STOP,
});

export const getVirtualPrinterFiles = () => (dispatch, getState) => (
  dispatch({
    type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SEND_COMMAND,
    command: {
      s_commandName: 'hl_getPrintedDocuments',
      s_sessionId: getSessionId(getState()),
    },
    context: {
      section: apiSections.VIRTUAL_PRINTER_FILES_SECTION,
    },
  })
);

export const flushVirtualPrinterFiles = ids => (dispatch, getState) => (
  dispatch({
    type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SEND_COMMAND,
    command: {
      s_commandName: 'hl_flushPrintedDocuments',
      s_sessionId: getSessionId(getState()),
      s_documentIds: ids,
    },
    context: {
      section: apiSections.VIRTUAL_PRINTER_FILES_SECTION,
    },
  })
);

export const toggleVirtualPrinterOverlay = () => ({
  type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_TOGGLE_OVERLAY,
});

export const clearVirtualPrinterSection = section => ({
  type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_CLEAR_SECTION,
  section,
});

export const dmpVirtualPrinterCommandFailure = (data, context, command, silentError = false) => ({
  type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_COMMAND_FAILURE,
  data,
  command,
  context,
  silentError,
});

export const dmpVirtualPrinterCommandFailureContextualizedType = section => `DMPC_VIRTUAL_PRINTER_COMMAND_${section.toUpperCase()}_FAILURE`;

export const dmpVirtualPrinterCommandSuccessContextualizedType = section => `DMPC_VIRTUAL_PRINTER_COMMAND_${section.toUpperCase()}_SUCCESS`;

export const dmpVirtualPrinterCommandFailureContextualized = (data, context, command) => ({
  type: dmpVirtualPrinterCommandFailureContextualizedType(context.section),
  data,
  command,
  context,
});

export const dmpVirtualPrinterCommandSuccess = (data, context, command) => ({
  type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_COMMAND_SUCCESS,
  data,
  command,
  context,
});

export const dmpVirtualPrinterCommandSuccessContextualized = (data, context, command) => ({
  type: dmpVirtualPrinterCommandSuccessContextualizedType(context.section),
  data,
  command,
  context,
});

export const sendVirtualPrinterDocumentProcess = params => (
  (dispatch, getState) => dispatch(
    {
      type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SEND_DOCUMENT,
      ...params,
    },
  )
);

export const setVirtualPrinterFileIns = ins => ({
  type: dmpconnectVirtualPrinterActionConstants.DMPC_VIRTUAL_PRINTER_SELECT_INS,
  ins,
});
