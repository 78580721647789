import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';


function InputDate({
  value, isValid, onChange, className, readOnly, size, placeholder, ...props
}) {
  let inputPlaceholder = 'JJ/MM/AAAA';
  if (readOnly) {
    inputPlaceholder = '';
  } else if (placeholder) {
    inputPlaceholder = placeholder;
  }
  return (
    <MaskedInput
      {...props}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      value={value}
      placeholder={inputPlaceholder}
      className={`form-control ${size && `form-control-${size}`} ${className} ${!isValid ? 'is-invalid' : ''}`}
      guide
      onChange={onChange}
      readOnly={readOnly}
    />
  );
}

InputDate.defaultProps = {
  isValid: true,
  onChange: () => {
  },
  value: '',
  className: '',
  size: '',
  readOnly: false,
  placeholder: undefined,
};

InputDate.propTypes = {
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputDate;
