import { mssActionConstants } from 'dmpconnectjsapp-base/constants';
import { mssActions }         from '../constants';

const initialState = {};

const addAccount = (state, psId, account) => {
  const
    {
      [psId]: {
        accounts = [],
        ...rest
      } = {},
      ...others
    } = state;
  
  return {
    [psId]: {
      accounts: [...accounts, account],
      ...rest
    },
    ...others
  }
}

const updateAccount = (state, psId, account) => {
  const
    {
      [psId]: {
        accounts = [],
        ...rest
      } = {},
      ...others
    } = state;
  
  return {
    [psId]: {
      accounts: accounts.map(a => a.id === account.id ? account : a),
      ...rest
    },
    ...others
  }
}

const deleteAccount = (state, psId, account) => {
  const
    {
      [psId]: {
        accounts = [],
        activeAccount,
        ...rest
      } = {},
      ...others
    } = state;
  
  const newAccounts = accounts.filter(a => a.id !== account.id);
  
  return {
    [psId]: {
      accounts: accounts.filter(a => a.id !== account.id),
      activeAccount: newAccounts.length > 0 ? newAccounts[0].id : undefined,
      ...rest
    },
    ...others
  }
}

const activateAccount = (state, psId, account = {}) => {
  const
    {
      [psId]: {
        activeAccount,
        ...rest
      } = {},
      ...others
    } = state;
  
  return {
    [psId]: {
      activeAccount: account.id,
      ...rest
    },
    ...others
  }
}

export function mssAccounts(state = initialState, action) {
  switch (action.type) {
    case mssActionConstants.ADD_MSS_ACCOUNT:
      return addAccount(state, action.psId, action.account);
    case mssActionConstants.UPDATE_MSS_ACCOUNT:
      return updateAccount(state, action.psId, action.account);
    case mssActionConstants.DELETE_MSS_ACCOUNT:
      return deleteAccount(state, action.psId, action.account);
    case mssActions.SET_MSS_ACCOUNT_ACTIVE:
      return activateAccount(state, action.psId, action.account);
    default:
      return state;
  }
}
