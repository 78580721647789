import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';

export default function DroppableNameCell({ text, ins, onDrop }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const insValide = ins !== 0 && ins !== '' && ins !== -1;

  return (
    <React.Fragment>
      <Dropzone
        noClick
        multiple={false}
        onDrop={(acceptedFiles) => { setShow(false); if (insValide) onDrop(acceptedFiles); }}
        onDragEnter={() => setShow(true)}
        onDragLeave={() => setShow(false)}
      >
        {({ getRootProps, getInputProps }) => (
          <section ref={target}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <span className="typography-big-text-title">{text}</span>
            </div>
          </section>
        )}
      </Dropzone>
      <Overlay target={target.current} show={show} placement="right">
        {props => (
          <Tooltip id="overlay-example" {...props}>
            {insValide ? (
              <>
                Déposer un document sur le DMP de
                {' '}
                {text}
              </>
            ) : (
              <>
                Impossible de déposer un document sur ce document. (INS Invalide)
              </>
            )}
          </Tooltip>
        )}
      </Overlay>
    </React.Fragment>
  );
}

DroppableNameCell.propTypes = {
  text: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  ins: PropTypes.string,
};

DroppableNameCell.defaultProps = {
  ins: '',
};
