import * as React from 'react';
import PropTypes from 'prop-types';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import EsConfigForm from './EsConfigForm';

const EsConfigCheck = ({
  configRequired,
  esLoginActive,
  loginType,
  es_id,
  esCertificate,
  children,
}) => {
  const configOk = (
    (es_id && es_id !== '')
    && (
      loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD
      || (esCertificate && esCertificate !== '')
    )
  );

  if (esLoginActive && configRequired && !configOk) {
    // display ES config form
    return (
      <Container className="login-container">
        <div className="centered-container">
          <EsConfigForm />
        </div>
      </Container>
    );
  }
  return children;
};

EsConfigCheck.propTypes = {
  configRequired: PropTypes.bool.isRequired,
  esLoginActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

function mapStateToProps(state) {
  const { dmpconnectESConfiguration } = state;

  return {
    configRequired: getConfigurationValue('configRequired', dmpconnectESConfiguration),
    esLoginActive: getConfigurationValue('esLoginActive', dmpconnectESConfiguration),
    loginType: getConfigurationValue('loginType', dmpconnectESConfiguration),
    es_id: getConfigurationValue('es_id', dmpconnectESConfiguration),
    esCertificate: getConfigurationValue('esCertificate', dmpconnectESConfiguration),
  };
}

const connectedEsConfigCheck = connect(mapStateToProps)(EsConfigCheck);
export default connectedEsConfigCheck;
