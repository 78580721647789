import * as React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';

const MobileListInput = ({
  options, id, name, title, onChange,
  optionsKey,
  optionLabel, optionValue,
  groupLabel,
  grouped,
  value,
}) => {
  const [show, setShow] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const getSelectedLabel = React.useCallback((selected) => {
    let flattenedOptions = options;
    if (grouped) {
      flattenedOptions = options.flatMap(group => group.types);
    }

    const selectedOption = flattenedOptions.find(option => option[optionValue] === selected);
    if (selectedOption) {
      return selectedOption[optionLabel];
    }

    return 'Sélectionner';
  }, [options, optionLabel, optionValue, grouped, optionsKey]);

  return (
    <>
      <Button
        variant="outline-secondary"
        className={`btn-select${selectedValue ? ' selected' : ''}`}
        onClick={() => setShow(true)}
      >
        {getSelectedLabel(selectedValue)}
        <i className="ic-chevron-down" />
      </Button>

      <Modal
        id={id}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={() => setShow(false)}
        className="modal-dropdown"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul role="listbox">
            {grouped ? (
              <>
                {options.map(group => (
                  <>
                    <li className="dropdown-header">
                      {group[groupLabel]}
                    </li>
                    {group[optionsKey]
                      .filter(opt => opt.valid === true)
                      .filter(opt => opt.label !== 'Note de vaccination')
                      .map(option => (
                        <li
                          className={`dropdown-item${selectedValue === option[optionValue] ? ' active' : ''}`}
                          role="option"
                          aria-selected={selectedValue === option[optionValue]}
                        >
                          <Button
                            variant="link"
                            onClick={() => setSelectedValue(option[optionValue])}
                          >
                            {option[optionLabel]}
                            <i className="ic-check ic-bg-primary" />
                          </Button>
                        </li>
                      ))}
                  </>
                ))}
              </>
            ) : (
              <>
                {options.filter(opt => opt.valid === true).map(option => (
                  <li
                    className={`dropdown-item${selectedValue === option[optionValue] ? ' active' : ''}`}
                    role="option"
                    aria-selected={selectedValue === option[optionValue]}
                  >
                    <Button
                      variant="link"
                      onClick={() => setSelectedValue(option[optionValue])}
                    >
                      {option[optionLabel]}
                      <i className="ic-check ic-bg-primary" />
                    </Button>
                  </li>
                ))}
              </>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="borderless-primary"
            onClick={() => setShow(false)}
          >
            Annuler
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              onChange({ target: { value: selectedValue, name } });
              setShow(false);
            }}
            disabled={!selectedValue}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

MobileListInput.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  optionsKey: PropTypes.string,
  optionLabel: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  groupLabel: PropTypes.string,
  grouped: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
MobileListInput.defaultProps = {
  options: [],
  groupLabel: '',
  optionsKey: '',
  grouped: false,
  value: '',
};

export default MobileListInput;
