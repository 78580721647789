import React from 'react';
import PropTypes from 'prop-types';
import { vitaleDataType } from 'dmpconnectjsapp-base/helpers/vitaleData';
import { getQualifiedIns as getQualifiedInsFromVitaleCard } from '../../dmpconnect/helpers/vitaleCard';
import INSiIdentityProvider from '../CertifiiedIdentity/INSiIdentityProvider';
import { getFullName } from '../../dmpconnect/helpers/accessibleDMPList';
import VitaleCardDate from '../Common/Date/VitaleCardDate';
import DMPStatusProvider from '../DMPStatus/DMPStatusProvider';
import DMPStatusActions from '../DMPStatus/DMPStatusActions';
import DMPStatusExtraActions from '../DMPStatus/DMPStatusExtraActions';
import SimpleTable from '../Common/Table/SimpleTable';
import { dmpStatuses } from '../../dmpconnect/constants';

function StatusIcon({
  // eslint-disable-next-line react/prop-types
  status, handleClick, ins, value,
}) {
  return (
    <>
      {status === dmpStatuses.DMPExist ? (
        <button
          type="button"
          className="btn btn-link btn-sm typography-big-text-title"
        // eslint-disable-next-line react/prop-types
          onClick={() => handleClick(ins)}
        >
          {value}
        </button>
      ) : (
        <span className="typography-big-text-title">{value}</span>
      )}
    </>
  );
}

const vitaleDataPatientToInsPatient = vitaleDataPatient => ({
  // {
  //   "i_birthRank": 1,
  //   "i_quality": 0,
  //   "s_birthday": "19671114",
  //   "s_birthdayYYYYMMDD": "19671114",
  //   "s_rawBirthdayYYYYMMDD": "19671114",
  //   "s_birthname": "INSFAMILLEQUATRE",
  //   "s_certifiedNir": "1671126221704 59",
  //   "s_given": "ARTHUR",
  //   "s_name": "INSFAMILLEQUATRE",
  //   "s_nir": "1671126221704 59",
  //   "i_sex": 2
  // },
  name: vitaleDataPatient.s_birthname,
  given: vitaleDataPatient.s_given,
  birthday: vitaleDataPatient.s_birthdayYYYYMMDD,
  sex: vitaleDataPatient.i_sex,
});

const getColumns = (
  accessRights,
  selector,
  vitaleCardStatus,
  handleClick,
  aldActive,
  showUploadForm,
  dataType,
  mssActive,
) => {
  let columns = [
    {
      Header: 'Patient',
      id: 'patientName',
      accessor: d => getFullName(d.s_name, d.s_given, d.s_birthname),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row, value }) => {
        const qualifiedInsFromVitaleCard = getQualifiedInsFromVitaleCard(row.original.s_nir,
          vitaleCardStatus);
        // eslint-disable-next-line react/prop-types
        const apiSubSection = `${qualifiedInsFromVitaleCard}_${row.index}`;
        return (
          <>
            {selector ? (
              <INSiIdentityProvider
                key={apiSubSection}
                // eslint-disable-next-line react/prop-types
                vitaleIndex={row.index}
                insFromVitaleCard={qualifiedInsFromVitaleCard}
                runOnMount={false}
                showLoading={false}
              >
                <DMPStatusProvider getOnMount={false} showLoading={false}>
                  <StatusIcon handleClick={handleClick} value={value} />
                </DMPStatusProvider>
              </INSiIdentityProvider>
            ) : (
              <span className="typography-big-text-title">{value}</span>
            )}
          </>
        );
      },
    },
    {
      Header: 'Né(e) le',
      id: 's_birthday',
      accessor: 's_birthday',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row, value }) => (
        <VitaleCardDate
          vitaleCardDate={value}
          // eslint-disable-next-line react/prop-types
          formattedVitaleCardDateYYYYMMDD={row.original.s_birthdayYYYYMMDD}
          className="typography-default-text-title"
        />
      ),
    },
    {
      id: 'dmp-access',
      Header: selector ? 'Statut' : 'Accès au dossier patient',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const qualifiedInsFromVitaleCard = getQualifiedInsFromVitaleCard(row.original.s_nir,
          vitaleCardStatus);
        // eslint-disable-next-line react/prop-types
        const apiSubSection = `${qualifiedInsFromVitaleCard}_${row.index}`;
        return (
          <INSiIdentityProvider
            key={apiSubSection}
            // eslint-disable-next-line react/prop-types
            vitaleIndex={row.index}
            insFromVitaleCard={qualifiedInsFromVitaleCard}
            ins2Patient={dataType === vitaleDataType.APCV_CONTEXT ? (
              vitaleDataPatientToInsPatient(row.original)
            ) : undefined}
            runOnMount
            showLoading
          >
            <DMPStatusProvider getOnMount>
              <DMPStatusActions
                accessDMP={ins => handleClick(ins, apiSubSection)}
                aldActive={aldActive}
                tlsSubSection={apiSubSection}
              />
            </DMPStatusProvider>
          </INSiIdentityProvider>
        );
      },
    },
    {
      id: 'actions',
      Header: 'Actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const qualifiedInsFromVitaleCard = getQualifiedInsFromVitaleCard(row.original.s_nir,
          vitaleCardStatus);
        // eslint-disable-next-line react/prop-types
        const apiSubSection = `${qualifiedInsFromVitaleCard}_${row.index}`;
        return (
          <INSiIdentityProvider
            key={apiSubSection}
            // eslint-disable-next-line react/prop-types
            vitaleIndex={row.index}
            insFromVitaleCard={qualifiedInsFromVitaleCard}
            runOnMount={false}
            showLoading
          >
            <DMPStatusProvider getOnMount={false} showError={false}>
              <DMPStatusExtraActions showUploadForm={ins => showUploadForm([], ins)} mssActive={mssActive} />
            </DMPStatusProvider>
          </INSiIdentityProvider>
        );
      },
    },
  ];

  if (selector) {
    columns = columns.filter(c => !['actions', 'dmp-access'].includes(c.id));
  }
  return columns;
};

const VitaleCardPatients = ({
  vitaleCardStatus,
  patients,
  handleClick,
  aldActive,
  showUploadForm,
  selector,
  accessRights,
  dataType,
  mssActive,
}) => {
  const columns = React.useMemo(() => getColumns(
    accessRights,
    selector,
    vitaleCardStatus,
    handleClick,
    aldActive,
    showUploadForm,
    dataType,
  ), []);
  return (
    <>
      <SimpleTable columns={columns} data={patients} striped />
    </>
  );
};

VitaleCardPatients.defaultProps = {
  vitaleCardStatus: null,
  patients: [],
  aldActive: false,
  selector: false,
  mssActive: false,
};

VitaleCardPatients.propTypes = {
  accessRights: PropTypes.object.isRequired,
  showUploadForm: PropTypes.func.isRequired,
  vitaleCardStatus: PropTypes.number,
  patients: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
  aldActive: PropTypes.bool,
  selector: PropTypes.bool,
  dataType: PropTypes.number.isRequired,
  mssActive: PropTypes.bool,
};

export default VitaleCardPatients;
