import {
  call, delay, put, take, takeEvery,
} from 'redux-saga/effects';
import { websocketChannel } from 'dmpconnectjsapp-base/sagas/connectorSagas';
import { softwareErrors } from 'dmpconnectjsapp-base/errors';
import {
  dmpRemoteCommandFailure,
  dmpRemoteCommandFailureContextualized,
  dmpRemoteCommandSuccess,
  dmpRemoteCommandSuccessContextualized,
} from 'dmpconnectjsapp-base/actions';
import { dmpconnectRemoteActionConstants } from '../constants';
import { errorTypes } from '../errors/errorConfiguration';
/**
 * Send asynchronous command to DMPConnectJS
 * @param action
 * @returns {IterableIterator<CallEffect|TakeEffect|PutEffect<{data, context, type, command}>>}
 */
const sendRemoteCommand = function* (action, retries = 0) {
  // const remoteControlActive = yield select(isRemoteControlActive);

  // if (remoteControlActive) {
  const { command, context, silentError } = action;
  try {
    const channel = yield call(websocketChannel, command, context);
    const { message } = yield take(channel);

    if (message.s_status !== 'OK') {
      yield put(dmpRemoteCommandFailure(message, context, command, silentError));
      yield put(dmpRemoteCommandFailureContextualized(message, context, command, silentError));
    } else {
      yield put(dmpRemoteCommandSuccess(message, context, command));
      yield put(dmpRemoteCommandSuccessContextualized(message, context, command));
    }
  } catch (e) {
    if (retries >= 5) {
      const wsError = {
        i_apiErrorType: errorTypes.SoftwareErrors,
        i_apiErrorCode: softwareErrors.WEBSOCKET_ERROR,
      };
      yield put(dmpRemoteCommandFailure(wsError, context, command, silentError));
      yield put(dmpRemoteCommandFailureContextualized(wsError, context, command, silentError));
    } else {
      console.log('ws error, retry');
      yield delay(1000);
      yield call(sendRemoteCommand, action, retries + 1);
    }
  }
  // }
};

export const handleRemoteCommand = function* () {
  yield takeEvery(dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND, sendRemoteCommand);
};
