import PropTypes from 'prop-types';
import * as React from 'react';
import { stepsStatus } from '../../../dmpconnect/constants/statuses';
import Loading from '../Loading/Loading';
import Alert from '../Message/Alert';
import ErrorMessage from '../Message/ErrorMessage';

const StepStatuses = ({
  step, successMessage, loadingMessage, errorMessage,
}) => (
  <>
    {step && (
    <>
      {step.status === stepsStatus.loading && (
        <Loading size="sm" message={loadingMessage} />
      )}
      {step.status === stepsStatus.success && (
        <Alert type="success">{successMessage}</Alert>
      )}
      {step.status === stepsStatus.error && (
        <>
          {step.error ? (
            <ErrorMessage error={step.error} message={errorMessage} showDetails />
          ) : (
            <Alert type="danger">{errorMessage}</Alert>
          )}
        </>
      )}
    </>
    )}
  </>
);

StepStatuses.propTypes = {
  step: PropTypes.object,
  successMessage: PropTypes.string.isRequired,
  loadingMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
StepStatuses.defaultProps = {
  step: undefined,
};

export default StepStatuses;
