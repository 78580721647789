import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/fr';
import '../utils/formUtils';

export const getUserFilter = (state, psId, filterKey) => {
  const {
    dmpconnectFilters: {
      usersFilters: {
        [psId]: {
          [filterKey]: filter = undefined,
        } = {},
      } = {},
      [psId]: {
        [filterKey]: oldFilter = undefined,
      } = {},
    } = {},
  } = state;

  return filter !== undefined ? filter : oldFilter;
};
export const getCurrentFilter = (state, filterKey, defaultValue) => {
  const {
    dmpconnectFilters: {
      [filterKey]: filter = undefined,
    } = {},
  } = state;

  return filter !== undefined ? filter : defaultValue;
};

export const getDocumentsDefaultTypeCodesFilter = (state) => {
  const {
    dmpconnectFilters: {
      defaultDocumentsTypeCodes,
    } = {},
  } = state;

  return defaultDocumentsTypeCodes;
};

export const formatFindPatientsParams = values => ({
  s_name: values.name ? values.name : '',
  s_givenName: values.givenName ? values.givenName : '',
  s_city: values.city ? values.city : '',
  s_postalCode: values.postalCode ? values.postalCode : '',
  s_birthday: values.birthday
    ? moment.utc(values.birthday, ['DD/MM/YYYY'], true).format('YYYYMMDD')
    : '',
  i_sex: values.sex ? Number(values.sex) : 1,
  i_approxName: values.approxName ? Number(values.approxName) : 0,
  i_approxCity: values.approxCity ? Number(values.approxCity) : 0,
});

export const findPatientsParamsSchema = yup.object({
  name: yup.string().DMPCName(80),
  givenName: yup.string().DMPCName(60),
  birthday: yup.string().date().pastDate(),
  city: yup.string().DMPCAdress(38),
  postalCode: yup.string().DMPCMaxLength(10),
  sex: yup.number(),
  approxName: yup.boolean(),
  approxCity: yup.boolean(),
  enoughParams: yup.bool().test(
    'enoughParams',
    'Vous devez remplir au moins un critère de recherche',
    function () {
      return (
        (this.parent.name && this.parent.name !== '')
        || (this.parent.givenName && this.parent.givenName !== '')
        || (this.parent.birthday && this.parent.birthday !== '')
        || (this.parent.city && this.parent.city !== '')
        || (this.parent.postalCode && this.parent.postalCode !== '')
        || (this.parent.sex && this.parent.sex > 1)
      );
    },
  ),
});

export const findPatientsDefaultValues = {
  name: '',
  givenName: '',
  birthday: '',
  city: '',
  postalCode: '',
  sex: 0,
  approxName: false,
  approxCity: false,
};

export const existsInText = (text, search) => search.trim().split(' ').every((part) => {
  const regexp = new RegExp(part, 'i');
  return regexp.test(text);
});

export const existInObject = (search, object) => Object.keys(object).some((key) => {
  if (object[key]) {
    const searchAsRegEx = new RegExp(search, 'i');
    if (moment.isMoment(object[key])) {
      return searchAsRegEx.test(object[key].format('YYYYMMDDHHmmss'));
    }
    if (typeof object[key] === 'object') {
      return existInObject(search, object[key]);
    }
    if (typeof object[key] === 'string') {
      return searchAsRegEx.test(object[key]);
    }
  }

  return false;
});
