import * as React from 'react';
import { connect } from 'react-redux';
import { apiSections, DmpcJsLogLevel, DmpcLogLevels } from 'dmpconnectjsapp-base/constants';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { getApiType } from 'dmpconnectjsapp-base/helpers/accessors';
import { areLogsDetailed } from '../../../dmpconnect/helpers/logs';
import {
  getLogs, setLogLevels, showLogsPopup,
} from '../../../dmpconnect/actions';
import ButtonWithLoader from '../Form/ButtonWithLoader';
import CustomCheckbox from '../Form/Input/CustomCheckbox';
import { API_TYPES } from '../../../dmpconnect/constants';

const GetLogsButton = ({
  detailedLogs,
  showPopup,
  dispatch,
  gettingLogs,
  logsPopupError,
  logsPopupOnClose,
  setLogLevelsSection,
  apiType,
}) => {
  const [isGettingLogs, setIsGettingLogs] = React.useState(false);
  const [isSettingLogs, setIsSettingLogs] = React.useState(false);
  const [keepLogLevel, setKeepLogLevel] = React.useState(false);

  const onClose = React.useCallback(() => {
    if (logsPopupOnClose) {
      logsPopupOnClose();
    }
    dispatch(showLogsPopup(false, undefined, undefined));
  }, [logsPopupOnClose, dispatch]);

  React.useEffect(() => {
    if (!isGettingLogs && gettingLogs) {
      setIsGettingLogs(true);
    } else if (isGettingLogs && !gettingLogs) {
      setIsGettingLogs(false);
      onClose();
    }
  }, [gettingLogs, isGettingLogs, onClose]);

  React.useEffect(() => {
    if (!isSettingLogs && isLoading(setLogLevelsSection)) {
      setIsSettingLogs(true);
    } else if (isSettingLogs && (isReady(setLogLevelsSection) || hasError(setLogLevelsSection))) {
      setIsSettingLogs(false);
      onClose();
    }
  }, [
    isLoading(setLogLevelsSection),
    isSettingLogs,
    onClose,
    isReady(setLogLevelsSection),
    hasError(setLogLevelsSection),
  ]);

  if (apiType === API_TYPES.REST) {
    return <></>;
  }

  return (
    <Modal
      show={showPopup}
      centered
      backdrop="static"
      onHide={() => onClose()}
    >
      <Modal.Header className="bg-sand" closeButton>
        <Modal.Title as="h5">
          {detailedLogs
            ? 'Télécharger les journaux'
            : 'Les journaux détaillés sont désactivés'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="typography-big-text">
        {detailedLogs ? (
          <>
            Cliquez sur le bouton ci-dessous pour récupérer une archive zip à transmettre au support.
            <br />
            <br />
            <CustomCheckbox
              checked={keepLogLevel}
              id="rest-log-level-checkbox"
              onChange={event => setKeepLogLevel(event.target.checked)}
              label="Ne pas réinitialiser le niveau de verbosité des journaux"
              showLabel
            />
            <div className="text-center mt-2">
              <ButtonWithLoader
                size="sm"
                label="Télécharger les journaux"
                loadingLabel="Téléchargement"
                className="width-auto"
                loading={gettingLogs}
                onClick={() => dispatch(getLogs(logsPopupError, !keepLogLevel))}
              />
            </div>
          </>
        ) : (
          <>
            Les journaux détaillés sont plus verbeux, et permettent de mieux diagnostiquer les problèmes.
            <br />
            <br />
            Nous vous suggérons de les activer, puis de reproduire l&apos;erreur, si possible.
            <div className="text-center mt-2 mb-2">
              <ButtonWithLoader
                size="sm"
                className="width-auto"
                label="Activer les journaux verbeux et rejouer l'erreur"
                loading={isLoading(setLogLevelsSection)}
                loadingLabel="Activation des journaux verbeux"
                onClick={() => dispatch(setLogLevels(DmpcLogLevels.DEV, DmpcJsLogLevel.DEBUG))}
              />
            </div>

            Sinon, vous pouvez récupérer les journaux en cliquant sur le bouton ci-dessous.
            <div className="text-center mt-2">
              <ButtonWithLoader
                size="sm"
                label="Télécharger tout de même"
                loadingLabel="Téléchargement"
                className="width-auto"
                loading={gettingLogs}
                onClick={() => dispatch(getLogs(logsPopupError, false))}
              />
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

GetLogsButton.propTypes = {
  apiType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  detailedLogs: PropTypes.bool,
  gettingLogs: PropTypes.bool,
  showPopup: PropTypes.bool,
  logsPopupError: PropTypes.array,
  setLogLevelsSection: PropTypes.object,
  logsPopupOnClose: PropTypes.func,
};
GetLogsButton.defaultProps = {
  gettingLogs: false,
  detailedLogs: false,
  showPopup: false,
  logsPopupError: undefined,
  logsPopupOnClose: undefined,
  setLogLevelsSection: undefined,
};

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.GET_LOG_LEVELS]: logLevelsSection,
      [apiSections.SET_LOG_LEVELS]: setLogLevelsSection,
    },
    dmpconnectApplication: {
      showLogsPopup: showPopup,
      logsPopupError,
      logsPopupOnClose,
      gettingLogs,
    },
  } = state;

  return {
    apiType: getApiType(state),
    detailedLogs: areLogsDetailed(logLevelsSection),
    setLogLevelsSection,
    showPopup,
    gettingLogs,
    logsPopupError,
    logsPopupOnClose,
  };
}

export default connect(mapStateToProps)(GetLogsButton);
