import React, { Component } from 'react';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  formatCreateApCvConnector,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  getApiType, getConfigurationValue,
  getCpxCode,
  getPracticeLocationSettingFromState,
  getPracticeSetting,
} from 'dmpconnectjsapp-base/helpers/accessors';

import { authenticationTypes, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import { getAction } from '../../dmpconnect/actions';
import Loading from '../Common/Loading/Loading';
import { API_TYPES } from '../../dmpconnect/constants';

class ApCVConnector extends Component {
  componentDidMount() {
    const {
      dispatch,
      connector,
      serverName,
      cpxCode,
      practiceSetting,
      practiceLocationSetting,
      apiType,
      active,
      authenticationType,
      cpxLoginType,
    } = this.props;
    if (
      authenticationType === authenticationTypes.DIRECT
      && active
      && apiType !== API_TYPES.REST && !isReady(connector)
    ) {
      dispatch(getAction(
        commands.createApCVConnector,
        apiSections.APCV_CONNECTOR_SECTION,
        formatCreateApCvConnector(
          cpxCode,
          serverName,
          practiceSetting,
          practiceLocationSetting,
          cpxLoginType,
        ),
      ));
    }
  }

  render() {
    const {
      connector, children, apiType, authenticationType, active,
    } = this.props;

    if (
      authenticationType === authenticationTypes.DIRECT
      && active
      && apiType !== API_TYPES.REST && !isReady(connector)
    ) {
      return <Loading message="chargement" />;
    }
    return children;
  }
}

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.APCV_CONNECTOR_SECTION]: connector,
    },
    dmpconnectApCvConfiguration: {
      serverName,
      active,
    },
    dmpConnectPersistedAppConfiguration,
  } = state;
  const { accessRights: { authenticationType } } = getAccessRightsProps(state);


  return {
    active,
    apiType: getApiType(state),
    connector,
    serverName,
    cpxCode: getCpxCode(state),
    practiceSetting: getPracticeSetting(state),
    practiceLocationSetting: getPracticeLocationSettingFromState(state),
    authenticationType,
    cpxLoginType: getConfigurationValue('cpxLoginType', dmpConnectPersistedAppConfiguration),
  };
}

ApCVConnector.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  connector: PropTypes.object,
  serverName: PropTypes.string,
  active: PropTypes.bool,
  cpxCode: PropTypes.string,
  practiceSetting: PropTypes.string,
  practiceLocationSetting: PropTypes.string,
  apiType: PropTypes.string.isRequired,
  authenticationType: PropTypes.number,
  cpxLoginType: PropTypes.string,
};

ApCVConnector.defaultProps = {
  children: null,
  active: false,
  connector: {},
  serverName: '',
  cpxCode: '',
  practiceSetting: '',
  practiceLocationSetting: '',
  authenticationType: null,
  cpxLoginType: undefined,
};


const ConnectedApCVConnector = connect(mapStateToProps)(ApCVConnector);

export default ConnectedApCVConnector;
