import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import InformationPrompt from '../Modal/InformationPrompt';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  toggleShow() {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  render() {
    const { show } = this.state;
    const message = (
      <div>
        <h3>Efficience</h3>
        <p>
          Efficience version 2 est homologu&eacute; en date du 18/06/2021 sur les profils
          Cr&eacute;ation et Gestion Administrative du dossier, Consultation et Alimentation, en
          authentification directe par CPS et CPE et en authentification indirecte pour les &eacute;tablissements de santé.
        </p>
        <p>Les fonctionnalités intégrées sont : </p>
        <ul>
          <li>
            Pour le profil transverse &quot;Acc&egrave;s sécurisé au DMP d&apos;un patient&quot;:
            <ul>
              <li>TD 0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.9</li>
            </ul>
          </li>
          <li>
            Pour le profil &quot;Cr&eacute;ation et gestion administrative du DMP d&apos;un patient&quot; :
            <ul>
              <li>TD 1.1, 1.2, 1.3, 1.4, 1.5, 1.6</li>
            </ul>
          </li>
          <li>
            Pour le profil &quot;Alimentation du DMP d&apos;un patient&quot; :
            <ul>
              <li>TD 2.1, 2.2</li>
            </ul>
          </li>
          <li>
            Pour le profil &quot;Consultation du DMP d&apos;un patient&quot;
            <ul>
              <li>TD 3.1, 3.2, 3.3</li>
            </ul>
          </li>
        </ul>
        <p>
          Il n&apos;y a pas de fonctionnalit&eacute; non implément&eacute;e (la totalit&eacute; des transactions DMP
          sont int&eacute;gr&eacute;es).
        </p>

        <p>Efficience utilise la biblioth&egrave;que DmpConnect, EAI &eacute;dit&eacute; par la soci&eacute;t&eacute; icanop&eacute;e.</p>
        <p>
          DmpConnect est homologu&eacute; en mode EAI pour les profils et modes d&apos;acc&egrave;s suivants
          :
        </p>
        <p>En date du 30 Novembre 2023 :</p>
        <ul>
          <li>
            En Authentification
            {' '}
            <strong className="font-weight-bold">directe</strong>
            {' '}
            par
            {' '}
            <strong className="font-weight-bold">CPS et CPE</strong>
            {' '}
            pour les profils:
            {' '}
            <strong className="font-weight-bold">
              Cr&eacute;ation et Gestion Administrative du Dossier, Alimentation et Consultation
            </strong>
          </li>
          <li>
            En Authentification
            {' '}
            <strong className="font-weight-bold">indirecte</strong>
            {' '}
            pour les &eacute;tablissements de sant&eacute;, pour les profils:
            {' '}
            <strong className="font-weight-bold">
              Cr&eacute;ation et Gestion Administrative du Dossier, Alimentation et Consultation
            </strong>
          </li>
        </ul>
      </div>
    );

    return (
      <>
        <Button variant="link" onClick={() => this.toggleShow()} className="btn-unstyled typography-small-link">À Propos</Button>
        <InformationPrompt
          show={show}
          message={message}
          handleClose={() => this.toggleShow()}
          title="À propos"
        />
      </>
    );
  }
}


export default About;
