import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInteropCodesFromState } from 'dmpconnectjsapp-base/helpers/accessors';
import PsNameWithOverlay from '../PS/PsNameWithOverlay';

const HRPersonMiniCard = ({ person, specialties }) => {
  const {
    person: {
      name, given,
    } = {},
    professionCode,
    professionLabel,
    specialty,
    rpps,
    formation,
    es: { name: esName } = {},
  } = person;

  if (!rpps && !name && !given) return null;

  const specialtyObj = specialties.find(s => s.code === specialty);

  return (
    <div className="d-flex align-items-center justify-content-start">
      {professionCode && (
      <span className={`mr-1 badge-profession badge-profession-${professionCode}-${formation ? '2' : '1'}`} />
      )}
      <PsNameWithOverlay
        name={name}
        given={given}
        profession={professionLabel}
        professionOid={professionCode}
        professionDesc={professionLabel}
        specialty={specialtyObj && specialtyObj.label}
        rpps={rpps}
        institution={esName}
        id={rpps}
      />
    </div>
  );
};

HRPersonMiniCard.propTypes = {
  person: PropTypes.object,
  specialties: PropTypes.array,
};
HRPersonMiniCard.defaultProps = {
  person: {},
  specialties: [],
};

function mapStateToProps(state) {
  return {
    specialties: getInteropCodesFromState(state, 'physicianSpecialities'),
  };
}

export default connect(mapStateToProps)(HRPersonMiniCard);
