import * as React from 'react';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatGetMssHpInfosParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAction, setMssEmailContent, setShowMssPopup } from '../../dmpconnect/actions';
import Loading from '../Common/Loading/Loading';
import { isMssActive } from '../../dmpconnect/helpers';

const MssPsOverlayFooter = ({
  dispatch,
  name,
  given,
  rpps,
  getHpSection,
  mssActive,
  emailList,
  email,
  disableGetMssHpInfosOutsideMssEditor,
}) => {
  const [emails, setEmails] = React.useState(emailList);
  React.useEffect(() => {
    if (
      !disableGetMssHpInfosOutsideMssEditor
      && mssActive
      && rpps
      && emailList.length === 0
      && (
        !email
        || (!isLoading(getHpSection) && !isReady(getHpSection) && !hasError())
      )
    ) {
      // dispatch(clearSubSection(apiSections.MSS_GET_HP_INFOS, rpps));

      dispatch(getAction(
        commands.getMssHpInfos,
        apiSections.MSS_GET_HP_INFOS,
        formatGetMssHpInfosParams(
          name,
          given,
          rpps,
        ),
        {
          subSection: rpps,
          silentError: true,
        },
      ));
    }
  }, [name, given, rpps, mssActive, disableGetMssHpInfosOutsideMssEditor]);

  React.useEffect(() => {
    setEmails(emailList);
  }, [emailList]);


  if (!isLoading(getHpSection) && !email && emails.length === 0) {
    return null;
  }

  return (
    <>
      {isLoading(getHpSection) ? (
        <Loading size="sm" />
      ) : (
        <>
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary mr-2"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              dispatch(setMssEmailContent({
                recipients: email || emails.join(';'),
              }));
              dispatch(setShowMssPopup(true));
            }}
          >
            <i className="ic-send-message" style={{ fontSize: '1rem' }} />
            Envoyer un message
          </button>
          <i className="ic-mss small" />
        </>
      )}
    </>
  );
};

MssPsOverlayFooter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  rpps: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  given: PropTypes.string.isRequired,
  email: PropTypes.string,
  getHpSection: PropTypes.object.isRequired,
  mssActive: PropTypes.bool,
  disableGetMssHpInfosOutsideMssEditor: PropTypes.bool,
  emailList: PropTypes.array,
};

MssPsOverlayFooter.defaultProps = {
  disableGetMssHpInfosOutsideMssEditor: false,
  mssActive: false,
  email: '',
  emailList: [],
};

function mapStateToProps(state, props) {
  const { rpps } = props;
  const {
    dmpconnect: {
      [apiSections.MSS_GET_HP_INFOS]: {
        [rpps]: getHpSection = {},
      } = {},
    },
          dmpConnectPersistedAppConfiguration: {
            disableGetMssHpInfosOutsideMssEditor,
          }
  } = state;

  let emailList = [];
  if (isReady(getHpSection)) {
    emailList = [...new Set(getHpSection.PSList.map(({ s_mail }) => s_mail)).values()];
  }

  return {
    emailList,
    getHpSection,
    mssActive: isMssActive(state),
    disableGetMssHpInfosOutsideMssEditor,
  };
}

export default connect(mapStateToProps)(MssPsOverlayFooter);
