import * as React from 'react';
import PropTypes from 'prop-types';
import { dmpStatuses, userAuthorizationStatuses } from '../../dmpconnect/constants';
import TitleTooltip from '../Common/TitleTooltip';
import DmpAuthorization, { displayModes } from '../DMPAuthorization/DMPAuthorization';

const DmpAccessButton = ({
  noButton,
  authorization,
  ins, status, accessDMP,
  isTreatingPhysician,
}) => (
  <>
    {status === dmpStatuses.DMPExist && (
    <>
      {authorization === userAuthorizationStatuses.AuthorizationExist ? (
        <>
          {noButton ? (
            <>
              {isTreatingPhysician ? (
                <i className="ic-dmp-gp-on" />
              ) : (
                <i className="ic-dmp-simple-access" />
              )}
            </>
          ) : (
            <TitleTooltip
              id={`dmp-access-${ins}`}
              text="Accéder au dossier"
            >
              <button
                type="button"
                className="btn btn-sand btn-sm"
                onClick={() => accessDMP(ins)}
              >
                {isTreatingPhysician ? (
                  <i className="ic-dmp-gp-on" />
                ) : (
                  <i className="ic-dmp-simple-access" />
                )}
                DMP
              </button>
            </TitleTooltip>
          )}
        </>
      ) : (
        <>
          {noButton ? (
            <i className="ic-dmp-no-autorisation" />
          ) : (
            <>
              {(
                authorization === userAuthorizationStatuses.AuthorizationExpired
                  || authorization === userAuthorizationStatuses.NoAuthorization
              ) && (
              <DmpAuthorization
                displayMode={displayModes.NORMAL}
                ins={ins}
              />
              )}
            </>
          )}
        </>
      )}
    </>
    )}
  </>
);
DmpAccessButton.propTypes = {
  status: PropTypes.number,
  authorization: PropTypes.number,
  isTreatingPhysician: PropTypes.bool,
  ins: PropTypes.string,
  accessDMP: PropTypes.func,
  noButton: PropTypes.bool,
};

DmpAccessButton.defaultProps = {
  status: null,
  authorization: null,
  ins: null,
  accessDMP: null,
  isTreatingPhysician: false,
  noButton: false,
};

export default DmpAccessButton;
