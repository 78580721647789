import React from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';

export default function SuccessMessage({ title, message }) {
  return (
    <Alert type="success">
      <strong>{title}</strong>
      {message && (
        <div className="mb-0">
          {message}
        </div>
      )}
    </Alert>
  );
}

SuccessMessage.defaultProps = {
  message: null,
  title: 'Succès',
};

SuccessMessage.propTypes = {
  message: PropTypes.node,
  title: PropTypes.string,
};
