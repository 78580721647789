import * as React from 'react';
import PropTypes from 'prop-types';
import { extractDate } from 'dmpconnectjsapp-base/helpers/accessors';
import SimpleTable from '../Common/Table/SimpleTable';
import HRPersonMiniCard from './HRPersonMiniCard';

// atcCode: "J01"
// atcLabel: "ANTIBACTERIENS A USAGE SYSTEMIQUE"
// author: {rpps: "", professionCode: "40", professionLabel: "CHIRURGIEN-DENTISTE", formation: false, specialty: undefined, …}
// cipCode: "3400930010662"
// cipLabel: "AMOXICILLINE MYP 1G CPR DISP 1*6"
// date: "20190107"
// hospitalStayDelivered: "true"

const columns = [
  {
    accessor: 'date',
    id: 'date',
    Cell: ({ value }) => (extractDate(value, 'YYYYMMDD').format('DD/MM/YYYY')),
    Header: 'Date de prescription',
    disableFilters: true,
  },
  {
    accessor: 'cipLabel',
    id: 'cipLabel',
    Header: 'Médicament',
    disableFilters: true,
  },
  {
    accessor: 'atcLabel',
    id: 'atcLabel',
    Header: 'Groupe thérapeutique',
    disableFilters: true,
  },
  {
    accessor: 'ingredients',
    id: 'ingredients',
    Header: 'Composants actifs du médicament',
    // eslint-disable-next-line react/no-array-index-key,react/prop-types
    Cell: ({ value }) => (<ul>{value && value.map(((ingredient, index) => <li key={`${ingredient.cnamCode}_${index}`}>{ingredient.cnamLabel}</li>))}</ul>),
    disableFilters: true,
  },
  {
    id: 'author',
    Header: 'Prescrit par',
    disableFilters: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => <HRPersonMiniCard person={row.original.author} />,
  },
  {
    accessor: 'author.es.name',
    id: 'author_es',
    Header: 'Etablissement',
    disableFilters: true,
  },
  {
    accessor: 'performer.es.name',
    id: 'performer',
    Header: 'Délivré par',
    disableFilters: true,
  },
];

const HRPrescriptionSection = ({ section }) => (
  <>
    {section && section.values && section.values.length > 0 && (
      <>
        <h1>{section.title}</h1>
        <SimpleTable
          columns={columns}
          data={section.values}
          striped
          responsive
          initialSortBy={[{ desc: true, id: 'date' }]}
        />
      </>
    )}
  </>
);

HRPrescriptionSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default HRPrescriptionSection;
