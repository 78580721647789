import * as yup from 'yup';
import '../../utils/formUtils';
import { extractDate } from 'dmpconnectjsapp-base/helpers/accessors';

export const validationSchema = yup.object({
  name: yup.string().INSiNames().required('Ce champs est requis'),
  given: yup.string().INSiNames().required('Ce champs est requis'),
  birthday: yup.string().date().pastDate().required('Ce champs est requis'),
  birthplace: yup.string().DMPCMaxLength(5),
  sex: yup.number().oneOf([1, 2, 3]),
});


export const formatValues = (values) => {
  const {
    name, given, birthday, birthplace, sex,
  } = values;

  return {
    s_birthName: name.toUpperCase().replace(/\s+/g, ' '),
    s_given: given.toUpperCase().replace(/\s+/g, ' '),
    i_sex: Number(sex),
    s_birthDate: extractDate(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    s_birthPlace: birthplace,
  };
};

export const initialValues = {
  name: '',
  given: '',
  birthday: '',
  birthplace: '',
  sex: 1,
};
