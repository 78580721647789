export const extractJSONGetMssNbUnreadMessagesParams = (data) => {
  try {
    const
      {
        mssAccount,
        folderId,
      } = data;
    
    return { mssAccount, folderId };
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const extractGetMssNbUnreadMessagesParams = (data) => {
  try {
    const
      {
        mssAccount: { value: mssAccount } = {},
        folderId  : { value: folderId }   = {},
      } = data;
    
    return { mssAccount, folderId };
  } catch (e) {
    console.log(e);
    return false;
  }
};
