import { apiSections, dmpconnectInitActionConstants } from 'dmpconnectjsapp-base/constants';
import { dmpCommandSuccessContextualizedType }        from 'dmpconnectjsapp-base/actions';
import { dmpconnectUserActionConstants }              from '../constants';

const initialState = {};


export function userRights(state = initialState, action) {
  switch (action.type) {
    case dmpconnectInitActionConstants.DMPC_INIT_APPLICATION:
      return initialState;
    case dmpconnectUserActionConstants.SET_USER_RIGHTS:
      return action.accessRights;
    case dmpCommandSuccessContextualizedType(apiSections.DMP_ACCESS_MODE_SECTION):
      return { ...state, accessMode: action.command.i_accessMode };
    default:
      return state;
  }
}
