import { dmpCommandSuccess, dmpCommandSuccessContextualized } from 'dmpconnectjsapp-base/actions';
import { formatSetPersistantDataParams }                       from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands                                                from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections }                                         from 'dmpconnectjsapp-base/constants';
import { getAccessRightsProps }                                from 'dmpconnectjsapp-base/rules/accessRights';
import moment                                                  from 'moment';
import { call, delay, put, select }                            from 'redux-saga/effects';
import { getAction }                                           from '../actions';
import { b64DecodeUnicode, b64EncodeUnicode, generateId }      from '../utils/dataUtils';
import { getPersistantData }                                   from './persistantDataSaga';

const getDebugUploadCPE              = ({ dmpconnectConnectorConfig: { debugUploadCPE } }) => debugUploadCPE;
const getAccessRights                = state => getAccessRightsProps(state);
const extractPersistantDataFromState = ({ esRestPersistantData: { persistantData64 } = {} }) => persistantData64;

export const clearPersistantDataFromState = function* () {
  const actionData    = { s_status: 'OK' };
  const actionContext = { section: apiSections.CLEAR_PERSISTANT_DATA };
  yield put(dmpCommandSuccess(actionData, actionContext));
  yield put(dmpCommandSuccessContextualized(actionData, actionContext));
};

export const getPersistantDataFromState = function* ({ context }) {
  yield delay(500);
  let data = yield select(extractPersistantDataFromState);
  if (!(typeof data === 'string' || data instanceof String)) {
    data = '';
  }
  const actionData    = {
    s_persistantData64: data,
    s_status          : 'OK',
  };
  yield put(dmpCommandSuccess(actionData, context));
  yield put(dmpCommandSuccessContextualized(actionData, context));
};

export const setPersistantDataToState = function* () {
  yield delay(500);
  const actionData    = { s_status: 'OK' };
  const actionContext = { section: apiSections.SET_PERSISTANT_DATA };
  yield put(dmpCommandSuccess(actionData, actionContext));
  yield put(dmpCommandSuccessContextualized(actionData, actionContext));
};

export const handleSentDocument = function* ({ command, data }) {
  const {
          s_uniqueId, s_uuid, UniqueIds, Uuids,
        }        = data;
  const uniqueId = s_uniqueId || (UniqueIds && UniqueIds.length > 0 ? UniqueIds[0] : undefined);
  const uuid     = s_uuid || (Uuids && Uuids.length > 0 ? Uuids[0] : undefined);
  
  let docCommand;
  if (command.Documents && command.Documents[0]) {
    [docCommand] = command.Documents;
  } else {
    docCommand = command;
  }
  const {
          s_commandName,
          s_sessionId,
          s_fileContentBase64,
          i_forceSchematronsValidation,
          i_pdfa1IgnoreTransparency,
          i_disablePdfa1Conversion,
          i_retrieveDocumentUuid,
          timeout,
          ...commandParams
        } = docCommand;
  
  const { data: { s_persistantData64: persistantData } } = yield call(getPersistantData, 'sentDocument', generateId(20));
  
  const { accessRights: { psId } } = yield select(getAccessRights);
  const debugUploadCPE             = yield select(getDebugUploadCPE);
  
  let decodedPersistantData = {};
  
  if (persistantData && persistantData !== '') {
    decodedPersistantData = JSON.parse(b64DecodeUnicode(persistantData));
  }
  
  // supprimer le document remplacé
  // REST
  
  const ins = commandParams.s_ins || command.s_ins;
  const {
          efficience: {
            submittedDocuments: {
              [psId]: {
                [ins]: {
                  [docCommand.s_replacedDocumentUniqueId]: replacedFile,
                  [uniqueId]                             : file,
                  ...allFiles
                } = {},
                ...allIns
              } = {},
              ...allCPX
            } = {},
            ...efficienceRest
          } = {},
          ...persistantRest
        }   = decodedPersistantData;
  
  const now = moment().format('YYYYMMDDHHmmss');
  
  const docToSave = {
    ...commandParams,
    Authors         : [{
      s_hpInternalId: psId,
    }],
    s_uniqueId      : uniqueId,
    s_uuid          : uuid,
    s_submissionDate: now,
    debug           : debugUploadCPE === true,
  };
  
  const newData = {
    efficience: {
      submittedDocuments: {
        [psId]: {
          [ins]: {
            [uniqueId]: docToSave,
            ...allFiles,
          },
          ...allIns,
        },
        ...allCPX,
      },
      ...efficienceRest,
    },
    ...persistantRest,
  };
  
  yield put(getAction(
    commands.setPersistantData,
    apiSections.SET_PERSISTANT_DATA,
    formatSetPersistantDataParams(b64EncodeUnicode(JSON.stringify(newData))),
    {
      synchronous : true,
      contextExtra: { reason: 'sentDocument' }
    }
  ));
};

export const handleDeletePersistedDoc = function* ({ uniqueId, ins }) {
  const { data: { s_persistantData64: persistantData } } = yield call(getPersistantData, 'deletedDocument', generateId(20));
  
  const { accessRights: { psId } } = yield select(getAccessRights);
  
  let decodedPersistantData = {};
  
  if (persistantData && persistantData !== '') {
    decodedPersistantData = JSON.parse(b64DecodeUnicode(persistantData));
  }
  
  const {
          efficience: {
            submittedDocuments: {
              [psId]: {
                [ins]: files = {},
                ...allIns
              } = {},
              ...allCPX
            } = {},
            ...efficienceRest
          } = {},
          ...persistantRest
        } = decodedPersistantData;
  
  const newFiles = Object.values(files)
    .filter(item => item.s_uniqueId !== uniqueId)
    .reduce((obj, item) => ({ [item.s_uniqueId]: item, ...obj }), {});
  
  const newData = {
    efficience: {
      submittedDocuments: {
        [psId]: {
          [ins]: newFiles,
          ...allIns,
        },
        ...allCPX,
      },
      ...efficienceRest,
    },
    ...persistantRest,
  };
  
  yield put(getAction(
    commands.setPersistantData,
    apiSections.SET_PERSISTANT_DATA,
    formatSetPersistantDataParams(b64EncodeUnicode(JSON.stringify(newData))),
    {
      synchronous : true,
      contextExtra: { 'reason': 'deletedDocument' }
    },
  ));
};

export const refreshPersistantData = function* (action) {
  const { context: { refresh = true } } = action;
  if (refresh) {
    yield put(getAction(
      commands.getPersistantData,
      apiSections.GET_PERSISTANT_DATA,
      undefined,
      {
        synchronous : true,
        contextExtra: { 'reason': 'refreshPersistantData' }
      },
    ));
  }
};
