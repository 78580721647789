import { setUserConfiguration }          from 'dmpconnectjsapp-base/actions';
import { formatSetPersistantDataParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';

import commands                                                                                                     from 'dmpconnectjsapp-base/actions/config/commands';
import {
  API_TYPES, apiSections
}                                                                                                                   from 'dmpconnectjsapp-base/constants';
import {
  getApiType
}                                                                                                                   from 'dmpconnectjsapp-base/helpers/accessors';
import {
  initAccessRightsProps
}                                                                                                                   from 'dmpconnectjsapp-base/rules/accessRights';
import {
  generateId
}                                                                                                                   from 'dmpconnectjsapp-base/utils/dataUtils';
import merge                                                                                                        from 'lodash.merge';
import {
  actionChannel, all, call, put, select, take,
}                                                                                                                   from 'redux-saga/effects';
import { getAction, setAllUsersFilters, setUserPreferences }                                                        from '../actions';
import { dmpconnectConfigurationActionConstants, dmpconnectFiltersActionConstants, dmpconnectUserActionConstants, } from '../constants';
import {
  b64DecodeUnicode, b64EncodeUnicode
}                                                                                                                   from '../utils/dataUtils';
import { initMssState }                                                                                             from './mssSagas';
import {
  getPersistantData
}                                                                                                                   from './persistantDataSaga';

const PERSISTANT_DATA_VERSION = 1;

function* initPersistantData(decodedData) {
  const { efficience: { version } = {} } = decodedData;
  let newData                            = decodedData;
  let save                               = false;
  
  
  if ((version || 1) > PERSISTANT_DATA_VERSION) {
    newData = { efficience: { version: PERSISTANT_DATA_VERSION } };
    save    = true;
  }
  // apply migration if needed
  // else if ((version || 1) < PERSISTANT_DATA_VERSION) {
  //   save = true;
  // }
  if (!version) {
    const { statistics, ...rest } = decodedData;
    newData                       = {
      efficience: {
        version           : PERSISTANT_DATA_VERSION,
        statistics,
        submittedDocuments: { ...rest },
      },
    };
    save                          = true;
  }
  
  if (save) {
    yield put(getAction(
      commands.setPersistantData,
      apiSections.SET_PERSISTANT_DATA,
      formatSetPersistantDataParams(b64EncodeUnicode(JSON.stringify(newData))),
      { synchronous: true },
    ));
  }
  return newData;
}

export function* initializeUserPreferencesFromPersistantData() {
  const apiType = yield select(getApiType);
  
  if (apiType === API_TYPES.WS) {
    const { data: { s_persistantData64 } } = yield call(getPersistantData, 'getPrefs', generateId(20));
    
    try {
      const decodedData = s_persistantData64 ? JSON.parse(b64DecodeUnicode(s_persistantData64)) : {};
      const data        = yield call(initPersistantData, decodedData);
      
      let stateToSave;
      const { efficience: { userPreferences, userConfiguration, usersFilters } = {} } = (data || {});
      
      if (userPreferences) {
        yield put(setUserPreferences(userPreferences, false));
      } else {
        const userPrefFromState = yield select(state => state.userPreferences);
        stateToSave             = {
          userPreferences: userPrefFromState,
        };
      }
      if (userConfiguration) {
        yield all(Object.entries(userConfiguration).map(([cpxId, config]) => put(setUserConfiguration(cpxId, config, false))));
      } else {
        const cpxConfigFromState = yield select(state => state.dmpconnectCPxConfiguration);
        stateToSave              = {
          ...stateToSave,
          userConfiguration: cpxConfigFromState,
        };
      }
      if (usersFilters) {
        yield put(setAllUsersFilters(usersFilters));
      } else {
        const stateFilters = yield select(({ dmpconnectFilters: { usersFilters: filterFromState } }) => filterFromState);
        stateToSave        = {
          ...stateToSave,
          filters: stateFilters,
        };
      }
      
      if (stateToSave) {
        const {
                efficience: {
                  userPreferences  : userPreferencesFromPersist,
                  userConfiguration: userConfigurationFromPersist,
                  usersFilters     : filtersFromPersist,
                  ...efficienceRest
                } = {},
                ...persistantRest
              } = data;
        
        yield put(getAction(
          commands.setPersistantData,
          apiSections.SET_PERSISTANT_DATA,
          formatSetPersistantDataParams(b64EncodeUnicode(JSON.stringify({
            efficience: {
              userPreferences  : { ...userPreferencesFromPersist, ...stateToSave.userPreferences },
              userConfiguration: { ...userConfigurationFromPersist, ...stateToSave.userConfiguration },
              usersFilters     : merge({}, filtersFromPersist, stateToSave.filters),
              ...efficienceRest,
            },
            ...persistantRest,
          }))),
          {
            synchronous : true,
            contextExtra: { 'reason': 'savePrefs' }
          },
        ));
      }
    } catch (e) {
      console.error(e);
    }
  }
}

function userPreferenceHandler(currentData, action) {
  const {
          efficience: {
            userPreferences = {},
            ...efficienceDataRest
          } = {},
          ...persistantDataRest
        } = currentData;
  
  return {
    ...persistantDataRest,
    efficience: {
      userPreferences: { ...userPreferences, ...action.preferences },
      ...efficienceDataRest,
    },
  };
}

function userConfigurationHandler(currentData, action) {
  const {
          efficience: {
            userConfiguration: {
              [action.cpxId]: userConfig,
              ...userConfigurationRest
            } = {},
            ...efficienceDataRest
          } = {},
          ...persistantDataRest
        } = currentData;
  
  return {
    ...persistantDataRest,
    efficience: {
      userConfiguration: {
        [action.cpxId]: action.config !== undefined ? { ...userConfig, ...action.config } : undefined,
        ...userConfigurationRest,
      },
      ...efficienceDataRest,
    },
  };
}

function filtersHandler(currentData, action) {
  const {
          efficience: {
            usersFilters: {
              [action.psId]: {
                [action.filterKey]: currentFilterValue,
                ...userFiltersRest
              } = {},
              ...usersFiltersRest
            } = {},
            ...efficienceDataRest
          } = {},
          ...persistantDataRest
        } = currentData;
  
  
  return {
    ...persistantDataRest,
    efficience: {
      usersFilters: {
        [action.psId]: {
          [action.filterKey]: action.filterValue,
          ...userFiltersRest,
        },
        ...usersFiltersRest,
      },
      ...efficienceDataRest,
    },
  };
}

function* saveInPersistantData(type, data) {
  const { data: { s_persistantData64 } } = yield call(getPersistantData, type, generateId(20));
  
  try {
    const currentData = s_persistantData64 ? JSON.parse(b64DecodeUnicode(s_persistantData64)) : {};
    let newData;
    
    switch (type) {
      case dmpconnectConfigurationActionConstants.SET_USER_PREFERENCE:
        newData = userPreferenceHandler(currentData, data);
        break;
      case dmpconnectConfigurationActionConstants.DMPC_SET_USER_CONFIGURATION:
        newData = userConfigurationHandler(currentData, data);
        break;
      case dmpconnectFiltersActionConstants.DMPC_SET_USER_FILTER:
        newData = filtersHandler(currentData, data);
        break;
      default:
        newData = undefined;
    }
    
    yield put(getAction(
      commands.setPersistantData,
      apiSections.SET_PERSISTANT_DATA,
      formatSetPersistantDataParams(b64EncodeUnicode(JSON.stringify(newData))),
      {
        synchronous : true,
        contextExtra: { reason: type }
      },
    ));
  } catch (e) {
    console.error(e);
  }
}

export function* saveUserDataInPersistantDataQueue() {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel([
    dmpconnectConfigurationActionConstants.SET_USER_PREFERENCE,
    dmpconnectConfigurationActionConstants.DMPC_SET_USER_CONFIGURATION,
    dmpconnectFiltersActionConstants.DMPC_SET_USER_FILTER,
  ]);
  while (true) {
    // 2- take from the channel
    const { type, persist, ...data } = yield take(requestChan);
    if (persist === true) {
      const apiType = yield select(getApiType);
      
      if (apiType === API_TYPES.WS) {
        yield call(saveInPersistantData, type, data);
      }
    }
  }
}

export function* getAndSetUserAccessRights() {
  const { accessRights } = yield select(initAccessRightsProps);
  yield put({
    type: dmpconnectUserActionConstants.SET_USER_RIGHTS,
    accessRights,
  });
  yield call(initMssState);
}
