import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

class ButtonWithLoader extends Component {
  render() {
    const {
      loading, loadingLabel, label, className, disabled, ...props
    } = this.props;
    return (
      <Button
        {...props}
        disabled={loading || disabled}
        className={className}
      >
        {loading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            {loadingLabel !== null && <span className="ml-2">{loadingLabel}</span>}
          </>
        ) : (
          label
        )}
      </Button>
    );
  }
}

ButtonWithLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  loadingLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};


ButtonWithLoader.defaultProps = {
  loadingLabel: 'Chargement',
  className: '',
  disabled: false,
};

export default ButtonWithLoader;
