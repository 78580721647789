import { apiSections } from 'dmpconnectjsapp-base/constants';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { readerSlotTypes } from '../../constants';

/**
 * Returns the reader index with the active given slot type
 * @param readers
 * @param slotType
 * @return {*}
 */
export const slotTypeInsertedReader = (readers, slotType) => readers && Array.isArray(readers) && readers.findIndex(
  reader => (
    reader.i_slotType === slotType
    || (slotType === readerSlotTypes.VITALE && reader.s_name.toLowerCase() === 'vitale')
    || (slotType === readerSlotTypes.CPS && reader.s_name.toLowerCase().indexOf('pss reader on') !== -1)
  ),
);

export const getActiveReadersCount = readers => readers && readers.filter(r => r.s_accessMode !== 'GALSS/PCSC').length;

export const checkReaderConfig = (readerIndex, slotType, readers) => {
  if (readers.length === 0) return false;

  const slotTypesToCheck = [readerSlotTypes.NONE, slotType];

  const readerFromConfig = readers[readerIndex];

  return (
    (readerFromConfig && slotTypesToCheck.includes(readerFromConfig.i_slotType))
  );
};

export const getReaderIndex = (readerIndex, slotType, readers) => {
  const slotTypeReadersCount = readers.filter(r => r.i_slotType === slotType).length;

  // si 1 carte du bon type insérée, on retourne cet index
  if (slotTypeReadersCount === 1) {
    return slotTypeInsertedReader(readers, slotType);
  }

  // si plusieurs cartes insérées, on vérifie si l'index enregistré contient le bon type de carte
  // si oui, on retourne cet index
  // si non, on retourne le premier
  if (slotTypeReadersCount > 1) {
    if (Number(readerIndex) !== -1 && checkReaderConfig(readerIndex, slotType, readers)) {
      return readerIndex;
    }
    return slotTypeInsertedReader(readers, slotType);
  }

  // si aucune carte insérée
  if (slotTypeReadersCount === 0) {
    // si on a un index, mais qu'il ne correspond pas au type de carte, on renvoie -1
    if (Number(readerIndex) !== -1 && !checkReaderConfig(readerIndex, slotType, readers)) {
      return -1;
    }
  }

  // sinon on retourne l'index enregistré
  return readerIndex;
};

export const getReaderName = (state, readerVariable) => {
  const {
    dmpconnect: {
      [apiSections.PCSC_READERS_SECTION]: pcscReaders,
    },
    dmpconnectPersistedConnectorConfiguration: {
      [readerVariable]: readerNumber,
    },
  } = state;

  if (isReady(pcscReaders)) {
    const { Readers = [] } = pcscReaders;
    if (Readers && Readers[readerNumber]) {
      return Readers[readerNumber].s_name;
    }
  }

  return null;
};
