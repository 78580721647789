import * as React from 'react';
import PropTypes from 'prop-types';
import { parseHrDocument } from 'dmpconnectjsapp-base/utils/clinicalDocument';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import HREncounterSection from './HREncounterSection';
import HRPrescriptionSection from './HRPrescriptionSection';
import HRMedicalDeviceSection from './HRMedicalDeviceSection';
import HRMedicalCareSection from './HRMedicalCareSection';
import HRActSection from './HRActSection';
import DateRangePresetBar from '../Common/Form/Input/DateRangePresetBar';
import HrVaccinationSection from './HRVaccinationSection';
import { hrSections, hrSectionsLabels } from '../../dmpconnect/constants/hrConstants';
import { existInObject, getCurrentFilter } from '../../dmpconnect/helpers/searchUtils';
import { defaultHRDocFilters, displayHrSections } from '../../dmpconnect/helpers/hr';
import Alert from '../Common/Message/Alert';

export const filterKey = 'document-hr';

export function compareDates(from, to, date) {
  const filterFrom = moment(from, 'DD/MM/YYYY');
  const filterTo = moment(to, 'DD/MM/YYYY');

  if (from && to) {
    return date.isSameOrAfter(filterFrom) && date.isSameOrBefore(filterTo);
  }
  if (from) {
    return date.isSameOrAfter(filterFrom);
  }
  if (to) {
    return date.isSameOrBefore(filterTo);
  }

  return true;
}

export function filterHrDocument(data, from, to, author) {
  if (!from && !to && !author) return data;

  return Object.entries(data).reduce((newData, current) => {
    const [key, section] = current;
    const { values, ...rest } = section;
    return {
      ...newData,
      [key]: {
        ...rest,
        values: values.filter((row) => {
          let filterDate = true;
          let filterAuthor = true;
          if (row.date) {
            const date = moment(row.date, 'YYYYMMDD');

            filterDate = compareDates(from, to, date);
          }

          if (row.from || row.to) {
            const dateFrom = moment(row.from, 'YYYYMMDD');
            const dateTo = moment(row.to, 'YYYYMMDD');

            filterDate = compareDates(from, to, dateFrom) && compareDates(from, to, dateTo);
          }

          filterAuthor = author.trim().split(' ').every(part => existInObject(part, row));

          return filterDate && filterAuthor;
        }),
      },
    };
  }, {});
}

export const isSectionEmpty = (data, section) => {
  if (!data || !section) return true;
  const { [section]: { values = [] } = {} } = data;
  return values.length === 0;
};

const HRDocument = ({ cdaContent, parsedHr, filters }) => {
  const [defaultData, setDefaultData] = React.useState(cdaContent ? parseHrDocument(cdaContent) : {});
  const [data, setData] = React.useState(defaultData);
  const [eventTypes, setEventTypes] = React.useState(filters.eventTypes);
  const [from, setFrom] = React.useState(filters.from);
  const [to, setTo] = React.useState(filters.to);
  const [dateRangeCode, setDateRangeCode] = React.useState(filters.dateRangeCode);
  const [author, setAuthor] = React.useState(filters.author);
  const [timeoutId, setTimeoutId] = React.useState(0);

  const handleDateRangeChange = (target, dateFrom, dateTo, code) => {
    setDateRangeCode(code);
    setFrom(dateFrom);
    setTo(dateTo);
    setData(filterHrDocument(defaultData, dateFrom, dateTo, author));
  };

  const handleAuthorChange = (event) => {
    const search = event.target.value;
    setAuthor(search);
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => setData(filterHrDocument(defaultData, from, to, search)), 200));
  };

  React.useEffect(() => {
    if (defaultData && !defaultData.error) {
      setData(filterHrDocument(defaultData, from, to, author));
    }
  }, [defaultData]);

  React.useEffect(() => {
    if (cdaContent) {
      setDefaultData(parseHrDocument(cdaContent));
    }
  }, [cdaContent]);

  React.useEffect(() => {
    if (parsedHr) {
      setDefaultData(parsedHr);
    }
  }, [parsedHr]);

  return (
    <div className="hr-display">
      <div className="hr-form">
        <Form.Group as={Row} className="mx-0">
          <Form.Label column sm={2}>Type d&apos;&eacute;v&egrave;nement :</Form.Label>
          <Col className="d-flex flex-wrap" style={{ gap: '0.25rem' }}>
            <div className="btn-group-toggle d-inline-block mr-2">
              <ToggleButton
                size="sm"
                type="checkbox"
                className="mb-0 toggle-button"
                variant="outline-secondary"
                value={0}
                checked={displayHrSections.every(s => eventTypes.includes(s))}
                onChange={() => setEventTypes(displayHrSections.every(s => eventTypes.includes(s)) ? [] : displayHrSections)}
              >
                Tout
              </ToggleButton>
            </div>
            <ToggleButtonGroup type="checkbox" size="sm" value={eventTypes} onChange={types => setEventTypes(types)}>
              <ToggleButton
                type="checkbox"
                className="toggle-button"
                value={hrSections.PRESCRIPTIONS}
                disabled={isSectionEmpty(defaultData, hrSections.PRESCRIPTIONS)}
              >
                {hrSectionsLabels[hrSections.PRESCRIPTIONS]}
              </ToggleButton>
              <ToggleButton
                type="checkbox"
                className="toggle-button"
                value={hrSections.VACCINATIONS}
                disabled={isSectionEmpty(defaultData, hrSections.VACCINATIONS)}
              >
                {hrSectionsLabels[hrSections.VACCINATIONS]}
              </ToggleButton>
              <ToggleButton
                type="checkbox"
                className="toggle-button"
                value={hrSections.MEDICALDEVICES}
                disabled={isSectionEmpty(defaultData, hrSections.MEDICALDEVICES)}
              >
                {hrSectionsLabels[hrSections.MEDICALDEVICES]}
              </ToggleButton>
              <ToggleButton
                type="checkbox"
                className="toggle-button"
                value={hrSections.BIO}
                disabled={isSectionEmpty(defaultData, hrSections.BIO)}
              >
                {hrSectionsLabels[hrSections.BIO]}
              </ToggleButton>
              <ToggleButton
                type="checkbox"
                className="toggle-button"
                value={hrSections.HOSPITALSTAYS}
                disabled={isSectionEmpty(defaultData, hrSections.HOSPITALSTAYS)}
              >
                {hrSectionsLabels[hrSections.HOSPITALSTAYS]}
              </ToggleButton>
              <ToggleButton
                type="checkbox"
                className="toggle-button"
                value={hrSections.RADIO}
                disabled={isSectionEmpty(defaultData, hrSections.RADIO)}
              >
                {hrSectionsLabels[hrSections.RADIO]}
              </ToggleButton>
              <ToggleButton
                type="checkbox"
                className="toggle-button"
                value={hrSections.MEDICALCARES}
                disabled={isSectionEmpty(defaultData, hrSections.MEDICALCARES)}
              >
                {hrSectionsLabels[hrSections.MEDICALCARES]}
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mx-0">
          <Form.Label column sm={2}>
            Période :
          </Form.Label>
          <Col>
            <DateRangePresetBar
              size="sm"
              handleChange={handleDateRangeChange}
              target="submission"
              activePreset={dateRangeCode}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mx-0">
          <Form.Label column sm={2}>
            Rechercher :
          </Form.Label>
          <Col>
            <InputGroup>
              <InputGroup.Prepend className="no-background">
                <InputGroup.Text>
                  <Form.Label htmlFor="hr-author-search">
                    <i className="ic-input-search" />
                  </Form.Label>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                id="hr-author-search"
                onChange={handleAuthorChange}
                value={author}
              />
            </InputGroup>
          </Col>
        </Form.Group>
      </div>
      <hr />
      {defaultData && defaultData.error ? (
        <Alert type="danger">
          <div className="mb-3">Une erreur s&apos;est produite pendant la lecture du document.</div>
          <ReactJson
            src={defaultData.error}
            name="détails"
            key="hr_error"
            collapsed={0}
            collapseStringsAfterLength={70}
            displayObjectSize={false}
            displayDataTypes={false}
          />
        </Alert>
      ) : (
        <>
          {data && (
            <div className="hr-results">
              {Object.entries(data).map(([key, section]) => (
                <React.Fragment key={key}>
                  {key === hrSections.PRESCRIPTIONS && eventTypes.includes(hrSections.PRESCRIPTIONS) && <HRPrescriptionSection section={{ ...section, title: hrSectionsLabels[hrSections.PRESCRIPTIONS] }} />}
                  {key === hrSections.VACCINATIONS && eventTypes.includes(hrSections.VACCINATIONS) && <HrVaccinationSection section={{ ...section, title: hrSectionsLabels[hrSections.VACCINATIONS] }} />}
                  {key === hrSections.MEDICALDEVICES && eventTypes.includes(hrSections.MEDICALDEVICES) && <HRMedicalDeviceSection section={{ ...section, title: hrSectionsLabels[hrSections.MEDICALDEVICES] }} />}
                  {key === hrSections.HOSPITALSTAYS && eventTypes.includes(hrSections.HOSPITALSTAYS) && <HREncounterSection section={{ ...section, title: hrSectionsLabels[hrSections.HOSPITALSTAYS] }} />}
                  {key === hrSections.MEDICALCARES && eventTypes.includes(hrSections.MEDICALCARES) && <HRMedicalCareSection section={{ ...section, title: hrSectionsLabels[hrSections.MEDICALCARES] }} />}
                  {key === hrSections.RADIO && eventTypes.includes(hrSections.RADIO) && <HRActSection section={{ ...section, title: hrSectionsLabels[hrSections.RADIO] }} />}
                  {key === hrSections.BIO && eventTypes.includes(hrSections.BIO) && <HRActSection section={{ ...section, title: hrSectionsLabels[hrSections.BIO] }} />}
                </React.Fragment>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

HRDocument.propTypes = {
  cdaContent: PropTypes.string,
  filters: PropTypes.object,
  parsedHr: PropTypes.object,
};

HRDocument.defaultProps = {
  filters: defaultHRDocFilters,
  cdaContent: undefined,
  parsedHr: undefined,
};

function mapStateToProps(state) {
  return {
    filters: getCurrentFilter(state, filterKey, defaultHRDocFilters),
  };
}

export default connect(mapStateToProps)(HRDocument);
