import moment from 'moment';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { authenticationTypes, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { dmpStatuses, userAuthorizationStatuses } from '../../constants';

export const isTreatingPhysician = directAuthenticationDMPStatus => directAuthenticationDMPStatus.i_treatingPhysician === 1;

export const isPatientMinor = (directAuthenticationDMPStatus, minimumMinorAge = 0, debugMinorAge = 18) => {
  const { ExistingTestAnswer: { AdminData: { s_birthday: birthday } = {} } = {} } = directAuthenticationDMPStatus;
  const birthdayMoment = moment.utc(birthday, ['YYYYMMDD'], true);
  const age = moment.utc().diff(birthdayMoment, 'years');
  const minorAge = debugMinorAge || 18;
  return age >= minimumMinorAge && age < minorAge;
};

export const isWebAccessOpen = (directAuthenticationDMPStatus) => {
  const { ExistingTestAnswer: { i_webAccessIsOpen = 0 } = {} } = directAuthenticationDMPStatus;

  return i_webAccessIsOpen === 1;
};
export const getSelectedPatientInfos = (
  directAuthenticationDMPStatus,
  vitaleCardSection,
  vitaleIndex,
) => {
  let birthName = '';
  let birthday = '';
  let givenName = '';
  let firstGivenName = '';
  let name = '';
  let i_dmpStatus = null;
  if (isReady(directAuthenticationDMPStatus)) {
    ({
      ExistingTestAnswer: {
        AdminData: {
          s_birthday: birthday,
          s_patientGivenName: givenName,
          s_patientFirstBirthGiven: firstGivenName,
          s_patientName: name,
          s_patientBirthName: birthName,
        },
        i_dmpStatus,
      },
    } = directAuthenticationDMPStatus);

    if (i_dmpStatus !== dmpStatuses.DMPNotFound && i_dmpStatus !== dmpStatuses.DMPError) {
      return {
        name, givenName: firstGivenName || givenName, birthday, birthName,
      };
    }
  }

  if (isReady(vitaleCardSection) && !(birthday && givenName && firstGivenName && name)) {
    const { Patients } = vitaleCardSection;
    const selectedPatient = Patients[vitaleIndex];
    if (selectedPatient) {
      ({
        s_name: name,
        s_given: givenName,
        s_birthdayYYYYMMDD: birthday,
        s_birthName: birthName,
      } = selectedPatient);
    }
  }

  return {
    name: name || '',
    givenName: firstGivenName || givenName || '',
    birthday,
    birthName: birthName || '',
  };
};

export const getDirectAuthenticationStatusFromSection = (accessRights, section, ins) => {
  const {
    [`${ins}/${accessRights.psId}`]: psDirectAuthenticationDMPStatus = {},
    [`${ins}/${accessRights.esId}`]: esDirectAuthenticationDMPStatus = {},
  } = section;
  let directAuthenticationDMPStatus;

  const {
    ExistingTestAnswer: {
      i_userAuthorization: psAuthorization,
      ...restTest
    } = {},
    loading: psLoading,
    error: psError,
    ...restStatus
  } = psDirectAuthenticationDMPStatus;

  const emergency = psAuthorization === userAuthorizationStatuses.AuthorizationExpired
    || psAuthorization === userAuthorizationStatuses.NoAuthorization;

  if (accessRights.authenticationType === authenticationTypes.AIR && !accessRights.airOnly) {
    const {
      ExistingTestAnswer: {
        i_userAuthorization: esAuthorization,
      } = {},
      loading: esLoading,
      error: esError,
    } = esDirectAuthenticationDMPStatus;

    let authorization = psAuthorization;
    if (!(
      psAuthorization === userAuthorizationStatuses.AuthorizationExist
      && esAuthorization === userAuthorizationStatuses.AuthorizationExist
    )) {
      authorization = userAuthorizationStatuses.NoAuthorization;
    }

    directAuthenticationDMPStatus = {
      ExistingTestAnswer: {
        i_userAuthorization: authorization,
        psAuthorization,
        esAuthorization,
        ...restTest,
      },
      loading: psLoading || esLoading,
      error: psError || esError,
      ...restStatus,
    };
  } else {
    directAuthenticationDMPStatus = psDirectAuthenticationDMPStatus;
  }

  return { ...directAuthenticationDMPStatus, showEmergency: emergency };
};
export const getDirectAuthenticationStatus = (state, ins, remote = false) => {
  if (!ins) return {};

  const { accessRights } = getAccessRightsProps(state);
  const { dmpconnectConnectorConfig: { airOnly = false } } = state;
  let section;
  if (remote) {
    ({
      dmpconnectRemote: {
        [apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION]: section,
      },
    } = state);
  } else {
    ({
      dmpconnect: {
        [apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION]: section,
      },
    } = state);
  }

  return getDirectAuthenticationStatusFromSection(accessRights, section, ins, airOnly);
};
export const getDirectAuthenticationStatusSection = (state, ins) => {
  if (!ins) return {};

  const { accessRights } = getAccessRightsProps(state);
  const { dmpconnectConnectorConfig: { airOnly = false } } = state;
  const {
    dmpconnect: {
      [apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION]: section,
    },
  } = state;

  return {
    dmpStatus: getDirectAuthenticationStatusFromSection(accessRights, section, ins, airOnly),
  };
};
export const getFormattedNames = (dmpDirectAuthenticationStatus) => {
  if (!isReady(dmpDirectAuthenticationStatus)) return null;

  const { ExistingTestAnswer } = dmpDirectAuthenticationStatus;
  const { AdminData } = ExistingTestAnswer;
  const {
    s_patientGivenName, s_patientFirstBirthGiven, s_patientBirthName, s_patientName,
  } = AdminData;
  return `${s_patientGivenName || s_patientFirstBirthGiven || ''} ${s_patientName || s_patientBirthName || ''}`;
};
