import { mssLoginTypes }                                                        from 'dmpconnectjsapp-base/constants';
import { getApiType, getConfigurationValue, getDcParams, getMssLoginType }      from 'dmpconnectjsapp-base/helpers/accessors';
import {
  esLoginTypes
}                                                                               from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { getAccessRightsProps }                                                 from 'dmpconnectjsapp-base/rules/accessRights';
import PropTypes                                                                from 'prop-types';
import React, { Suspense }                                                      from 'react';
import Container                                                                from 'react-bootstrap/Container';
import { connect }                                                              from 'react-redux';
import { Route, Switch }                                                        from 'react-router-dom';
import { ToastContainer }                                                       from 'react-toastify';
import CardReaderProvider                                                       from './components/Card/CardReaderProvider';
import CenteredCard                                                             from './components/Common/Card/CenteredCard';
import AvailableNewVersion                                                      from './components/Common/Header/AvailableNewVersion';
import Loading                                                                  from './components/Common/Loading/Loading';
import GetLogsPopup                                                             from './components/Common/Logs/GetLogsPopup';
import Alert                                                                    from './components/Common/Message/Alert';
import VersionFooter                                                            from './components/Common/Version/VersionFooter';
import EsConfigCheck                                                            from './components/Config/EsConfigCheck';
import DpConnector                                                              from './components/DP/DpConnector';
import VersionFooterDP                                                          from './components/DP/VersionFooterDP';
import ErrorBoundary                                                            from './components/Error/ErrorBoundary';
import IframeMessageReceiver                                                    from './components/iframe/IframeMessageReceiver';
import MssSendEmail                                                             from './components/mss/MssSendEmail';
// Components
import { initApplication, resetAllConfigFromEnv, setPersistedAppConfiguration } from './dmpconnect/actions';
import { API_TYPES }                                                            from './dmpconnect/constants';
import userManager                                                              from './dmpconnect/constants/userManager';
import { isMssActive }                                                          from './dmpconnect/helpers';
import getIsMobileFromState                                                     from './dmpconnect/helpers/isMobile';
import env, { generateEnvCheckSum }                                             from './envVariables';
import lazyWithRetry                                                            from './lib/lazyWithRetry';

import { doesNotTriggerInitApp, routes } from './router';

const DMPMobileLanding = lazyWithRetry(() => import('./scenes/DMPMobileLanding'));
const DMPDocument = lazyWithRetry(() => import('./scenes/DMPDocument'));
const DMPDocuments = lazyWithRetry(() => import('./scenes/DMPDocuments'));
const Imagery = lazyWithRetry(() => import('./scenes/Imagery'));
const DMPSentDocuments = lazyWithRetry(() => import('./scenes/DMPSentDocuments'));
const InitApp = lazyWithRetry(() => import('./components/Init/InitApp'));
const DMPSearch = lazyWithRetry(() => import('./scenes/DMPSearch'));
const DPSearch = lazyWithRetry(() => import('./scenes/DPSearch'));
const DMPConfiguration = lazyWithRetry(() => import('./scenes/DMPConfiguration'));
const DMPDebug = lazyWithRetry(() => import('./scenes/DMPDebug'));
const DMPEsConfig = lazyWithRetry(() => import('./scenes/DMPEsConfig'));
const DMPTseConfig = lazyWithRetry(() => import('./scenes/DMPTseConfig'));
const ErrorModal = lazyWithRetry(() => import('./components/Error/ErrorModal'));
const GlobalModalConfirmation = lazyWithRetry(() => import('./components/Common/Modal/GlobalModalConfirmation'));
const DMPAdministration       = lazyWithRetry(() => import('./scenes/DMPAdministration'));
const WebAccessPDFViewer      = lazyWithRetry(() => import('./scenes/WebAccessPDFViewer'));
const PageNotFound            = lazyWithRetry(() => import('./scenes/PageNotFound'));
const DMPAccess               = lazyWithRetry(() => import('./scenes/DMPAccess'));
const VirtualPrinterOverlay   = lazyWithRetry(() => import('./components/VirtualPrinter/VirtualPrinterOverlay'));
const RemoteCreateDMP         = lazyWithRetry(() => import('./components/Remote/RemoteCreateDMP'));
const RemoteReactivateDMP     = lazyWithRetry(() => import('./components/Remote/RemoteReactivateDMP'));
const RemoteOpenDMPConsent    = lazyWithRetry(() => import('./components/Remote/RemoteOpenDMPConsent'));
const RemoteCloseDMPConsent   = lazyWithRetry(() => import('./components/Remote/RemoteCloseSessionDMPConsent'));
const RemoteSubmitDocument    = lazyWithRetry(() => import('./components/Remote/RemoteSubmitDocument'));
const ParcoursSoin            = lazyWithRetry(() => import('./scenes/ParcoursSoin'));
const MobileParcoursSoin      = lazyWithRetry(() => import('./scenes/MobileParcoursSoin'));
const MobileSearchDocs        = lazyWithRetry(() => import('./scenes/MobileSearchDocuments'));
const MobileUploader          = lazyWithRetry(() => import('./scenes/MobileUploader'));
const EsLoginCallback         = lazyWithRetry(() => import('./components/DMPLogin/EsLoginCallback'));

const DPAccess = lazyWithRetry(() => import('./scenes/DPAccess'));
const DPList   = lazyWithRetry(() => import('./scenes/DPList'));

const ALDVitale = lazyWithRetry(() => import('./scenes/ALDVitale'));
const ALDDmp    = lazyWithRetry(() => import('./scenes/ALDDmp'));

const MssClient = lazyWithRetry(() => import('./scenes/MssClient'));
const DMPLogout = lazyWithRetry(() => import('./components/DMPLogin/DMPLogout'));

const DMPVidalConfig = lazyWithRetry(() => import('./scenes/DMPVidalConfig'));
const DMPMeds        = lazyWithRetry(() => import('./scenes/DMPMeds'));

const Logs         = lazyWithRetry(() => import('./scenes/Logs'));
const DMPCPxConfig = lazyWithRetry(() => import('./scenes/DMPCPxConfig'));
const DMPMssConfig = lazyWithRetry(() => import('./scenes/DMPMssConfig'));
const DMPConfig    = lazyWithRetry(() => import('./scenes/DMPConfig'));

const DISPLAY_MODES = {
  EFFICIENCE: 'EFFICIENCE',
  MSSCLIENT : 'MSSCLIENT',
  DPCONSULT : 'DPCONSULT',
};

const App = ({
  dispatch,
  saasTokenOk,
  envCheckSum,
  envCheckSumVersion,
  openIDConfiguration,
  applicationInitialized,
  connectorOk,
  sessionOk,
  connectorVersionOk,
  licenseOk,
  showMssPopup,
  mssActive,
  hideConfig,
  connectorToken,
  apiType,
  dcParams,
  pathname,
  urlProcessed,
  displayMode,
  showLoadingBackdrop,
  loadingBackdropMessage,
  exportOnlyUI,
  psName,
  isMobile,
  mssPscLoginCallback,
}) => {
  React.useEffect(() => {
    document.title = 'Efficience';
    
    // this has to be done this way
    // https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx/64815699#64815699
    // if we add it in the render part, the script will not be executed.
    const script = document.createElement('script');
    script.src   = '/redmine-widget/redmine-widget.js';
    
    if (Number(env.REACT_APP_REDMINE_WIDGET) === 1) {
      document.body.appendChild(script);
    }
    
    return () => {
      if (Number(env.REACT_APP_REDMINE_WIDGET) === 1) {
        document.body.removeChild(script);
      }
    };
  }, []);
  
  React.useEffect(() => {
    window.psName = psName || '';
    window.postMessage(JSON.stringify({ reload_form: true }), '*');
  }, [psName]);
  
  React.useEffect(() => {
    const currentEnvChecksum = generateEnvCheckSum(env);
    if (envCheckSum !== currentEnvChecksum && !!envCheckSumVersion) {
      dispatch(resetAllConfigFromEnv());
    }
    dispatch(setPersistedAppConfiguration('envCheckSum', currentEnvChecksum));
    dispatch(setPersistedAppConfiguration('envCheckSumVersion', env.REACT_APP_VERSION));
  }, [env, envCheckSum, envCheckSumVersion]);
  
  React.useEffect(() => {
    if (
      !applicationInitialized
      && connectorOk
      && sessionOk
      && connectorVersionOk
      && licenseOk
      && saasTokenOk
      && urlProcessed
      && !doesNotTriggerInitApp.includes(pathname)
    ) {
      dispatch(initApplication());
    }
  }, [
    saasTokenOk,
    urlProcessed,
    applicationInitialized,
    connectorOk,
    sessionOk,
    connectorVersionOk,
    licenseOk,
  ]);
  
  React.useEffect(() => {
    userManager.setConfig({
      ...openIDConfiguration,
      connectorToken,
      apiType,
      dcParams: openIDConfiguration.sendTokenDcParams === true ? dcParams : undefined,
    });
  }, [openIDConfiguration, connectorToken, apiType, dcParams]);
  
  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={
          <CenteredCard><Loading message="Chargement ..."/></CenteredCard>
        }>
          {showLoadingBackdrop === true && (
            <Loading backdrop message={loadingBackdropMessage}/>
          )}
          <AvailableNewVersion/>
          <Switch>
            <Route path={routes.pscInfo.path}>
              <Container className="align-items-center justify-content-center p-3">
                <Alert type="info">
                  Service d’authentification des professionnels de santé pour l’application Efficience de la société icanopée
                  <br/>
                  Pour toute question : <a href="mailto:contact@icanopee.fr">contact@icanopee.fr</a>.
                </Alert>
              </Container>
            </Route>
            <Route path={routes.logs.path} component={Logs}/>
            <Route path={routes.dmpConfig.path} component={DMPConfig}/>
            <Route path={routes.cpxConfig.path} component={DMPCPxConfig}/>
            {mssActive && <Route path={routes.mssConfig.path} component={DMPMssConfig}/>}
            <Route path={routes.logout.path} component={DMPLogout}/>
            {Number(env.REACT_APP_DEBUG) === 1 && (
              <Route exact path={routes.debug.path} component={DMPDebug}/>
            )}
            {Number(env.REACT_APP_VIDAL) === 1 && (
              <Route exact path={routes.vidalConfig.path} component={DMPVidalConfig}/>
            )}
            <Route exact path={routes.tseConfig.path} component={DMPTseConfig}/>
            <Route path="/*">
              <>
                {saasTokenOk ? (
                  <>
                    {applicationInitialized ? (
                      <>
                        <CardReaderProvider showLoading={false}>
                          <Switch>
                            <Route exact path={routes.openIdAuthCallback.path} component={EsLoginCallback}/>
                            <Route exact path={routes.esConfig.path} component={DMPEsConfig}/>
                            <Route path="/*">
                              <>
                              {displayMode === DISPLAY_MODES.MSSCLIENT && (
                                <EsConfigCheck>
                                  <DMPAccess>
                                    <Switch>
                                      <Route
                                        exact
                                        path={routes.configuration.path}
                                        component={hideConfig ? PageNotFound : DMPConfiguration}
                                      />
                                      <Route path={routes.mss.path} component={MssClient} />
                                      <Route path="/*" component={PageNotFound} />
                                    </Switch>
                                    {showMssPopup && mssActive === true && <MssSendEmail />}
                                  </DMPAccess>
                                </EsConfigCheck>
                              )}
                              {displayMode === DISPLAY_MODES.DPCONSULT && (
                                <DPAccess>
                                  <DpConnector>
                                    <Switch>
                                      <Route exact path={routes.dmpSearch.path} component={DPSearch} />
                                      <Route path={routes.dp.path} component={DPList} />
                                      <Route
                                        exact
                                        path={routes.configuration.path}
                                        component={hideConfig ? PageNotFound : DMPConfiguration}
                                      />
                                      <Route path="/*" component={PageNotFound} />
                                    </Switch>
                                  </DpConnector>
                                </DPAccess>
                              )}
                              {displayMode === DISPLAY_MODES.EFFICIENCE && (
                                <EsConfigCheck>
                                  <DMPAccess isMobile={isMobile}>
                                    <Switch>
                                      <Route path={routes.dmpSearch.path} component={DMPSearch} />
                                      <Route
                                        exact
                                        path={routes.configuration.path}
                                        component={hideConfig ? PageNotFound : DMPConfiguration}
                                      />
                                      <Route exact path={routes.aldVitale.path} component={exportOnlyUI ? PageNotFound : ALDVitale} />
                                      <Route exact path={routes.aldDmp.path} component={exportOnlyUI ? PageNotFound : ALDDmp} />
                                      <Route exact path={routes.dmpDocument.path} component={exportOnlyUI ? PageNotFound : DMPDocument} />
                                      <Route exact path={routes.dmpMobileLanding.path} component={exportOnlyUI ? PageNotFound : DMPMobileLanding} />
                                      <Route exact path={routes.upload.path} component={MobileUploader} />
                                      <Route path={routes.dmpAdministration.path} component={exportOnlyUI ? PageNotFound : DMPAdministration} />
                                      <Route path={routes.sentDocuments.path} component={exportOnlyUI ? PageNotFound : DMPSentDocuments} />

                                      <Route path={routes.dmpDocumentsCategory.path} component={DMPDocuments} />
                                      <Route path={routes.dmpDocuments.path} component={DMPDocuments} />
                                      <Route path={routes.imagery.path} component={Imagery} />
                                      <Route path={routes.meds.path} component={DMPMeds} />
                                      <Route path={routes.hr.path} component={DMPMeds} />
                                      <Route path={routes.webAccessPDFViewer.path} component={WebAccessPDFViewer} />
                                      <Route path={routes.listview.path} component={isMobile ? MobileParcoursSoin : ParcoursSoin} />
                                      <Route path={routes.searchDocs.path} component={MobileSearchDocs} />
                                      <Route path={routes.mss.path} component={MssClient} />
                                      <Route path="/*" component={PageNotFound} />
                                    </Switch>
                                    <RemoteCreateDMP />
                                    <RemoteReactivateDMP />
                                    <RemoteOpenDMPConsent />
                                    <RemoteCloseDMPConsent />
                                    <RemoteSubmitDocument />
                                    <VirtualPrinterOverlay />
                                    {showMssPopup && mssActive === true && <MssSendEmail />}
                                  </DMPAccess>
                                </EsConfigCheck>
                              )}
                              </>
                            </Route>
                          </Switch>
                        </CardReaderProvider>
                        {!isMobile && !mssPscLoginCallback && (
                          <>
                            {displayMode === DISPLAY_MODES.DPCONSULT ? (
                              <VersionFooterDP/>
                            ) : (
                               <VersionFooter/>
                             )}
                          </>
                        )}
                      </>
                    ) : (
                       <InitApp/>
                     )}
                  </>
                ) : (
                   <CenteredCard>
                     Vous n'&ecirc;tes pas autorisé à utiliser cette application.
                   </CenteredCard>
                 )}
              </>
            </Route>
          </Switch>
          
          <ErrorModal/>
          <GlobalModalConfirmation/>
          
          <ToastContainer
            autoClose={2000}
            position={'top-right'}
            closeOnClick
            hideProgressBar
          />
          <IframeMessageReceiver/>
        </Suspense>
      </ErrorBoundary>
      <GetLogsPopup/>
      {Number(env.REACT_APP_REDMINE_WIDGET) === 1 && !mssPscLoginCallback && <div id="helpdesk_widget"></div>}
    </>
  );
};

App.propTypes = {
  dispatch              : PropTypes.func.isRequired,
  applicationInitialized: PropTypes.bool,
  initRunning           : PropTypes.bool,
  showMssPopup          : PropTypes.bool,
  mssActive             : PropTypes.bool,
  saasTokenOk           : PropTypes.bool,
  hideConfig            : PropTypes.bool,
  envCheckSum           : PropTypes.string,
  envCheckSumVersion    : PropTypes.string,
  openIDConfiguration   : PropTypes.object,
  connectorToken        : PropTypes.string,
  dcParams              : PropTypes.string,
  apiType               : PropTypes.string.isRequired,
  pathname              : PropTypes.string.isRequired,
  urlProcessed          : PropTypes.bool,
  displayMode           : PropTypes.string.isRequired,
  showLoadingBackdrop   : PropTypes.bool,
  exportOnlyUI          : PropTypes.bool,
  loadingBackdropMessage: PropTypes.string,
  psName                : PropTypes.string,
  isMobile              : PropTypes.bool,
  mssPscLoginCallback   : PropTypes.bool,
  connectorOk           : PropTypes.bool.isRequired,
  sessionOk             : PropTypes.bool.isRequired,
  connectorVersionOk    : PropTypes.bool.isRequired,
  licenseOk             : PropTypes.bool.isRequired,
};

App.defaultProps = {
  initRunning           : undefined,
  applicationInitialized: false,
  showMssPopup          : false,
  mssActive             : false,
  saasTokenOk           : false,
  hideConfig            : false,
  envCheckSum           : null,
  envCheckSumVersion    : null,
  openIDConfiguration   : null,
  connectorToken        : undefined,
  dcParams              : undefined,
  urlProcessed          : false,
  showLoadingBackdrop   : false,
  exportOnlyUI          : false,
  loadingBackdropMessage: '',
  psName                : undefined,
  isMobile              : false,
  mssPscLoginCallback   : false,
};

function mapStateToProps(state) {
  const {
          router                             : { location: { pathname } },
          dmpconnectInit                     : {
            applicationInitialized,
            initRunning,
            connectorOk,
            sessionOk,
            connectorVersionOk,
            licenseOk,
          },
          dmpconnectApplication              : {
            showMssPopup,
            saasTokenOk,
            urlProcessed,
            showLoadingBackdrop,
            loadingBackdropMessage,
          },
          dmpConnectPersistedAppConfiguration: {
            efficienceDP,
            mssClientMode,
            hideConfig,
            envCheckSum,
            envCheckSumVersion,
            exportOnlyUI,
            cpxLoginType,
          },
          dmpconnectESConfiguration          : {
            loginType,
            esLoginActive,
          },
          openIDConfiguration,
          dmpconnectPersistedConnectorConfiguration,
        } = state;
  
  const mssLoginType = getMssLoginType(state);
  
  let displayMode = DISPLAY_MODES.EFFICIENCE;
  if (efficienceDP) {
    displayMode = DISPLAY_MODES.DPCONSULT;
  } else if (mssClientMode) {
    displayMode = DISPLAY_MODES.MSSCLIENT;
  }
  
  const {
          accessRights: {
            psId,
            given,
            name,
          },
        } = getAccessRightsProps(state);
  
  const apiType    = getApiType(state);
  const esIsActive = esLoginActive || apiType === API_TYPES.REST;
  
  
  return {
    psName             : psId ? `${name} ${given} (${psId})` : undefined,
    pathname,
    urlProcessed,
    initRunning,
    connectorOk,
    sessionOk,
    connectorVersionOk,
    licenseOk,
    applicationInitialized,
    saasTokenOk,
    showMssPopup,
    openIDConfiguration,
    apiType            : getApiType(state),
    dcParams           : getDcParams(state),
    mssActive          : isMssActive(state),
    efficienceDP,
    hideConfig,
    exportOnlyUI,
    envCheckSum,
    envCheckSumVersion,
    connectorToken     : getConfigurationValue('connectorJWT', dmpconnectPersistedConnectorConfiguration),
    displayMode,
    showLoadingBackdrop,
    loadingBackdropMessage,
    isMobile           : getIsMobileFromState(state),
    mssPscLoginCallback: (
      (!esIsActive || (esIsActive && loginType !== esLoginTypes.OPENID))
      && cpxLoginType !== esLoginTypes.OPENID
      && mssLoginType === mssLoginTypes.PSC
      && pathname === routes.openIdAuthCallback.path
    ),
  };
}

const connectedApp = connect(mapStateToProps,
  null,
  null,
  {
    pure: false,
  })(App);

export default connectedApp;
