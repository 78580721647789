import React from 'react';
import { useTable, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from 'react-bootstrap';

export default function SimpleTable({
  data, columns, initialSortBy, showHeaderWhenEmptyData, rowProps, ...props
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useSortBy,
  );

  if (!showHeaderWhenEmptyData && (!data || data.length === 0)) {
    return null;
  }

  // Render the UI for your table
  return (
    <>
      <BootstrapTable
        {...props}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  key={`th-${column.id}`}
                  className={`th-${column.id} ${column.isSorted ? 'sorted' : ''} typography-default-text-title`}
                  style={{ verticalAlign: 'top', width: `${column.fixedWidth}px` }}
                >
                  <div {...column.getHeaderProps(column.getSortByToggleProps({ title: 'Trier' }))} className="d-flex align-items-center">
                    {column.render('Header')}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {column.isSorted ? (column.isSortedDesc
                      ? <i className="ic-arrow-down" />
                      : <i className="ic-arrow-up" />) : ''}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(rowProps(row))}>
                {row.cells.map(cell => (
                  <td className={`td-${cell.column.id} ${cell.column.tdClassName || ''}`} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
    </>
  );
}

SimpleTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  initialSortBy: PropTypes.array,
  showHeaderWhenEmptyData: PropTypes.bool,
  rowProps: PropTypes.func,
};

SimpleTable.defaultProps = {
  initialSortBy: [],
  showHeaderWhenEmptyData: false,
  rowProps: () => ({}),
};
