import * as React from 'react';
import PropTypes from 'prop-types';
import { extractDate } from 'dmpconnectjsapp-base/helpers/accessors';
import SimpleTable from '../Common/Table/SimpleTable';

const columns = [
  {
    accessor: row => (
      <>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {`du ${extractDate(row.from, 'YYYYMMDD').format('DD/MM/YYYY')}`}
        {' '}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {`au ${extractDate(row.to, 'YYYYMMDD').format('DD/MM/YYYY')}`}
      </>
    ),
    id: 'date',
    Header: 'Période de l\'acte',
    disableFilters: true,
  },
  {
    accessor: 'label',
    id: 'label',
    Header: 'Pathologie en cours',
    disableFilters: true,
  },
  {
    accessor: 'performer.es.name',
    id: 'es',
    Header: 'Lieu du séjour',
    disableFilters: true,
  },
];

const HREncounterSection = ({ section }) => (
  <>
    {section && section.values && section.values.length > 0 && (
      <>
        <h1>{section.title}</h1>
        <SimpleTable
          columns={columns}
          data={section.values}
          striped
          responsive
          initialSortBy={[{ desc: true, id: 'date' }]}
        />
      </>
    )}
  </>
);

HREncounterSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default HREncounterSection;
