import merge from 'lodash.merge';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  getClearSectionState,
  getFailureState,
  getLoadingState,
  getSuccessState,
} from 'dmpconnectjsapp-base/reducers/helpers';
import { dmpconnectRemoteActionConstants } from '../constants';

const initialState = {
  [apiSections.REMOTE_EXPORT_DOCUMENT_SECTION]: {},
  [apiSections.REMOTE_DIRECT_AUTHENTICATION_DMP_STATUS_SECTION]: {},
  [apiSections.REMOTE_FIND_PATIENTS_SECTION]: {},
  [apiSections.REMOTE_OPEN_OR_CREATE_DMP]: {},
  [apiSections.REMOTE_CREATE_DMP]: {},
  [apiSections.REMOTE_REACTIVATE_DMP]: {},
  [apiSections.REMOTE_SEND_DOCUMENT]: {},
  [apiSections.REMOTE_CERTIFIFIED_IDENTITY_SECTION]: {},
  [apiSections.REMOTE_CLOSE_DMP_SESSION]: {},
  [apiSections.REMOTE_SUBMIT_DOCUMENT]: {},
};

export function dmpconnectRemote(state = initialState, action) {
  switch (action.type) {
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_SEND_COMMAND:
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_COMMAND_START:
      return merge({}, state, getLoadingState(action));
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_COMMAND_SUCCESS:
      return merge({}, state, getSuccessState(action));
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_COMMAND_FAILURE:
      return merge({}, state, getFailureState(action));
    case dmpconnectRemoteActionConstants.DMPC_CLEAR_REMOTE_SECTION:
      return getClearSectionState(action, state);
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_CREATE_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_CREATE_DMP]: {
          getConsent: true,
          params: action.params,
          ins: action.ins,
        },
      });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_CREATE_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_CREATE_DMP]: {
          getConsent: false,
          consent: action.consent,
        },
      });

    case dmpconnectRemoteActionConstants.DMPC_REMOTE_CREATE_DMP_SHOW_FORM:
      return merge({}, state, {
        [apiSections.REMOTE_CREATE_DMP]: {
          showForm: true,
        },
      });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_CREATE_DMP_HIDE_FORM:
      return merge({}, state, {
        [apiSections.REMOTE_CREATE_DMP]: {
          showForm: false,
        },
      });

    case dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_REACTIVATE_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_REACTIVATE_DMP]: {
          getConsent: true,
          params: action.params,
          ins: action.ins,
        },
      });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_REACTIVATE_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_REACTIVATE_DMP]: {
          getConsent: false,
          consent: action.consent,
        },
      });

    case dmpconnectRemoteActionConstants.DMPC_REMOTE_REACTIVATE_DMP_SHOW_FORM:
      return merge({}, state, {
        [apiSections.REMOTE_REACTIVATE_DMP]: {
          showForm: true,
        },
      });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_REACTIVATE_DMP_HIDE_FORM:
      return merge({}, state, {
        [apiSections.REMOTE_REACTIVATE_DMP]: {
          showForm: false,
        },
      });

    case dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_OPEN_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_OPEN_OR_CREATE_DMP]: {
          getConsent: true,
          params: action.params,
          ins: action.ins,
        },
      });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_OPEN_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_OPEN_OR_CREATE_DMP]: {
          getConsent: false,
          consent: action.consent,
        },
      });

    case dmpconnectRemoteActionConstants.DMPC_REMOTE_GET_CLOSE_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_CLOSE_DMP_SESSION]: {
          getConsent: true,
        },
      });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_ANSWER_CLOSE_DMP_CONSENT:
      return merge({}, state, {
        [apiSections.REMOTE_CLOSE_DMP_SESSION]: {
          getConsent: false,
          consent: action.consent,
        },
      });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_SUBMIT_DOC:
      return merge({}, state, { [apiSections.REMOTE_SUBMIT_DOCUMENT]: { ...action.document, id: action.requestId } });
    case dmpconnectRemoteActionConstants.DMPC_REMOTE_SUBMIT_DOC_INS:
      return merge({}, state, { [apiSections.REMOTE_SUBMIT_DOCUMENT]: { ...state.submitDocument, ins: action.ins } });
    default:
      return state;
  }
}
