import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

export default function CenteredCard({
  children, header, headerClassName, className,
}) {
  return (
    <div className="centered-container">
      <Card className={className}>
        {header && <Card.Header className={headerClassName}>{header}</Card.Header>}
        <Card.Body>
          {children}
        </Card.Body>
      </Card>
    </div>
  );
}

CenteredCard.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
};

CenteredCard.defaultProps = {
  header: null,
  headerClassName: null,
  className: null,
};
