export const hrSections = {
  RADIO: 'radio',
  BIO: 'bio',
  MEDICALCARES: 'medicalCares',
  HOSPITALSTAYS: 'hospitalStays',
  MEDICALDEVICES: 'medicalDevices',
  VACCINATIONS: 'vaccinations',
  PRESCRIPTIONS: 'prescriptions',
};

export const hrSectionsLabels = {
  [hrSections.RADIO]: 'Radiologie',
  [hrSections.BIO]: 'Biologie',
  [hrSections.MEDICALCARES]: 'Soins médicaux et dentaires',
  [hrSections.HOSPITALSTAYS]: 'Hospitalisations',
  [hrSections.MEDICALDEVICES]: 'Dispositifs médicaux',
  [hrSections.VACCINATIONS]: 'Vaccinations',
  [hrSections.PRESCRIPTIONS]: 'Médicaments',
};

export const typeDocToHrSection = {
  BIL_AUTO: hrSections.MEDICALCARES,
  DISP_AUT: hrSections.PRESCRIPTIONS,
  PROT_ALD: hrSections.MEDICALCARES,
  SYNTH: hrSections.MEDICALCARES,
  '11369-6': hrSections.VACCINATIONS,
  '11488-4': hrSections.MEDICALCARES,
  '11490-0': hrSections.HOSPITALSTAYS,
  '11502-2': hrSections.BIO,
  '11505-5': hrSections.MEDICALCARES,
  '11506-3': hrSections.MEDICALCARES,
  '11526-1': hrSections.MEDICALCARES,
  '15507-7': hrSections.MEDICALCARES,
  '15508-5': hrSections.MEDICALCARES,
  '18776-5': hrSections.MEDICALCARES,
  '34133-9': hrSections.MEDICALCARES,
  '34749-2': hrSections.MEDICALCARES,
  '34794-8': hrSections.MEDICALCARES,
  '34874-8': hrSections.MEDICALCARES,
  '47420-5': hrSections.MEDICALCARES,
  '34112-3': hrSections.HOSPITALSTAYS,
  '18748-4': hrSections.RADIO,
  '52040-3': hrSections.RADIO,
  '51969-4': hrSections.BIO,
  '57828-6': hrSections.PRESCRIPTIONS,
  '57832-8': hrSections.PRESCRIPTIONS,
  '57833-6': hrSections.PRESCRIPTIONS,
  '60593-1': hrSections.PRESCRIPTIONS,
  '67851-6': hrSections.HOSPITALSTAYS,
  '18761-7': hrSections.HOSPITALSTAYS,
  '60591-5': hrSections.MEDICALCARES,
  '70004-7': hrSections.MEDICALCARES,
  '75482-0': hrSections.MEDICALCARES,
  '75492-9': hrSections.MEDICALCARES,
  '75497-8': hrSections.MEDICALCARES,
  '77436-4': hrSections.MEDICALCARES,
  '80565-5': hrSections.MEDICALCARES,
  '85208-7': hrSections.MEDICALCARES,
};
