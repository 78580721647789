import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import { insTypes } from '../../constants/dmpConstants';

export const vitaleCardStatuses = {
  MISSING_CARD: 1,
  REAL_CARD: 3,
  TEST_CARD: 4,
  DEMO_CARD: 5,
};

export const insTypeByVitaleCardStatus = {
  [vitaleCardStatuses.DEMO_CARD]: [insTypes.DEMO_INS],
  [vitaleCardStatuses.REAL_CARD]: [insTypes.REAL_INS],
  [vitaleCardStatuses.TEST_CARD]: [insTypes.TEST_INS],
};

export const getQualifiedIns = (nir, vitaleCardStatus) => (nir ? `${nir.replace(/ /g, '')}${insTypeByVitaleCardStatus[vitaleCardStatus]}` : undefined);

export const getSelectedVitaleCardPatientFromCertifiedIns = (certifiedIdentitySection, vitaleCardSection, ins) => {
  if (!vitaleCardSection || !isReady(vitaleCardSection)) return false;

  const selectedPatientIndex = Object.entries(certifiedIdentitySection).filter((patient) => {
    const values = patient[1];
    const { Ins } = values;
    if (!Ins) return false;
    const { s_ins, s_insType } = Ins;
    const qualifiedIns = s_ins + s_insType;
    return ins === qualifiedIns;
  }).map(value => value[0].split('_')[1]);

  if (selectedPatientIndex.length === 0) return false;
  const { Patients } = vitaleCardSection;
  return Patients[Number(selectedPatientIndex[0])];
};

export const getVitaleCardDataFromIndex = (vitaleCardSection, vitaleIndex) => {
  if (isReady(vitaleCardSection)) {
    const { Patients } = vitaleCardSection;
    return Patients[vitaleIndex];
  }
  return null;
};

export const apCvContextToVitaleData = (apCvContext) => {

};
