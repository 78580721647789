import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

export default function Popable({
  popup,
  shown,
  title,
  children,
  footer,
  footerClassName,
  handleClose,
  card,
  ...params
}) {
  if (popup !== true && card !== true) return children;
  if (card === true) {
    return (
      <>
        <Card>
          <Card.Body>{children}</Card.Body>
          {footer && <Card.Footer>{footer}</Card.Footer>}
        </Card>
      </>
    );
  }

  return (
    <>
      <Modal
        {...params}
        show={shown}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {footer && <Modal.Footer className={footerClassName}>{footer}</Modal.Footer>}
      </Modal>
    </>
  );
}

Popable.propTypes = {
  popup: PropTypes.bool.isRequired,
  shown: PropTypes.bool,
  title: PropTypes.node.isRequired,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
  card: PropTypes.bool,
  footerClassName: PropTypes.string,
};

Popable.defaultProps = {
  shown: false,
  footer: null,
  card: false,
  handleClose: null,
  footerClassName: '',
};
