import { apiErrors, dmpErrors, jwtErrors } from 'dmpconnectjsapp-base/errors';

export const jsonWenTokenErrorsToJwtErrors = {
  TokenExpiredError: jwtErrors.EXPIRED_TOKEN,
};

export const remoteErrors = {
  UNKNONW_ACTION: 'UnknownAction',
  INVALID_FORMAT: 'InvalidFormat',
  NO_PS_LOGGED_IN: 'NoHPConnected',
  INVALID_INS: 'InvalidINS',
  REJECTED_BY_USER: 'RejectedByUser',
  NOT_ENOUGH_PARAMS: 'NotEnoughParameters',
  DMP_FOUND: 'DmpAlreadyExist',
  DMP_ERROR: 'DMPError',
  REFUSED_ACTION: 'RefusedAction',
  DMP_NOT_CONNECTED: 'DmpNotConnected',
  BAD_CONFIDENTIALITY: 'DocumentBadConfidentiality',
  DMP_NOT_FOUND: 'DMPNotFound',
  INVALID_INS_TYPE: 'InvalidINSType',
  MSS_ERROR: 'MSSError',
};

export const dmpToRemoteErrors = {
  [dmpErrors.DMPFound]: remoteErrors.DMP_FOUND,
  [dmpErrors.DMPTooManyResult]: remoteErrors.DMP_ERROR,
  [dmpErrors.DMPIdentityCertificationFailure]: remoteErrors.DMP_ERROR,
};

export const apiToRemoteErrors = {
  [apiErrors.INVALID_PARAMETER]: remoteErrors.INVALID_FORMAT,
  [apiErrors.INVALID_ins]: remoteErrors.INVALID_INS,
};

export const openIDErrors = {
  // auth
  invalid_client: 'invalid_client',
  invalid_uri: 'invalid_uri',
  redirect_uri_mismatch: 'redirect_uri_mismatch',
  not_allowed: 'not_allowed',
  consent_required: 'consent_required',
  missing_auth_callback_value: 'missing_auth_callback_value',

  // token
  invalid_request: 'invalid_request',
  unsupported_grant_type: 'unsupported_grant_type',
  invalid_grant: 'invalid_grant',
  unauthorized_client: 'unauthorized_client',
  invalid_scope: 'invalid_scope',
  invalid_redirect_uri: 'invalid_redirect_uri',
  invalid_token: 'invalid_token',
  login_check_failed: 'login_check_failed',
  missing_login_check: 'missing_login_check',
  unmatched_state: 'unmatched_state',
  unknown: 'unknown',


};

export const httpErrors = {
  failed_to_fetch: 'failed_to_fetch',
}
