import { apiSections }      from 'dmpconnectjsapp-base/constants';
import PropTypes            from 'prop-types';
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import checkAccessRights    from '../AccessRights/CheckAccessRights';
import Alert                from '../Common/Message/Alert';
import InsManualSearch      from '../TLSi/INSi/InsManualSearch';


class AccessByINSSearch extends Component {
  
  render() {
    const { selectIns, isAnonymousCpe, findPatientsInsParams, showUploadForm, selector } = this.props;
    return (
      <>
        {isAnonymousCpe ? (
          <Alert type="warning">
            Ce téléservice n'est accessible qu'aux cartes CPE nominatives et aux cartes CPS.
            <br/>
            <br/>
            Pour commander une carte CPE à votre nom vous pouvez passer par le service
            {' '}
            <a href="https://tops.eservices.esante.gouv.fr/tops/pageAccueil/accueil.html" target="_blank" rel="noreferrer noopener">TOPS</a>
            {' '}
            en vous connectant avec la carte du Professionnel de Sante.
            <br/>
            Pour plus de renseignements sur ce service,
            {' '}
            <a href="https://esante.gouv.fr/commande-de-carte-TOPS" target="_blank" rel="noreferrer noopener">accédez au guide</a>
          </Alert>
        ) : (
           <>
             <h4>Recherche d&apos;INS par traits d&apos;identité</h4>
             <Alert type="warning">
               La transaction de recherche de DMP par traits d'identité (TD 0.5) n'étant plus disponible depuis le 12/06/2024, nous l'avons
               remplacée par la recherche d'INS par traits stricts.
             </Alert>
             <InsManualSearch
               selectIns={ins => selectIns(ins)}
               showTitle={false}
               defaultValues={findPatientsInsParams}
               showUploadForm={showUploadForm}
               selector={selector}
             />
           </>
         )}
      </>
    );
  }
}

AccessByINSSearch.propTypes    = {
  selectIns            : PropTypes.func.isRequired,
  open                 : PropTypes.bool.isRequired,
  dispatch             : PropTypes.func.isRequired,
  isAnonymousCpe       : PropTypes.bool,
  findPatientsInsParams: PropTypes.object,
  showUploadForm       : PropTypes.func,
  selector             : PropTypes.bool,
};
AccessByINSSearch.defaultProps = {
  isAnonymousCpe       : false,
  findPatientsInsParams: {},
  showUploadForm       : null,
  selector             : false,
};

function mapStateToProps(state) {
  const
    {
      dmpconnect    : {
        [apiSections.CPX_CARD_SECTION]: cpxCard,
      },
      dmpconnectUser: {
        findPatientsInsParams
      }
    } = state;
  
  return {
    isAnonymousCpe: cpxCard.i_isAnonymousCpe === 1,
    findPatientsInsParams,
  };
}

const connectedAccessByINSSearch = connect(mapStateToProps)(AccessByINSSearch);

export default checkAccessRights(connectedAccessByINSSearch);
