export const informantType = {
  informant: 1,
  emergencyContact: 2,
  trustContact: 3,
  unknown: 4,
};

export const informantRelationType = {
  mother: 1,
  father: 2,
  boliogicalMother: 3,
  biologicalFather: 4,
  stepMother: 5,
  stepFather: 6,
  grandMother: 7,
  grandFather: 8,
  greatGrandMother: 9,
  greatGrandFather: 10,
  boliogicalDaughter: 11,
  biologicalSon: 12,
  brother: 13,
  sister: 14,
  stepBrother: 15,
  stepSister: 16,
  grandDaughter: 17,
  grandSon: 18,
  uncle: 19,
  aunt: 20,
  nevew: 21,
  niece: 22,
  husband: 23,
  wife: 24,
  partner: 25,
  homie: 26,
  close: 27,
  neighboor: 28,
  familyMember: 29,
  child: 30,
  cousin: 31,
  marriagePartner: 32,
  daughter: 33,
  son: 34,
  guardian: 35,
  curator: 36,
  safeguard: 37,
  unknown: 38,
};

export const informantAddressType = {
  home: 1,
  mainHome: 2,
  vacancyHome: 3,
  workPlace: 4,
  temporaryAddress: 5,
  unknown: 6,
};

export const informantAddressStreetNameType = {
  ally: 1,
  avenue: 2,
  boulevard: 3,
  center: 4,
  mall: 5,
  apartmentBlock: 6,
  deadEnd: 7,
  hamlet: 8,
  housingEstate: 9,
  pathway: 10,
  place: 11,
  residence: 12,
  road: 14,
  street: 15,
  square: 16,
  village: 17,
  activityZone: 18,
  concertedDevelopmentZone: 19,
  futureDevelopmentZone: 20,
  industrialZone: 21,
  unknown: 22,

};

export const informantTelecomType = {
  phone: 1,
  fax: 2,
  email: 3,
  http: 4,
  ftp: 5,
  mllp: 6,
  unknonw: 7,
};
