import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { extractDate, formatDate } from 'dmpconnectjsapp-base/helpers/accessors';
import { generateId } from '../../../dmpconnect/utils/dataUtils';

class VitaleCardDateNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      isYYYYMMDDValid: true,
      formattedDateYYYYMMDD: '',
    };
  }

  componentDidMount() {
    const { vitaleCardDate, formattedVitaleCardDateYYYYMMDD } = this.props;
    const dateVitaleCard = extractDate(vitaleCardDate, 'DDMMYYYY');
    const formattedDate = formatDate(dateVitaleCard, 'YYYYMMDD');

    this.setState({ isValid: formattedDate === formattedVitaleCardDateYYYYMMDD });


    const dateYYYYMMDD = extractDate(formattedVitaleCardDateYYYYMMDD, 'YYYYMMDD');
    const formattedDateYYYYMMDD = formatDate(dateYYYYMMDD, 'DD/MM/YYYY');
    this.setState({ isYYYYMMDDValid: dateYYYYMMDD.isValid(), formattedDateYYYYMMDD });
  }

  render() {
    const { isValid, isYYYYMMDDValid, formattedDateYYYYMMDD } = this.state;
    const { vitaleCardDate } = this.props;

    return (
      <>
        {!isValid && (
        <OverlayTrigger
          placement="bottom"
          delay={200}
          overlay={(
            <Popover id={`birthDateNotice${generateId(5)}`}>
              <Popover.Title as="h3">Date de naissance rectifiée</Popover.Title>
              <Popover.Content>
                <table>
                  <tbody>
                    <tr>
                      <td>Date lue</td>
                      <td>
                        {': '}
                        {vitaleCardDate}
                      </td>
                    </tr>
                    {isYYYYMMDDValid && (
                      <tr>
                        <td>Date rectifiée</td>
                        <td>
                          {': '}
                          {formattedDateYYYYMMDD}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Popover.Content>
            </Popover>
          )}
        >
          <span className="la la-exclamation-circle ml-2" />
        </OverlayTrigger>
        )}
      </>
    );
  }
}

VitaleCardDateNotice.propTypes = {
  vitaleCardDate: PropTypes.string.isRequired,
  formattedVitaleCardDateYYYYMMDD: PropTypes.string.isRequired,
};

export default VitaleCardDateNotice;
