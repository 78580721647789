import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../Message/ErrorMessage';
import Loading from '../Loading/Loading';
import SuccessMessage from '../Message/SuccessMessage';
import { isModalError } from '../../../dmpconnect/helpers/error';


export default function SectionStatusWrapper({
  initialisation,
  loading,
  error,
  forceErrorMessageIfModal,
  success,
  initialisationContent,
  loadingContent,
  errorContent,
  successContent,
  children,
  showChildrenOnError,
  showChildrenOnSuccess,
}) {
  return (
    <>
      {initialisation && (
        initialisationContent || <Loading message="Initialisation ..." />
      )}
      {loading && (
        loadingContent || <Loading message="Chargement ..." />
      )}
      {error && (!isModalError(error) || forceErrorMessageIfModal) && (
        errorContent || <ErrorMessage error={error} showDetails />
      )}
      {success && (
        successContent || <SuccessMessage />
      )}
      {(
        !initialisation
        && !loading
        && (!error || showChildrenOnError)
        && (!success || showChildrenOnSuccess)
      ) && children}
    </>
  );
}

SectionStatusWrapper.propTypes = {
  initialisation: PropTypes.bool,
  loading: PropTypes.bool,
  forceErrorMessageIfModal: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  success: PropTypes.bool,
  initialisationContent: PropTypes.element,
  loadingContent: PropTypes.element,
  errorContent: PropTypes.element,
  successContent: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  showChildrenOnError: PropTypes.bool,
  showChildrenOnSuccess: PropTypes.bool,
};

SectionStatusWrapper.defaultProps = {
  initialisation: false,
  loading: false,
  forceErrorMessageIfModal: false,
  error: null,
  success: false,
  initialisationContent: null,
  loadingContent: null,
  errorContent: null,
  successContent: null,
  showChildrenOnError: false,
  showChildrenOnSuccess: false,
  children: undefined,
};
