import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { initialValues, validationSchema } from '../../../dmpconnect/helpers/insi/manualSearch';
import { sexes } from '../../../dmpconnect/constants/dmpConstants';
import InputDate from '../../Common/Form/Input/InputDate';
import ButtonWithLoader from '../../Common/Form/ButtonWithLoader';
import TitleTooltip from '../../Common/TitleTooltip';

const InsManualSearchForm = ({
  loading, onSubmit, defaultValues,
}) => (
  <Formik
    validationSchema={validationSchema}
    initialValues={defaultValues || initialValues}
    onSubmit={values => onSubmit(values)}
    isInitialValid={validationSchema.isValidSync(defaultValues || initialValues)}
  >
    {(
      {
        handleSubmit,
        handleChange,
        values,
        errors,
        isValid,
      },
    ) => (
      <Form onSubmit={handleSubmit}>
        <Row className="mt-2">
          <Col>
            <Form.Group controlId="insName">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                className="text-uppercase"
                size="sm"
                onChange={handleChange}
                isInvalid={!!errors.name}
                name="name"
                value={values.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="insGiven">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                className="text-uppercase"
                size="sm"
                onChange={handleChange}
                isInvalid={!!errors.given}
                name="given"
                value={values.given}
                placeholder="Prénom"
              />
              <Form.Control.Feedback type="invalid">{errors.given}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form.Group controlId="insSex">
              <Form.Label>Sexe</Form.Label>
              <Form.Control
                size="sm"
                as="select"
                value={values.sex}
                onChange={handleChange}
                name="sex"
                isInvalid={!!errors.sex}
              >
                {sexes.map(sexe => (
                  <option
                    key={sexe.value}
                    value={sexe.value}
                  >
                    {sexe.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="insBirthday">
              <Form.Label>Date de naissance</Form.Label>
              <InputDate
                name="birthday"
                size="sm"
                value={values.birthday}
                isValid={!errors.birthday}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.birthday}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="insBirthplace">
              <Form.Label>
                Lieu de naissance
                <TitleTooltip
                  placement="right"
                  text="Code Officiel Géographique de l'INSEE"
                  id="cog_insee"
                >
                  <i className="la la-question-circle" />
                </TitleTooltip>
              </Form.Label>
              <Form.Control
                name="birthplace"
                size="sm"
                value={values.birthplace}
                isInvalid={!!errors.birthplace}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{errors.birthplace}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="text-right mb-3">
          <ButtonWithLoader
            type="submit"
            size="sm"
            disabled={!isValid}
            loading={loading}
            label="Obtenir l'INS"
            loadingLabel="Obtenir l'INS"
          />
        </div>
      </Form>
    )}
  </Formik>
);

InsManualSearchForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

InsManualSearchForm.defaultProps = {
  loading: false,
  defaultValues: undefined,
};

export default InsManualSearchForm;
