import { documentStatuses, searchParamsToReferenceIds } from 'dmpconnectjsapp-base/helpers/findDocuments';
import moment                                           from 'moment';
import * as yup                                         from 'yup';
import '../../utils/formUtils';
import { insTypeToOid }                                 from '../../constants/dmpConstants';
import { calculateVisibility, canConsultDocument }      from '../../rules/documentRules';

export const documentStatusesLabel = {
  [documentStatuses.ALL]       : 'Tous',
  [documentStatuses.APPROVED]  : 'Approuvé',
  [documentStatuses.DEPRECATED]: 'Obsolète',
  [documentStatuses.ARCHIVED]  : 'Archivé',
};

export const findDocumentByUniqueUUid = (documents, uniqueUUid) => documents[uniqueUUid] || {};
export const findDocumentByUniqueId   = (documents, uniqueId, exeptUuid = null) => {
  const docArray = Object.values(documents);
  return docArray.filter(document => document.s_uniqueId === uniqueId && (!exeptUuid || (exeptUuid && document.s_uuid !== exeptUuid)));
};
export const findNewDocuments         = (documents) => {
  const docArray = Object.values(documents);
  return docArray.filter(document => document.isNew === true);
};

export const getDocumentData = (documentContent) => {
  if (documentContent && documentContent.s_status === 'OK') {
    return documentContent.s_fileContentInBase64;
  }
  return null;
};
export const getCdaContent   = (documentContent) => {
  if (documentContent && documentContent.s_status === 'OK') {
    return documentContent.s_cdaContentInBase64;
  }
  return null;
};

export const getCdaHeaders = (documentContent) => {
  if (documentContent && documentContent.s_status === 'OK') {
    return documentContent.s_cdaHeadersInBase64;
  }
  return null;
};

export const isMetaCategorySelected = (selectedCategories, metaCategory) => {
  if (!metaCategory || !selectedCategories) {
    return false;
  }
  
  const { types: childrenCategories } = metaCategory;
  
  return childrenCategories.some(category => selectedCategories.includes(category.code));
};

export const getFilteredMetaCategoriesIndex = (metaCategories, selectedCategories) => {
  const selectedMetas = [];
  if (!metaCategories) {
    return selectedMetas;
  }
  metaCategories.forEach((meta, index) => {
    if (isMetaCategorySelected(selectedCategories, meta)) {
      selectedMetas.push(index);
    }
  });
  return selectedMetas;
};

export const isMetaCategoryFullyFiltered = (selectedCategories, metaCategory) => {
  if (!metaCategory || !selectedCategories) {
    return false;
  }
  
  const { types: childrenCategories = [] } = metaCategory;
  
  return childrenCategories.every(category => selectedCategories.includes(category.code));
};

export const getFullyFilteredMetaCategoriesIndex = (metaCategories, selectedCategories) => {
  const selectedMetas = [];
  if (!metaCategories) {
    return selectedMetas;
  }
  metaCategories.forEach((meta, index) => {
    if (isMetaCategoryFullyFiltered(selectedCategories, meta)) {
      selectedMetas.push(index);
    }
  });
  return selectedMetas;
};

const formatDate    = (date) => {
  let completeDate                       = date;
  const { groups: { day, month, year } } = date.match(/(?<day>[0-9]{2})\/(?<month>[0-9]{2})\/(?<year>[0-9]{4})(?<hour>.*)/);
  if (day === '00' && month === '00') {
    completeDate = `01/01/${year}00:00:00`;
  } else if (day === '00') {
    completeDate = `01/${month}/${year}00:00:00`;
  }
  return moment.utc(completeDate, 'DD/MM/YYYYHH:mm:ss');
};
const getDateFilter = (columnName, startDate, startDateHour, endDate, endDateHour) => ({
  id   : columnName,
  type : 'date',
  value: {
    datemin: startDate
             ? formatDate(startDate + startDateHour)
             : moment(0),
    datemax: endDate
             ? formatDate(endDate + endDateHour)
             : moment(),
  },
});

export const findDocumentParamsToFilters = (params) => {
  const
    {
      status,
      submissionStartDate,
      submissionStartDateHour,
      submissionEndDate,
      submissionEndDateHour,
      creationStartDate,
      creationStartDateHour,
      creationEndDate,
      creationEndDateHour,
      actStartDate,
      actStartDateHour,
      actEndDate,
      actEndDateHour,
      patientHidden,
      guardianHidden,
      healthcareProfessionalHidden,
      categories,
      title,
      author,
      specialty,
      globalSearch,
      anatomyImagery,
      modalityImagery,
      ...referenceIdsParams
    } = params;
  
  const filters = [];
  
  filters.push({
    id            : 'i_document_Visibility',
    type          : 'value',
    value         : calculateVisibility(patientHidden, healthcareProfessionalHidden, guardianHidden),
    originalValues: { patientHidden, healthcareProfessionalHidden, guardianHidden },
  });
  filters.push({ id: 's_typeCode', type: 'array', value: categories });
  if (creationStartDate || creationEndDate) {
    filters.push({
      ...getDateFilter('creationDate',
        creationStartDate,
        creationStartDateHour,
        creationEndDate,
        creationEndDateHour),
      originalValues: {
        creationStartDate,
        creationStartDateHour,
        creationEndDate,
        creationEndDateHour,
      },
    });
  }
  if (actStartDate || actEndDate) {
    filters.push({
      ...getDateFilter('serviceStopDate',
        actStartDate,
        actStartDateHour,
        actEndDate,
        actEndDateHour),
      originalValues: {
        actStartDate,
        actStartDateHour,
        actEndDate,
        actEndDateHour,
      },
    });
  }
  if (submissionStartDate || submissionEndDate) {
    filters.push({
      ...getDateFilter('submissionDate',
        submissionStartDate,
        submissionStartDateHour,
        submissionEndDate,
        submissionEndDateHour),
      originalValues: {
        submissionStartDate,
        submissionStartDateHour,
        submissionEndDate,
        submissionEndDateHour,
      },
    });
  }
  filters.push({
    id: 'i_document_Status', type: 'value', value: status, originalValues: { status },
  });
  filters.push({
    id: 's_title', type: 'string', value: title, originalValues: { title },
  });
  filters.push({
    id: 'Authors', type: 'authors', value: author, originalValues: { author },
  });
  filters.push({
    id: 'specialty', type: 'specialty', value: specialty, originalValues: { specialty },
  });
  filters.push({
    id: 'globalSearch', type: 'global', value: globalSearch, originalValues: { globalSearch },
  });
  
  if (anatomyImagery || modalityImagery) {
    try {
      const anatomy  = anatomyImagery ? JSON.parse(anatomyImagery) : [];
      const modality = modalityImagery ? JSON.parse(modalityImagery) : [];
      
      filters.push({
        id            : 'EventCodes',
        type          : 'eventCodes',
        value         : [
          ...(anatomy || []),
          ...(modality || []),
        ],
        originalValues: { anatomyImagery, modalityImagery }
      });
    } catch (e) {
      console.log('error parsing EventCodes', e.message, anatomyImagery, modalityImagery);
    }
  }
  
  const refIds = searchParamsToReferenceIds(referenceIdsParams);
  if (refIds && refIds.length > 0) {
    filters.push({
      id            : 'ReferenceIds',
      type          : 'referenceIds',
      value         : refIds,
      originalValues: { ...referenceIdsParams }
    });
  }
  
  return filters;
};

export const filterDocument = (filter, document) => {
  const {
          id, value = null, datemin = null, datemax = null, array, type,
        } = filter;
  
  // TODO : handle eventCodes filter if we add it to DocumentTable
  if (type === 'eventCodes') {
    // i_eventCodeClassification(pin):4
    // i_handle(pin):525713
    // s_eventCodeCode(pin):"24727-0"
    // s_eventCodeDescription(pin):"CT tête avec contraste IV"
    // return !array || document.EventCodes.some(e => e.s_id === value.)
  }
  
  if (type === 'array') {
    return !array || array.includes(document[id]);
  }
  if (type === 'value' && value && value !== 0) {
    return document[id] === value;
  }
  if (type === 'date' && (datemin || datemax)) {
    const momentValue = moment.utc(document[id], 'YYYYMMDDHHmmss');
    return momentValue.isBetween(datemin, datemax, null, '[]');
  }
  if (type === 'string' && value) {
    return document[id].toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }
  if (type === 'authors' && value) {
    return document.Authors.findIndex((author) => {
      const authorName = author.s_hpGiven.concat(' ').concat(author.s_hpName).toLowerCase();
      return authorName.indexOf(value.toLowerCase()) !== -1;
    }) !== -1;
  }
  if (type === 'specialty' && value) {
    return document.Authors.some(author => author.s_hpSpeciality === value);
  }
  
  return true;
};

export const getCategoryCounts = (metaCategories, documents) => {
  if (documents === undefined) {
    return metaCategories;
  }
  const categoryCount = Object.values(documents).reduce((acc, curr) => {
    acc[curr.s_typeCode] = acc[curr.s_typeCode] || { count: 0, new: 0 };
    acc[curr.s_typeCode].count += 1;
    if (curr.isNew === true) {
      acc[curr.s_typeCode].new += 1;
    }
    return acc;
  }, {});
  
  const metaCategoriesCount = [];
  
  metaCategories.forEach((meta, index) => {
    // JSON.parse(JSON.stringify(meta)) pour copier l'objet sans référence
    metaCategoriesCount[index] = { ...JSON.parse(JSON.stringify(meta)), count: 0, new: 0 };
    
    meta.types.forEach((category, indexCat) => {
      const count                                = categoryCount[category.code] || { count: 0, new: 0 };
      metaCategoriesCount[index].types[indexCat] = { ...category, ...count };
      metaCategoriesCount[index].count += count.count;
      metaCategoriesCount[index].new += count.new;
    });
  });
  
  return metaCategoriesCount;
};

export const getFilteredDocuments   = (documents, params, accessRights) => {
  const filters = findDocumentParamsToFilters(params);
  return Object.values(documents || {}).filter(document => (
    canConsultDocument(document, accessRights)
    && filters.every(filter => filterDocument(filter, document))
  ));
};
export const getFindDocumentsParams = (state) => {
  const { dmpconnectUser: { findDocumentsParams: params } } = state;
  return { ...params };
};

export const getFindDocumentsLastSearchTimeStamp = (state, ins) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        timestamp: lastSearchTimestamp = null,
      } = {},
    },
  } = state;
  return lastSearchTimestamp;
};

export const imagerySearchParamsEndpointValidator = yup.object({
  Ins               : yup.object({
    nir: yup.string().length(15, 'Le NIR doit contenir 15 caractères.').required('Le NIR est manquant'),
    oid: yup.string()
      .oneOf(Object.values(insTypeToOid), ({ values }) => `Le type d'INS doit être une des valeurs suivantes : ${values}`)
      .required('Le type d\'INS est manquant'),
  }).required('L\'INS est manquant'),
  references        : yup.object({
    accessionNumber : yup.object({
      id : yup.string().required('L\'identifiant de l\'accessionNumber est manquant'),
      oid: yup.string().required('L\'oid de l\'autorité ayant émis l\'accessionNumber est manquant'),
    }).default(undefined),
    orderNumber     : yup.object({
      id : yup.string().required('L\'identifiant de l\'orderNumber est manquant'),
      oid: yup.string().required('L\'oid de l\'autorité ayant émis l\'orderNumber est manquant'),
    }).default(undefined),
    studyInstanceUID: yup.object({
      id : yup.string().required('L\'identifiant du studyInstanceUID est manquant'),
      oid: yup.string().required('L\'oid de l\'autorité ayant émis le studyInstanceUID est manquant'),
    }).default(undefined),
  }).default(undefined),
  EventCodes        : yup.array().of(yup.object({
    id : yup.string().required('L\'identifiant de l\'eventCode est manquant'),
    oid: yup.string().required('L\'oid de l\'eventCode est manquant'),
  })).nullable(),
  actDateFrom       : yup.string().date().nullable(),
  actDateTo         : yup.string().date().nullable(),
  submissionDateFrom: yup.string().date().nullable(),
  submissionDateTo  : yup.string().date().nullable(),
});
