import env from '../../envVariables';
import { ohifActionsConstants } from '../constants';

const initialState = {
  documentKOSTitleTrigger: '',
  ohifKosViewerUrlDebugMode: 'none',
  ohifKosViewerHost: env.REACT_APP_OHIF_VIEWER_HOST,
  ohifKosViewerUrl: `${env.REACT_APP_OHIF_VIEWER_HOST}/viewer?StudyInstanceUIDs=1.3.6.1.4.1.14519.5.2.1.1706.8374.643249677828306008300337414785&initialSeriesInstanceUID=1.3.6.1.4.1.14519.5.2.1.1706.8374.134469846969371865041508269759`,
};

export function ohifConfig(state = initialState, action) {
  switch (action.type) {
    case ohifActionsConstants.SET_OHIF_CONFIG:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
}
