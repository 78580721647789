export const extractJSONGetMssInfosParams = (data) => {
  const {
    name,
    given,
    rpps,
    specialty,
    organization,
    query: {
      type,
      attributeName,
      attributeValue,
      operand1: {
        type: operand1Type,
        attributeName: operand1AttributeName,
        attributeValue: operand1AttributeValue,
      } = {},
      operand2: {
        type: operand2Type,
        attributeName: operand2AttributeName,
        attributeValue: operand2AttributeValue,
      } = {},
    } = {},
  } = data;

  return {

    name,
    given,
    rpps,
    specialty,
    organization,

    type,
    attributeName,
    attributeValue,

    operand1Type,
    operand1AttributeName,
    operand1AttributeValue,

    operand2Type,
    operand2AttributeName,
    operand2AttributeValue,
  };
};

export const extractGetMssInfosParams = (data) => {
  const {
    name: { value: name } = {},
    given: { value: given } = {},
    rpps: { value: rpps } = {},
    specialty: { value: specialty } = {},
    organization: { value: organization } = {},
    query: {
      type: { value: type } = {},
      attributeName: { value: attributeName } = {},
      attributeValue: { value: attributeValue } = {},
      operand1: {
        type: { value: operand1Type } = {},
        attributeName: { value: operand1AttributeName } = {},
        attributeValue: { value: operand1AttributeValue } = {},
      } = {},
      operand2: {
        type: { value: operand2Type } = {},
        attributeName: { value: operand2AttributeName } = {},
        attributeValue: { value: operand2AttributeValue } = {},
      } = {},
    } = {},
  } = data;

  return {

    name,
    given,
    rpps,
    specialty,
    organization,

    type,
    attributeName,
    attributeValue,

    operand1Type,
    operand1AttributeName,
    operand1AttributeValue,

    operand2Type,
    operand2AttributeName,
    operand2AttributeValue,
  };
};

export const formatQuery = (params) => {
  const {
    type,
    attributeName,
    attributeValue,

    operand1Type,
    operand1AttributeName,
    operand1AttributeValue,

    operand2Type,
    operand2AttributeName,
    operand2AttributeValue,
  } = params;

  if (!type) return undefined;

  return {
    Query: {
      s_type: type,
      s_attributeName: attributeName,
      s_attributeValue: attributeValue,
      Operand1: operand1Type
        ? {
          s_type: operand1Type,
          s_attributeName: operand1AttributeName,
          s_attributeValue: operand1AttributeValue,
        }
        : undefined,
      Operand2: operand2Type
        ? {
          s_type: operand2Type,
          s_attributeName: operand2AttributeName,
          s_attributeValue: operand2AttributeValue,
        }
        : undefined,
    },
  };
};
