import { getError, hasError, isReady }                           from 'dmpconnectjsapp-base/helpers/common';
import PropTypes                                                 from 'prop-types';
import React, { useState }                                       from 'react';
import Button                                                    from 'react-bootstrap/Button';
import Spinner                                                   from 'react-bootstrap/Spinner';
import { toast }                                                 from 'react-toastify';
import { getErrorDescription }                                   from '../../../dmpconnect/errors';
import { getQualifiedInsINSi }                                   from '../../../dmpconnect/helpers/certifiedIdentity';
import { resultToHtml, resultToJS, resultToJson, resultToText, } from '../../../dmpconnect/helpers/insi/result';
import { copyToClipboard }                                       from '../../../dmpconnect/utils/clipboard';
import ButtonWithLoader                                          from '../../Common/Form/ButtonWithLoader';
import Loading                                                   from '../../Common/Loading/Loading';
import Alert                                                     from '../../Common/Message/Alert';
import ErrorMessage                                              from '../../Common/Message/ErrorMessage';
import TitleTooltip                                              from '../../Common/TitleTooltip';
import DMPStatusActions                                          from '../../DMPStatus/DMPStatusActions';
import DMPStatusExtraActions                                     from '../../DMPStatus/DMPStatusExtraActions';
import DMPStatusProvider                                         from '../../DMPStatus/DMPStatusProvider';

export default function INSiResult({
  certifiedInsLoading,
  certifiedIdentityResult,
  newSearchCallback,
  resultExportUrl,
  exportId,
  hideNewSearchButton,
  hideCopyInsButton,
  hideCopyAllButton,
  hideExportButton,
  autoExportToServer,
  exportLabel,
  exportFullLabel,
  accessDmp,
  showAccessDmpButton,
  showUploadForm,
  mssActive,
  selector,
}) {
  const [insCopySuccess, setInsCopySuccess] = useState(0);
  const [allCopySuccess, setAllCopySuccess] = useState(0);
  const [exportSuccess, setExportSuccess]   = useState(0);
  const [exportLoading, setExportLoading]   = useState(false);
  const [content, setContent]               = useState();
  
  
  const copyInsToClipBoard = async () => {
    try {
      // eslint-disable-next-line no-undef
      await copyToClipboard(certifiedIdentityResult.Identity.Ins.s_value + certifiedIdentityResult.Identity.Ins.s_key);
      setInsCopySuccess(1);
    } catch (err) {
      console.log('clipboard error', err, err.message);
      setInsCopySuccess(-1);
    }
  };
  
  const exportToServer = async () => {
    setExportLoading(true);
    toast((
      <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-3"
        />
        Export du résultat en cours ...
      </>
    ), { autoClose: false, toastId: 'export_to_server' });
    fetch(resultExportUrl, {
      method : 'post',
      headers: {
        Accept        : 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body   : resultToJson({ ...certifiedIdentityResult, exportId }),
    })
      .then((res) => {
        if (res.ok) {
          setExportSuccess(1);
          toast.update('export_to_server',
            {
              render         : 'Le résultat a été exporté avec succès',
              type           : 'success',
              position       : 'top-right',
              closeOnClick   : true,
              autoClose      : true,
              hideProgressBar: true,
              draggable      : false,
            });
        } else {
          const erreur  = (
            <>
              {' HTTP '}
              {res.status}
              {' '}
              -
              {' '}
              {res.statusText}
            </>
          );
          const message = 'Une erreur s\'est produite pendant l\'export';
          res.json().then((json) => {
              toast.update('export_to_server',
                {
                  render         : (
                    <div>
                      {json.message || message}
                      <br/>
                      {json.erreur || erreur}
                    </div>
                  ),
                  type           : 'error',
                  position       : 'top-right',
                  closeOnClick   : true,
                  autoClose      : 10000,
                  hideProgressBar: true,
                  draggable      : false,
                });
            })
            .catch(() => {
              toast.update('export_to_server',
                {
                  render         : (
                    <div>
                      {message}
                      <br/>
                      {erreur}
                    </div>
                  ),
                  type           : 'error',
                  position       : 'top-right',
                  closeOnClick   : true,
                  autoClose      : true,
                  hideProgressBar: true,
                  draggable      : false,
                });
            });
          
          setExportSuccess(-1);
        }
      })
      .catch(() => {
        toast.update('export_to_server',
          {
            render         : 'Erreur de connection pendant l\'export',
            type           : 'error',
            position       : 'top-right',
            closeOnClick   : true,
            autoClose      : true,
            hideProgressBar: true,
            draggable      : false,
          });
        setExportSuccess(-1);
      })
      .finally(() => setExportLoading(false));
  };
  
  const copyAllToClipBoard = async () => {
    try {
      // eslint-disable-next-line no-undef
      await copyToClipboard(resultToText({ ...certifiedIdentityResult, exportId }));
      setAllCopySuccess(1);
    } catch (err) {
      console.log('clipboard error', err, err.message);
      setAllCopySuccess(-1);
    }
  };
  
  const copyInsTooltip = {
    0   : 'Copier l\'INS - NIR + clé - dans le presse papier',
    1   : 'INS copié vers le presse-papier',
    [-1]: 'Erreur',
  };
  const copyAllTooltip = {
    0   : 'Copier tout le résultat vers le presse-papier',
    1   : 'Résultat copié vers le presse-papier',
    [-1]: 'Erreur',
  };
  const exportTooltip  = {
    0   : 'Exporter le résultat vers le serveur',
    1   : 'Résultat exporté',
    [-1]: 'Erreur pendant l\'export',
  };
  const copyIcon       = {
    0   : <i className="la la-copy"/>,
    1   : <i className="la la-clipboard-check"/>,
    [-1]: <i className="la la-times"/>,
  };
  
  React.useEffect(() => {
    let tmpContent;
    if (isReady(certifiedIdentityResult) || hasError(certifiedIdentityResult)) {
      window.parent.postMessage(resultToJS({ ...certifiedIdentityResult, exportId }), '*');
      if (resultExportUrl && autoExportToServer && !exportSuccess && !exportLoading) {
        exportToServer();
      }
    }
    if (certifiedInsLoading === true) {
      tmpContent = <Loading centered message="Obtention de l'INS en cours ..."/>;
    } else if (hasError(certifiedIdentityResult)) {
      const error     = getError(certifiedIdentityResult);
      // if (!isModalError(certifiedIdentityResult)) {
      const errorDesc = getErrorDescription(error);
      tmpContent      = (
        <ErrorMessage
          error={error}
          title={error.s_apiErrorDescription}
          // message={error.s_apiErrorTlsiErrorSoapReason}
          showDetails={errorDesc.showDetails}
        />
      );
      // }
    } else if (isReady(certifiedIdentityResult)) {
      let exportButtonLabel = 'Exporter tout';
      if (exportFullLabel) {
        exportButtonLabel = exportFullLabel;
      } else if (exportLabel) {
        exportButtonLabel = `Export vers ${exportLabel}`;
      }
      
      tmpContent = (
        <>
          {certifiedIdentityResult.i_insIdentityResult === 2 && showAccessDmpButton && (
            <div id="insi-dmp-access">
              {!selector && <span className="h6 mb-0">Accéder au dossier patient : </span>}
              <DMPStatusProvider
                getOnMount
                showLoading
                certifiedIns={getQualifiedInsINSi(certifiedIdentityResult)}
              >
                <DMPStatusActions
                  accessDMP={ins => accessDmp(ins)}
                  aldActive={false}
                  selector={selector}
                  selectorButtonLabel="Sélectionner ce patient"
                />
              </DMPStatusProvider>
              {!selector && (
                <div className="float-right">
                  <DMPStatusProvider
                    getOnMount={false}
                    showLoading={false}
                    certifiedIns={getQualifiedInsINSi(certifiedIdentityResult)}
                  >
                    <DMPStatusExtraActions
                      showUploadForm={(ins) => showUploadForm([], ins)}
                      mssActive={mssActive}
                      showButtonLabels
                    />
                  </DMPStatusProvider>
                </div>
              )}
            </div>
          )}
          <div id="action" className="text-right">
            {certifiedIdentityResult.i_insIdentityResult === 2 && !hideCopyInsButton && (
              <TitleTooltip text={copyInsTooltip[insCopySuccess]} id="copyIns">
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => copyInsToClipBoard()}
                  onBlur={() => setInsCopySuccess(0)}
                  onMouseEnter={() => setInsCopySuccess(0)}
                >
                  {copyIcon[insCopySuccess]}
                  Copier l&apos;INS
                </Button>
              </TitleTooltip>
            )}
            
            {!hideCopyAllButton && (
              <TitleTooltip text={copyAllTooltip[allCopySuccess]} id="copyAll">
                <Button
                  className="ml-2"
                  size="sm"
                  variant="outline-primary"
                  onClick={() => copyAllToClipBoard()}
                  onBlur={() => setAllCopySuccess(0)}
                  onMouseEnter={() => setAllCopySuccess(0)}
                >
                  {copyIcon[allCopySuccess]}
                  Copier tout
                </Button>
              </TitleTooltip>
            )}
            {resultExportUrl !== null && !hideExportButton && (
              <TitleTooltip text={exportTooltip[exportSuccess]} id="copyIns">
                <ButtonWithLoader
                  className="ml-2"
                  size="sm"
                  variant="outline-primary"
                  onClick={() => exportToServer()}
                  label={(
                    <>
                      {copyIcon[exportSuccess]}
                      {exportButtonLabel}
                    </>
                  )}
                  loading={exportLoading}
                  disabled={exportLoading}
                  loadingLabel="Export en cours ..."
                />
              </TitleTooltip>
            )}
          </div>
          
          {certifiedIdentityResult.i_insIdentityResult === 1 && (
            <Alert type="danger" id="insi_result_no_id" className="mt-2">
              <div className="typography-big-text-title">Impossible d&apos;obtenir le numéro INS</div>
              Le serveur n&apos;a pas pu trouver les informations d&apos;identité du patient.
            </Alert>
          )}
          
          {certifiedIdentityResult.i_insIdentityResult === 3 && (
            <Alert type="danger" id="insi_result_more_id" className="mt-2">
              <div className="typography-big-text-title">Impossible d&apos;obtenir le numéro INS</div>
              Le serveur indique que plusieurs identités correspondent aux informations fournies.
            </Alert>
          )}
          
          {certifiedIdentityResult.i_insIdentityResult === 2 && resultToHtml(certifiedIdentityResult)}
          
          {!hideNewSearchButton && newSearchCallback && (
            <div className="text-center mt-3">
              <Button
                variant="primary"
                onClick={() => newSearchCallback()}
              >
                Nouvelle Recherche
              </Button>
            </div>
          )}
        </>
      );
    }
    setContent(tmpContent);
  }, [certifiedInsLoading,
      certifiedIdentityResult,
      exportLoading,
      allCopySuccess,
      insCopySuccess,
      exportSuccess,
      accessDmp,
      showAccessDmpButton]);
  
  return <>{content || 'Aucune identité n\'a été trouvée pour ce patient.'}</>;
}

INSiResult.propTypes = {
  certifiedIdentityResult: PropTypes.object.isRequired,
  newSearchCallback      : PropTypes.func,
  resultExportUrl        : PropTypes.string,
  certifiedInsLoading    : PropTypes.bool,
  exportId               : PropTypes.string,
  hideNewSearchButton    : PropTypes.bool,
  hideCopyInsButton      : PropTypes.bool,
  hideCopyAllButton      : PropTypes.bool,
  hideExportButton       : PropTypes.bool,
  autoExportToServer     : PropTypes.bool,
  exportLabel            : PropTypes.string,
  exportFullLabel        : PropTypes.string,
  showAccessDmpButton    : PropTypes.bool,
  mssActive              : PropTypes.bool,
  accessDmp              : PropTypes.func,
  showUploadForm         : PropTypes.func,
  selector               : PropTypes.bool,
};

INSiResult.defaultProps = {
  resultExportUrl    : null,
  certifiedInsLoading: false,
  exportId           : undefined,
  hideNewSearchButton: true,
  hideCopyAllButton  : true,
  hideCopyInsButton  : true,
  hideExportButton   : true,
  autoExportToServer : true,
  exportLabel        : '',
  exportFullLabel    : '',
  newSearchCallback  : null,
  showAccessDmpButton: false,
  accessDmp          : undefined,
  showUploadForm     : null,
  mssActive          : false,
  selector           : false,
};
