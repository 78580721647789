import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import { extractDate, formatDate } from 'dmpconnectjsapp-base/helpers/accessors';

function DMPDate({
  dmpDate, inputFormat, outputFormat, className,
}) {
  if (!dmpDate) return null;

  const date = extractDate(dmpDate, inputFormat);
  const formattedDate = formatDate(date, outputFormat);
  const isValid = date.isValid();

  return (
    <>
      {isValid ? (
        <span className={className}>
          {formattedDate}
        </span>
      ) : (
        <OverlayTrigger
          placement="right"
          delay={200}
          overlay={(
            <span className={`ml-2 text-danger ${className}`}>
              Date invalide
            </span>
            )}
        >
          <span className="text-danger">
            {dmpDate}
          </span>
        </OverlayTrigger>
      )}
    </>
  );
}

DMPDate.defaultProps = {
  inputFormat: 'YYYYMMDD',
  outputFormat: 'DD/MM/YYYY',
  dmpDate: null,
  className: null,
};

DMPDate.propTypes = {
  dmpDate: PropTypes.string,
  inputFormat: PropTypes.string,
  outputFormat: PropTypes.string,
  className: PropTypes.string,
};


export default DMPDate;
