import PropTypes from 'prop-types';
import React from 'react';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { connect } from 'react-redux';
import { checkDMPConnectJSVersiongt } from '../../../dmpconnect/utils/version';
import TopNotification from '../Message/TopNotification';
import env from '../../../envVariables';

const AvailableNewVersion = ({
  availableVersion, currentVersion, link,
}) => {
  // add -alpha to the current version to check prerelease versions
  const newVersionAvailable = Number(env.REACT_APP_HIDE_CONNECTOR_NEW_VERSION_MESSAGE || 0) !== 1
    && availableVersion
    && currentVersion
    && checkDMPConnectJSVersiongt(availableVersion, `${currentVersion}`);

  if (newVersionAvailable) {
    return (
      <TopNotification>
        Une mise à jour du connecteur est disponible. Veuillez installer la dernière version.
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="ic-chevron-right-small" />
          Installer
        </a>
      </TopNotification>
    );
  }
  return null;
};

AvailableNewVersion.propTypes = {
  availableVersion: PropTypes.string,
  currentVersion: PropTypes.string,
  link: PropTypes.string,
};
AvailableNewVersion.defaultProps = {
  link: '',
  availableVersion: '',
  currentVersion: undefined,
};

function mapStateToProps(state, props) {
  const {
    dmpconnect: {
      [apiSections.SESSION_SECTION]: {
        s_serviceVersion,
        s_lastVersionNumber,
        s_lastVersionUpdateLog,
        s_lastVersionLinkStr,
      },
    },
  } = state;

  return {
    availableVersion: s_lastVersionNumber,
    changeLog: s_lastVersionUpdateLog,
    currentVersion: s_serviceVersion,
    link: s_lastVersionLinkStr,
  };
}

const connectedAvailableNewVersion = connect(mapStateToProps)(AvailableNewVersion);

export default connectedAvailableNewVersion;
