import { toast } from 'react-toastify';
import { dmpconnectApplicationActionConstants } from '../constants';

const initialState = {};

export function mssPatientInfos(state = initialState, action) {
  switch (action.type) {
    case dmpconnectApplicationActionConstants.DMPC_MSS_RESET_PATIENT_INFOS: {
      toast.success('Informations patients MSS réinitialisées.');
      return initialState;
    }
    case dmpconnectApplicationActionConstants.DMPC_MSS_SET_PATIENT_INFOS: {
      const { ins, name, given } = action;
      const patientIns = ins.length === 16 ? ins.substring(0, 15) : ins;
      const {
        [patientIns]: infos,
        ...rest
      } = state;
      return { ...rest, [patientIns]: { name, given } };
    }
    default:
      return state;
  }
}
