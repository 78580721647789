import { clearSection }                                                   from 'dmpconnectjsapp-base/actions';
import { apiSections }                                                    from 'dmpconnectjsapp-base/constants';
import { getActiveMssAccountConfig, getDefaultMssConfig, getMssAccounts } from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps }                                           from 'dmpconnectjsapp-base/rules/accessRights';
import PropTypes                                                          from 'prop-types';
import * as React                                                         from 'react';
import { Form }                                                           from 'react-bootstrap';
import { connect }                                                        from 'react-redux';
import { setMssAccountActive }                                            from '../../dmpconnect/actions';
import { getDefaultMssAccountLabel }                                      from '../../dmpconnect/helpers/mss';

const MssAccountSelector = ({
  dispatch,
  psId,
  mssDefaultConfig,
  mssActiveAccount,
  mssAccounts,
  ...props
}) => (
  <Form.Control
    as="select"
    style={{ width: 'max-content' }}
    value={mssActiveAccount.id}
    {...props}
    onChange={(event) => {
      dispatch(setMssAccountActive(psId, mssAccounts.find(a => a.id === event.target.value)));
      dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
      dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
    }}
  >
    {mssAccounts.map(account => (
      <option
        key={account.id}
        value={account.id}
      >
        {account.label || getDefaultMssAccountLabel(account, mssAccounts, mssDefaultConfig.mssOperatorsConfig)}
      </option>
    ))}
  </Form.Control>
);

MssAccountSelector.propTypes    = {
  dispatch        : PropTypes.func.isRequired,
  psId            : PropTypes.string.isRequired,
  mssAccounts     : PropTypes.array,
  mssActiveAccount: PropTypes.object,
  mssDefaultConfig: PropTypes.object,
};
MssAccountSelector.defaultProps = {
  mssAccounts     : [],
  mssActiveAccount: {},
  mssDefaultConfig: {},
};

function mapStateToProps(state) {
  const { accessRights } = getAccessRightsProps(state);
  return {
    psId            : accessRights.psId,
    mssAccounts     : getMssAccounts(state),
    mssActiveAccount: getActiveMssAccountConfig(state),
    mssDefaultConfig: getDefaultMssConfig(state),
  };
}

export default connect(mapStateToProps)(MssAccountSelector);
